<template>
  <TypeAheadInput
    :tabindex="tabindex"
    :items="customers"
    :serializer="serializer"
    :id="id"
    :class="wrapperClasses"
    :name="name"
    :error="errorsFeedback"
    :required="required"
    :helpText="helpText"
    :placeholder="placeholder"
    @blur="onBlur"
    @selected="(event) => $emit('selected', event)"
    @valid="(event) => $emit('valid', event)"
    @invalid="(event) => $emit('invalid', event)"
    @input="(event) => $emit('input', event)"
    v-model="searchQuery"
  />
</template>

<script>
import TypeAheadInput from "../../../input/type-ahead/TypeAheadInput.vue";
import CustomerService from "../../../../services/CustomerService.js";
import baseCustomerTypeAheadProps from "./baseCustomerTypeAheadProps.js";

const customerService = new CustomerService();

export default {
  name: "CustomerTypeAhead",
  components: { TypeAheadInput },
  props: {
    ...baseCustomerTypeAheadProps,
    tabindex: {
      type: Number,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      customers: [],
      searchQuery: this.value,
      internalErrors: [],
    };
  },
  methods: {
    serializer(customer) {
      return customer.full_name;
    },
    onBlur() {
      this.$emit("blur");
      this.checkRules(this.searchQuery);
    },
    checkRules(newValue) {
      this.internalErrors = this.$helpers.inputRules(
        newValue,
        this.rules,
        this.required
      );
    },
    allOk() {
      const element = document.getElementById(this.id);
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        if (this.searchQuery.length > 0) {
          element.classList.add("is-valid");
        }
      }, 200);
    },
  },
  created() {
    customerService
      .getCustomers()
      .then(({ data }) => {
        this.customers = data.data.elements;
      })
      .finally(() => {
        this.$emit("loaded", true);
      });
  },
  computed: {
    wrapperClasses() {
      /* if (this.disabled) {
        return "disabled ";
      } */
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        return "is-invalid ";
      }

      if (this.searchQuery && this.searchQuery.length > 0) {
        return "is-valid";
      }
      return "";
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error;
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors;
      }
      return [];
    },
  },
  watch: {
    value(newValue) {
      if (newValue !== this.searchQuery) {
        this.searchQuery = newValue;
      }
    },
    searchQuery(newValue) {
      if (newValue !== this.value) {
        this.value = newValue;
      }
      this.$emit("input", newValue);
      //this.allOk();
    },
    /*  internalErrors() {
      this.allOk();
    }, */
  },
};
</script>
