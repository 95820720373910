<template>
  <div :class="stepsSticky ? 'steps-sticky' : ''">
    <!-- style="z-index: 99; position: sticky; top: -26px;" -->
    <div class="steps-card-detail-header">
      <div class="d-flex justify-content-between mb-3">
        <div class="heading-3 anim-fade-one">
          {{
            allSteps[`${progressValue}`]
              ? allSteps[`${progressValue}`].title
              : $t("orderCreatePage.stepsCardDetail.stepSix")
          }}

          ({{
            allSteps[`${progressValue}`]
              ? progressValue * Math.ceil(100 / allSteps.length)
              : 100
          }}%)
        </div>
        <div class="d-flex justify-content-between link-2">
          <div v-for="(name, index) in allSteps" :key="name + index">
            <div
              v-if="progressValue != index"
              class="px-2 anim-fade-one"
              :class="index + 1 == Object.keys(allSteps).length ? '' : 'br-1'"
              @click="scrollToId(allSteps[`${index}`].link)"
            >
              {{ allSteps[`${index}`].title }}
            </div>
          </div>
          <div
            variant="tertiary"
            :class="stepsSticky ? 'px-1 text-primary' : 'px-1'"
            @click="stepsSticky = !stepsSticky"
          >
            <i class="fas fa-thumbtack"></i>
          </div>
        </div>
      </div>

      <NbProgressBar
        id="progressBar-example-1"
        :progressVal="progressValue"
        :height="3"
        max="100"
        :step="Math.ceil(100 / allSteps.length)"
      />
    </div>
    <div>
      <b-collapse
        id="steps-card-detail-body-id"
        class="mt-0 steps-card-detail-body"
        v-model="value"
      >
        <div class="d-flex justify-content-between">
          <div>
            <div class="title-columns">
              {{ $t("orderCreatePage.titleColumns.boxProducts") }}
            </div>
            <div class="text-columns">
              <span class="font-weight-bold"
                >• {{ orderSummary.quantity ? orderSummary.quantity : "0" }}
                {{ $t("items") }}</span
              >
              {{ $t("added") }}
            </div>
            <div class="text-columns">
              <span class="font-weight-bold"
                >• {{ orderSummary.boxLength ? orderSummary.boxLength : "0" }}
                {{ $t("box") }}</span
              >
              {{ $t("created") }}
            </div>
          </div>
          <div>
            <div class="title-columns">
              {{ $t("orderCreatePage.titleColumns.carrier") }}
            </div>
            <div class="text-columns">
              {{
                order.courier.selectedQuote
                  ? order.courier.selectedQuote.contract_name
                  : "Nenhuma transportadora selecionada"
              }}.
            </div>
          </div>
          <div>
            <div class="title-columns">
              {{ $t("orderCreatePage.titleColumns.estimatedDelivery") }}
            </div>
            <!-- <div class="subtitle-columns number-2">A definir</div> -->
            <div class="text-columns">
              {{ $t("deliveryTime") }}:
              {{
                order.courier.selectedQuote
                  ? order.courier.selectedQuote.delivery_time
                  : "--"
              }}
              {{ $t("myNotificationsPage.days") }}
            </div>
          </div>
          <div>
            <div class="title-columns">
              {{ $t("orderCreatePage.titleColumns.totalPartial") }}
            </div>
            <div class="subtitle-columns number-2">
              {{ order.shipment.currency === "USD" ? "$" : "R$"
              }}{{
                orderSummary.total_value ? orderSummary.total_value : "0.00"
              }}
              <span class="text-columns">{{ $t("items") }}</span>
            </div>
            <div class="subtitle-columns number-2">
              {{ order.shipment.currency === "USD" ? "$" : "R$"
              }}{{
                order.courier.selectedQuote
                  ? order.courier.selectedQuote.duties_tax
                  : "--"
              }}
              <span class="text-columns">{{ $t("dutiesTax") }}</span>
            </div>
            <div class="subtitle-columns number-2">
              {{ order.shipment.currency === "USD" ? "$" : "R$"
              }}{{
                order.courier.selectedQuote
                  ? order.courier.selectedQuote.freight_cost
                  : "--"
              }}
              <span class="text-columns">{{ $t("freightCost") }}</span>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="steps-card-detail-footer d-flex justify-content-between">
      <div></div>
      <div>
        <NbButton variant="tertiary" @click="content = !content">{{
          $t("orderCreatePage.orderSummary")
        }}</NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbProgressBar from "@/components/progressBar/NbProgressBar.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "NbStepsCard",
  components: {
    NbProgressBar,
    NbButton,
  },
  props: {
    allSteps: {
      type: Array,
      required: true,
    },
    progressValue: {
      type: [Number, String],
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    orderSummary: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
      stepsSticky: false,
    };
  },
  methods: {
    scrollToId(id) {
      if (id) {
        const elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  watch: {
    content(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.content = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.steps-sticky {
  z-index: 99;
  position: sticky;
  top: -26px;
}

.br-1 {
  border-right: 1px solid var(--gray-60);
}

.steps-card-detail-header {
  background: var(--gray-05);
  border-radius: 5px 5px 0px 0px;
  padding: 0.81rem 1.5rem 1rem 1.5rem;
}
.steps-card-detail-body {
  background: var(--gray-05);
  padding: 0rem 1.5rem;
}
.steps-card-detail-footer {
  background: var(--gray-05);
  border-radius: 0px 0px 5px 5px;
  padding: 0rem 1.5rem 1rem 1.5rem;
}

.title-columns {
  margin-top: 0.5rem;
  color: var(--gray-60);
  font: normal normal bold 10px/14px Nunito Sans;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.text-columns {
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
}
</style>
