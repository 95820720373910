import HttpService from "./HttpService";

export default class Cn35Service {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Generate cn35 label
   *
   * @param orderId
   * @returns {Promise}
   */
  generate(orderId) {
    return this.httpService.put("/v1/orders/" + orderId + "/cn35");
  }
}
