<template>
  <section>
    <NbNumberedAccordionForm
      :id="'accord-form-shipment'"
      :isFrom="isFrom"
      :twoPartFormDone="areBoxesOk"
      @firstFormDone="firstFormDone($event)"
      class="mb-2"
      accordionKey="shipment"
      :showAccordion="accordionAttributes.shipment"
      :number="number"
      @siwtchShowAccordion="$emit('siwtchShowAccordion', $event)"
      :title="$t('orderCreatePage.shipment')"
      :subTitle="$t('orderCreatePage.shipmentInformation.title')"
      :helpText="$t('orderCreatePage.shipmentInformation.helpText')"
      :hasError="sectionError"
      :errors="errors"
      :formInputs="shipmentInputs"
      @resetIsLandedCostPrc="resetIsLandedCostPrc"
      @input="shipmentUpdated()"
      @updateValidFields="$emit('updateValidFields', $event)"
      @invalid="$emit('updateInvalidFields', $event)"
      :formVal.sync="shipment"
    >
      <NbBoxesList
        :prc="shipment.prc"
        :errors="errors"
        :currency="shipment.currency"
        :taxes_currency="shipment.collected_taxes_currency"
        @itemAttributeValueUpdated="$emit('itemAttributeValueUpdated')"
        @boxMetricsChange="$emit('boxMetricsChange')"
        @updateValidFields="$emit('updateValidFields', $event)"
        @invalid="$emit('updateInvalidFields', $event)"
        :formValBoxes.sync="shipment.volumes_attributes"
      />
    </NbNumberedAccordionForm>
  </section>
</template>

<script>
import NbNumberedAccordionForm from "@/components/forms/NbNumberedAccordionForm.vue";
import NbBoxesList from "@/components/orders/create/shipment/NbBoxesList.vue";
import SalesChannelsManagementService from "@/services/SalesChannelManagementService";

const salesChannelManagementService = new SalesChannelsManagementService();

export default {
  components: {
    NbNumberedAccordionForm,
    NbBoxesList,
  },
  props: {
    /* value: {
      type: Object,
      required: true,
    }, */
    formVal: {
      type: Object,
      required: true,
    },
    isFrom: {
      type: String,
      required: false,
    },
    quickStarts: {
      type: Array,
      required: false,
    },
    number: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    sectionError: {
      type: Boolean,
      required: false,
    },
    accordionAttributes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      salesChannelOptions: [],
      incotermOptions: [
        { text: "Sender (DDP)", value: "DDP" },
        { text: "Receiver (DDU)", value: "DDU" },
      ],
      confirmOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      currencyOptions: [
        { text: "USD ($)", value: "USD" },
        { text: "BRL (R$)", value: "R$" },
      ],
      products: [
        {
          sku: "",
          name: "",
          description: "",
          origin_country: "",
          category: "",
          hs_code: "",
          unit_price: 0,
          weight: 0.0,
          quantity: 1,
          box: "",
          total: 0.0,
        },
      ],
      areBoxesOk: false, //TODO: incompleto quando box/items blank
    };
  },
  created() {
    //this.shipment = { ...this.shipment, ...this.value.shipment };
  },
  methods: {
    getAndConfigureSalesChannels() {
      salesChannelManagementService.getSalesChannels().then(({ data }) => {
        const availableSalesChannels = data.data;
        this.salesChannelOptions = availableSalesChannels.map(
          ({ id, name }) => ({
            value: id,
            text: name,
          })
        );
        this.salesChannelOptions.unshift({
          value: null,
          text: "Select an option",
        });
      });
    },
    resetIsLandedCostPrc() {
      this.shipment.is_landed_cost = false;
      this.shipment.prc = false;
    },
    firstFormDone(event) {
      //test boxes (volumes_attributes) (may have a better way?)
      if (
        event &&
        this.shipment &&
        this.shipment.volumes_attributes &&
        this.shipment.volumes_attributes.length > 0
      ) {
        this.areBoxesOk = true;

        //test each volumes_attributes (box)
        this.shipment.volumes_attributes.forEach((box) => {
          if (
            this.areBoxesOk &&
            box.height &&
            box.weight &&
            box.width &&
            box.length
          ) {
            //test each order_items_attributes (product) in box
            this.areBoxesOk = box.order_items_attributes.every(
              (product) =>
                this.areBoxesOk &&
                product.description &&
                product.origin_country &&
                product.category &&
                product.hs_code &&
                product.weight &&
                Number(product.value) > 0 &&
                Number(product.quantity) > 0
            );
          } else {
            this.areBoxesOk = false;
          }
        });

        this.$emit("formDoneChangeShipment", this.areBoxesOk);
        return;
      }

      this.areBoxesOk = false;

      this.$emit("formDoneChangeShipment", this.areBoxesOk);
    },
    shipmentUpdated() {
      this.$emit("shipmentUpdated");
    },
  },
  computed: {
    shipment: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
    shipmentInputs() {
      let finalShipmentInputs = [
        {
          required: true,
          id: "order_number",
          name: this.$t("orderNumber"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("orderNumber"),
          key: "order_number",
          error: this.errors.order_number,
          helpText: this.$t(
            "orderCreatePage.shipmentInformation.ordernumberHelpText"
          ),
        },
        {
          required: false,
          id: "customer_sales_channel",
          type: "select",
          options: this.salesChannelOptions,
          name: this.$t("salesChannel"),
          classes: "mb-3 w-32-5",
          key: "sales_channel_id",
          error: this.errors.sales_channel_id,
          helpText: this.$t(
            "orderCreatePage.shipmentInformation.saleschannelHelpText"
          ),
        },
        {
          required: false,
          id: "customer_sales_channel_order_number",
          name: this.$t("salesChannelOrderNumberShort"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("salesChannelOrderNumberShort"),
          key: "sales_channel_order_number",
          error: this.errors.sales_channel_order_number,
          helpText: this.$t(
            "orderCreatePage.shipmentInformation.saleschannelordernumberHelpText"
          ),
        },
        {
          required: true,
          id: "incoterm",
          type: "radio",
          options: this.incotermOptions,
          name: this.$t("taxes"),
          classes: "mb-4 w-26",
          inputClasses: "display-block",
          key: "incoterm",
          error: this.errors.incoterm,
          helpText: this.$t(
            "orderCreatePage.shipmentInformation.incotermHelpText"
          ),
          onClick: "resetIsLandedCostPrc",
        },
        {
          required: this.isLandedCostRequired,
          id: "landed_cost",
          type: "checkbox",
          name: this.$t(
            "orderCreatePage.shipmentInformation.landedcostHelpText"
          ),
          nameHelpText: this.$t(
            "orderCreatePage.shipmentInformation.landedCost"
          ),
          classes: "w-24",
          key: "is_landed_cost",
          error: this.errors.is_landed_cost,
        },
        {
          required: this.shipment.incoterm == "DDP",
          id: "prc",
          type: "checkbox",
          name: this.$t("orderCreatePage.shipmentInformation.PRC"),
          nameHelpText: this.$t("orderCreatePage.shipmentInformation.PRC"),
          classes: "w-24 mt-4",
          key: "prc",
          error: this.errors.prc,
        },
        {
          required: false,
          id: "return_insurance",
          type: "checkbox",
          name: this.$t("includeInsurance"),
          nameHelpText: this.$t(
            "orderCreatePage.shipmentInformation.includeInsuranceNameHelpText"
          ),
          classes: "w-26 mt-4 pt-1",
          inputClasses: "display-block",
          key: "return_insurance",
          error: this.errors.return_insurance,
        },
        {
          required: true,
          id: "shipment_collected_taxes_currency",
          type: "select",
          options: this.currencyOptions,
          name: `${this.$t("collectedTaxesCurrency")} (ICMS/II)`,
          classes: "mb-3 mt-1 w-48-5",
          key: "collected_taxes_currency",
          error: this.errors.collected_taxes_currency,
          helpText: this.$t(
            "orderCreatePage.shipmentInformation.taxesCurrencyHelpText"
          ),
        },
        {
          required: true,
          id: "shipment_currency",
          type: "select",
          options: this.currencyOptions,
          name: this.$t("orderCurrency"),
          classes: "mb-3 mt-1 w-48-5",
          key: "currency",
          error: this.errors.currency,
          helpText: this.$t(
            "orderCreatePage.shipmentInformation.currencyHelpText"
          ),
        },
      ];

      if (this.shipment.incoterm !== "DDP") {
        let customer_incoterm = finalShipmentInputs.find(
          (inputOption) => inputOption.id === "landed_cost"
        );
        customer_incoterm.classes = "anim-fade-disappear w-24 mt-4 pt-1 ";

        let prc = finalShipmentInputs.find(
          (inputOption) => inputOption.id === "prc"
        );

        prc.classes = "anim-fade-disappear w-24 mt-4 pt-1 ";
      } else {
        let customer_incoterm = finalShipmentInputs.find(
          (inputOption) => inputOption.id === "landed_cost"
        );
        customer_incoterm.classes = "anim-fade-appear w-24 mt-4 pt-1";

        let prc = finalShipmentInputs.find(
          (inputOption) => inputOption.id === "prc"
        );
        prc.classes = "anim-fade-appear w-24 mt-4 pt-1";
      }

      return finalShipmentInputs;
    },
    isLandedCostRequired() {
      if (this.shipment.incoterm !== "DDP") {
        return false;
      }
      return true;
    },
  },
  watch: {
    /* shipment(newValue) {
      this.$emit("input", newValue);
    }, */
    formVal(/* newValue */) {
      //this.shipment = newValue;

      let newProducts = [];
      this.formVal.shipment.volumes_attributes.forEach((box) => {
        newProducts = [...newProducts, ...box.order_items_attributes];
      });
      if (newProducts.length) this.products = newProducts;
    },

    quickStarts() {
      this.quickStarts.forEach((quickStart) => {
        if (quickStart.incoterm) {
          this.shipment.incoterm = quickStart.incoterm;
        }
        if (typeof quickStart["insurance"] === "boolean") {
          this.shipment.return_insurance = quickStart.insurance;
        }
        if (quickStart.currency) {
          this.shipment.currency = quickStart.currency;
        }
        //fill category and hs_code
        if (quickStart.category) {
          this.products[0].category = quickStart.category;

          this.shipment.category = quickStart.category;
        }
        if (quickStart.hs_code) {
          this.products[0].hs_code = quickStart.hs_code;
          this.shipment.hs_code = quickStart.hs_code;
        }
        if (quickStart.origin_country) {
          this.products[0].origin_country = quickStart.origin_country;
          this.shipment.origin_country = quickStart.origin_country;
        }
        if (quickStart.sales_channel) {
          this.shipment.sales_channel_id = quickStart.sales_channel;
        }
        this.getAndConfigureSalesChannels();
      });
    },
    errors: {
      //deep: true,
      handler(newValue) {
        if (newValue.is_landed_cost && newValue.is_landed_cost.length > 0) {
          this.shipment.incoterm = "DDP";
        }
      },
    },
  },
};
</script>

<style></style>
