import { REPEATED_NUMBERS_REGEX } from "./constants";

/**
 * Validate an unmasked CPF
 *
 * @param {string} rawCPF - CPF to validate.
 */
export function validateCPF(rawCPF) {
  if (REPEATED_NUMBERS_REGEX.test(rawCPF)) return false;
  if (rawCPF.length !== 11) return false;

  let sum = 0;
  let modulus;
  for (let iterator = 1; iterator <= 9; iterator++) {
    sum =
      sum +
      parseInt(rawCPF.substring(iterator - 1, iterator)) * (11 - iterator);
  }

  modulus = (sum * 10) % 11;
  if (modulus == 10 || modulus == 11) modulus = 0;
  if (modulus != parseInt(rawCPF.substring(9, 10))) return false;

  sum = 0;
  for (let iterator = 1; iterator <= 10; iterator++) {
    sum =
      sum +
      parseInt(rawCPF.substring(iterator - 1, iterator)) * (12 - iterator);
  }

  modulus = (sum * 10) % 11;
  if (modulus == 10 || modulus == 11) modulus = 0;
  if (modulus != parseInt(rawCPF.substring(10, 11))) return false;

  return true;
}

/**
 * Validate an unmasked CNPJ
 *
 * @param {string} rawCNPJ - CNPJ to validate.
 */
export function validateCNPJ(rawCNPJ) {
  if (REPEATED_NUMBERS_REGEX.test(rawCNPJ)) return false;
  if (rawCNPJ.length != 14) return false;

  let size = rawCNPJ.length - 2;
  let numbers = rawCNPJ.substring(0, size);
  let digits = rawCNPJ.substring(size);
  let sum = 0;
  let position = size - 7;

  for (let iterator = size; iterator >= 1; iterator--) {
    sum += numbers.charAt(size - iterator) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(0)) return false;

  size = size + 1;
  numbers = rawCNPJ.substring(0, size);
  sum = 0;
  position = size - 7;
  for (let iterator = size; iterator >= 1; iterator--) {
    sum += numbers.charAt(size - iterator) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(1)) return false;

  return true;
}

/**
 * Validate an unmasked document trying to validate as CPF or CNPJ
 *
 * @param {string} rawDocument - Document to validate to validate.
 */
export function validateCPFOrCNPJ(rawDocument) {
  return validateCPF(rawDocument) || validateCNPJ(rawDocument);
}

/**
 * Validate an unmasked document and if its valid, tell if it is a CPF or CNPJ
 *
 * @param {string} rawDocument - Document to validate to validate.
 * @returns {string|boolean} - The document type CPF or CNPJ
 */
export function getValidDocumentName(rawDocument) {
  if (validateCPF(rawDocument)) {
    return "CPF";
  }
  if (validateCNPJ(rawDocument)) {
    return "CNPJ";
  }
  return false;
}

/**
 * Validate an unmasked CEP length
 *
 * @param {string} rawCEP - CEP to validate.
 */
export function validateCEP(rawCEP) {
  return rawCEP.length === 8;
}

/**
 * Validate an unmasked phone length
 *
 * @param {string} rawPhone - phone to validate.
 */
export function validatePhone(rawPhone) {
  return rawPhone.length === 10 || rawPhone.length === 11;
}

export function getErrors(errorsList, reference) {
  const errorsOfThisGroup = {};

  for (const errorName in reference) {
    if (reference[errorName] && errorsList.includes(errorName)) {
      errorsOfThisGroup[errorName] = reference[errorName];
    }
  }
  return errorsOfThisGroup;
}

const validators = {
  CPF: validateCPF,
  CNPJ: validateCNPJ,
  CEP: validateCEP,
  DOCUMENT: validateCPFOrCNPJ,
  PHONE: validatePhone,
};

export function genericValidator(validationType, value) {
  if (validators[validationType]) {
    return validators[validationType](value);
  }
  return false;
}
