<template>
  <b-modal
    id="modal-delete-box"
    centered
    :title="$t('components.destructiveModal.deleteBox.title')"
    @ok="(event) => $emit('ok', event)"
    @cancel="(event) => $emit('cancel', event)"
    @close="(event) => $emit('close', event)"
    @hidden="(event) => $emit('hidden', event)"
    v-model="isOpen"
  >
    <div class="modal-body">
      <div class="heading-4 mb-4">
        {{ $t("components.destructiveModal.deleteBox.information") }}
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <NbButton class="float-left" variant="secondary" @click="cancel">
        {{ $t("components.destructiveModal.deleteBox.cancel") }}
      </NbButton>
      <NbButton @click="ok">
        {{ $t("components.destructiveModal.deleteBox.ok") }}
      </NbButton>
    </template>
  </b-modal>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "ModalCreateCustomBox",
  components: { NbButton },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    scope: "deleteBox",
    error: {},
  }),
  created() {
    this.isOpen = this.value;
  },
  watch: {
    value() {
      if (this.value !== this.isOpen) this.isOpen = this.value;
    },
    isOpen() {
      if (this.value !== this.isOpen) this.$emit("input", this.isOpen);
    },
  },
};
</script>

<style>
/* ***thy modal config novo modal new modal */
#modal-delete-box .modal-content {
  background: var(--background);
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
}

#modal-delete-box .close {
  color: var(--black);
  opacity: 1;
}

#modal-delete-box .modal-header {
  border: 0px;
  padding: 0rem;
}

#modal-delete-box .modal-body {
  padding: 0rem;
}

#modal-delete-box .modal-footer {
  justify-content: space-between;
  border: 0px;
  padding: 0rem;
}

#modal-delete-box .visibility-hidden {
  visibility: hidden;
}
</style>
