<template>
  <div class="w-100">
    <div
      v-for="(box, boxIndex) of boxes"
      :key="`${box.id}-box-${boxIndex}`"
      class="nb-box-list mb-4 mt-5"
    >
      <div
        :id="`box-card-${boxIndex + 1}`"
        class="heading-3 text-center bg-gray-10 m-auto w-fit pl-4 pr-1"
      >
        {{ $t("box") }} {{ boxIndex + 1 }}
        <NbButton
          class="pr-2 bg-gray-10"
          variant="quaternary"
          icon="trash"
          style="position: relative; z-index: 1"
          @click="onDelete(boxIndex)"
        ></NbButton>
      </div>
      <div class="box-body">
        <NbProductCard
          v-for="(
            order_items_attribute, itemIndex
          ) in box.order_items_attributes"
          :key="'item' + itemIndex"
          class="item-card bg-background mt-2 pt-3 px-4 pb-4"
          :quoteVersion="quoteVersion"
          :boxes="productsBoxOptions"
          :boxIndex="boxIndex"
          :itemIndex="itemIndex"
          :currency="simplifyCurrency(currency)"
          :weightUnit="weightUnit"
          :errors="errors"
          @updateValidFields="$emit('updateValidFields', $event)"
          @invalid="$emit('invalid', $event)"
          @itemAttributeValueUpdated="$emit('itemAttributeValueUpdated')"
          @deleteItem="deleteItem($event)"
          @copyItem="copyItem($event)"
          @relocateItem="relocateItem($event)"
          :formValProduc.sync="box.order_items_attributes[itemIndex]"
        />

        <div class="d-flex justify-content-end mt-2">
          <NbButton
            variant="secondary"
            size="sm"
            icon="plus"
            @click="createNewItem(boxIndex)"
          >
            {{ $t("components.boxesList.newItem") }}
          </NbButton>
        </div>

        <div class="box-card my-4">
          <div class="heading-3 mb-3">
            {{ $t("components.boxesList.specificationsOf") }} {{ $t("box") }}
            {{ boxIndex + 1 }}
          </div>
          <NbBox
            :prc="prc"
            :currency="currency"
            :taxes_currency="taxes_currency"
            :sizeUnit="sizeUnit"
            :weightUnit="weightUnit"
            :boxIndex="boxIndex"
            :errors="errors"
            :boxOptions="boxOptions"
            :availableBoxes="availableBoxes"
            @updateSelectedBox="updateSelectedBox($event.index, $event.name)"
            @updateValidFields="$emit('updateValidFields', $event)"
            @updateInvalidFields="$emit('invalid', $event)"
            @boxMetricsChange="$emit('boxMetricsChange')"
            @sendMetric="sendMetric($event)"
            :formValBox.sync="boxes[boxIndex]"
          />
        </div>

        <!-- 
        <div class="bg-background p-4 mb-4">
          <div class="heading-3 mb-1">
            {{ $t('components.boxesList.containsHarmfulProducs') }}?
          </div>
          <div class="heading-3 mb-3">
            <div class="body-3">
              {{ $t('components.boxesList.harmfilExample') }},...
            </div>
            <div class="body-3">
              {{ $t('components.boxesList.ifDoubt') }} 
              <span class="link-1">{{ $t('components.boxesList.seeHarmfulList') }}.</span>
            </div>
          </div>
          <div>
            <NbRadio
              :id="`shipment_box${boxIndex}_nocuous`"
              :classes="'display-block'"
              :error="[]"
              :options="nocuousOptions"
              v-model="box.has_nocuous_items"
            />
          </div>
        </div>
        -->
      </div>

      <div class="dashed-middle d-flex justify-content-center">
        <NbButton
          variant="quaternary"
          icon="plus"
          style="position: relative; z-index: 1"
          @click="addCustomBox(boxes[boxIndex].volume_name)"
        >
          {{ $t("components.boxesList.newBox") }}
        </NbButton>
      </div>
    </div>
    <div class="my-3">
      <Alert
        class="mb-2 text-center py-3"
        :link="shippingQuickStartLink"
        :aling="'center'"
        type="success"
        :message="$t('components.boxesList.howAboutCreatingRule')"
      />
    </div>
    <ModalDeleteBox
      @ok="deleteBox"
      @cancel="cancelDeletion"
      @close="cancelDeletion"
      @hidden="cancelDeletion"
      v-model="deletingBox"
    />
  </div>
</template>

<script>
import NbProductCard from "../shipment/NbProductCard.vue";
import NbBox from "@/components/orders/create/shipment/NbBox.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import ModalDeleteBox from "./ModalDeleteBox.vue";
import UsersService from "../../../../services/UsersService";
import SellerService from "../../../../services/SellerService";
import Alert from "@/components/alerts/Alert.vue";
import BoxService from "@/services/BoxService.js";

const usersService = new UsersService();
const sellerService = new SellerService();
const boxService = new BoxService();
export default {
  name: "NbBoxesList",
  components: { ModalDeleteBox, NbButton, NbProductCard, Alert, NbBox },
  props: {
    quoteVersion: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      required: true,
    },
    taxes_currency: {
      type: String,
      required: true,
    },
    prc: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Array, Object],
      required: false,
      default: () => {},
    },
    /* value: {
      type: Array,
      required: true,
    }, */
    formValBoxes: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    deletingBox: false,
    boxToDelete: null,
    weightUnit: "",
    sizeUnit: "",
    currentMetric: "",
    default_order_items_attributes: [
      {
        sku: "",
        name: "",
        description: "",
        origin_country: "",
        category: "",
        hs_code: null,
        unit_price: "",
        weight: 0.0,
        quantity: 1,
        box: "",
        total: 0,
        value: null,
        id: null,
        created_at: "",
        updated_at: "",
        seller_id: null,
        freight_cost: "",
        freight_value: "",
        platform_id: null,
      },
    ],
    availableBoxes: [],
  }),
  created() {
    this.getAndConfigureBoxes();
  },
  beforeMount() {
    usersService.getUserMeasurementSystem().then(({ data }) => {
      this.updateMetric(data.data);
    });
  },
  methods: {
    updateMetric(mode) {
      this.currentMetric = mode;
      if (mode === "imperial") {
        this.weightUnit = "lb";
        this.sizeUnit = "in";
      } else {
        this.weightUnit = "kg";
        this.sizeUnit = "cm";
      }
    },
    onDelete(index) {
      this.deletingBox = true;
      this.boxToDelete = index;
    },
    cancelDeletion() {
      this.boxToDelete = null;
      this.deletingBox = false;
    },
    deleteBox() {
      if (this.boxes.length <= 1) {
        const message = `Can't delete the last box!`;
        this.$helpers.toast(message, "danger", 2000);
        return;
      }
      this.boxes.splice(this.boxToDelete, 1);
    },
    sendMetric(metricMode) {
      if (this.currentMetric !== metricMode) {
        sellerService
          .updateSellerSettings(this._props.value[0].seller_id, {
            measure_mode: metricMode,
          })
          .then((response) => {
            if (response.status === 200) {
              this.updateMetric(metricMode);
            }
          });
      }
    },
    newEmptyBox(volume_type = "") {
      const newBox = {
        id: null,
        height: "",
        width: "",
        length: "",
        weight: "",
        created_at: "",
        updated_at: "",
        seller_id: null,
        default_choice: null,
        platform_id: null,
        volume_type: volume_type,
        total_weight: "",
        has_nocuous_items: false,
        freight_cost: "",
        freight_value: "",
        order_items_attributes: [{ ...this.default_order_items_attributes[0] }],
      };
      this.$set(this.boxes, this.boxes.length, newBox);
    },
    addCustomBoxInAvaliableBoxes(boxName) {
      //const newItem = { ...this.default_order_items_attributes[0] }
      //const newItem = JSON.parse(JSON.stringify(this.default_order_items_attributes[0]));
      this.availableBoxes.push({
        height: "0.00",
        length: "0.00",
        volume_name: boxName || "Custom Box",
        volume_type: boxName || Math.random().toString(16).slice(2),
        weight: "0.00",
        width: "0.00",
        freight_cost: "",
        freight_value: "",
        order_items_attributes: [{ ...this.default_order_items_attributes[0] }],
        /* hash: Math.random().toString(16).slice(2), */
      });
    },
    addCustomBox(boxName) {
      let finalName = "Custom Box";

      if (typeof boxName === "string") {
        finalName = boxName;
      }
      this.newEmptyBox(finalName);
      const index = this.boxes.length - 1;
      this.updateSelectedBox(index, finalName);
      //this.createNewItem(index);
      setTimeout(() => {
        this.scrollToId(`box-card-${this.boxes.length}`);
      }, 200);
    },
    updateSelectedBox(index, name) {
      const selectedBox = this.availableBoxes.find(
        ({ volume_type }) => volume_type === name
      );
      let finalBox = { ...selectedBox };
      finalBox.volume_name = name || "Custom Box";
      finalBox.volume_type = Math.random().toString(16).slice(2);
      finalBox.order_items_attributes = [
        { ...this.default_order_items_attributes[0] },
      ];
      finalBox.order_items_attributes[0].box = finalBox.volume_type;
      finalBox.freight_cost = "";
      finalBox.freight_value = "";

      //better way to manipulate a complex array
      this.$set(this.boxes, index, finalBox);
      /* This code can trigger a reactive problem. use this.$set() when manipulate complex arrays
      this.boxes.splice(index, 1, finalBox);
      */
    },
    deleteItem(itemToDelete) {
      if (
        this.boxes[itemToDelete.boxIndex].order_items_attributes.length <= 1
      ) {
        const message = `Be careful! The box ${
          itemToDelete.boxIndex + 1
        } is empty!`;
        this.$helpers.toast(message, "warning", 2000);
      }
      this.boxes[itemToDelete.boxIndex].order_items_attributes.splice(
        itemToDelete.itemIndex,
        1
      );
    },
    copyItem(itemToCopy) {
      this.boxes[itemToCopy.boxIndex].order_items_attributes.splice(
        itemToCopy.itemIndex,
        0,
        ...[{ ...itemToCopy.item }]
      );
    },
    relocateItem(event) {
      //adiciona o item na nova caixa
      this.boxes[event.boxDestinationIndex].order_items_attributes.push(
        this.boxes[event.boxOriginIndex].order_items_attributes[
          event.itemOriginIndex
        ]
      );
      //remove o item da caixa original
      this.boxes[event.boxOriginIndex].order_items_attributes.splice(
        event.itemOriginIndex,
        1
      );

      if (this.boxes[event.boxOriginIndex].order_items_attributes.length <= 0) {
        const message = `Be careful! The box ${
          event.boxOriginIndex + 1
        } is empty!`;
        this.$helpers.toast(message, "warning", 2000);
      }
    },
    createNewItem(boxIndex) {
      const newItem = { ...this.default_order_items_attributes[0] };
      if (
        this.boxes[boxIndex] &&
        !this.boxes[boxIndex].order_items_attributes
      ) {
        this.boxes[boxIndex].order_items_attributes = [];
      }
      newItem.box = this.boxes[boxIndex].volume_type;
      //better way to manipulate a complex array
      this.$set(
        this.boxes[boxIndex].order_items_attributes,
        this.boxes[boxIndex].order_items_attributes.length || 0,
        newItem
      );
      /* This code trigger a reactive problem.
      this.boxes[boxIndex].order_items_attributes.splice(this.boxes[boxIndex].order_items_attributes.length || 0, 0, ...[ newItem ]);
      */
    },
    simplifyCurrency(currency) {
      if (currency === "USD") {
        return "$";
      } else {
        return "R$";
      }
    },
    getAndConfigureBoxes() {
      boxService.getBoxes().then(({ data }) => {
        this.availableBoxes = data.data.elements
          .sort((a, b) => (a.default_choice && !b.default_choice ? -1 : 1))
          .map((box) => {
            box.volume_type = box.name;
            (box.order_items_attributes = []), delete box.name;
            return box;
          });
        //adiciona uma caixa em branco nas opcoes de caixas
        this.addCustomBoxInAvaliableBoxes("Custom Box");

        //load auto_select
        if (this.availableBoxes[0].default_choice) {
          this.availableBoxes[0].order_items_attributes = [
            { ...this.default_order_items_attributes[0] },
          ];
          Object.assign(this.boxes[0], this.availableBoxes[0]);
          this.boxes[0].volume_name = this.availableBoxes[0].volume_type;
          this.boxes[0].order_items_attributes[0].box =
            this.availableBoxes[0].volume_type;
          return;
        }
        //Se tiver apenas 1 caixa carrega ela por default
        if (data.data.length == 1) {
          this.availableBoxes[0].order_items_attributes = [
            { ...this.default_order_items_attributes[0] },
          ];
          Object.assign(this.boxes[0], data.data[0]);
          this.boxes[0].volume_name = data.data[0].volume_type;
          this.boxes[0].order_items_attributes[0].box =
            data.data[0].volume_type;
          return;
        }
        //joga caixa vazia 'Custom Box'
        this.availableBoxes[0].order_items_attributes = [
          { ...this.default_order_items_attributes[0] },
        ];
        Object.assign(
          this.boxes[0],
          this.availableBoxes[this.availableBoxes.length - 1]
        );
        this.boxes[0].volume_name =
          this.availableBoxes[this.availableBoxes.length - 1].volume_type;
        this.boxes[0].order_items_attributes[0].box =
          this.availableBoxes[this.availableBoxes.length - 1].volume_type;
      });
    },
    scrollToId(id) {
      const elmnt = document.getElementById(id);
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    boxes: {
      get() {
        return this.formValBoxes;
      },
      set(val) {
        this.$emit("update:formValBoxes", val);
      },
    },
    productsBoxOptions() {
      return this.boxes.map((box, index) => ({
        text: index + 1,
        value: box.volume_type,
      }));
    },
    shippingQuickStartLink() {
      return {
        newLine: true,
        target: "_blank",
        src: "/settings/shipping_rules",
        text: this.$t("components.boxesList.moreAbourShippingRules"),
      };
    },
    nocuousOptions() {
      return [
        {
          text: this.$t("components.boxesList.notContainsHarmfulProduct"),
          value: false,
        },
        {
          text: this.$t("components.boxesList.containsHarmfulProduct"),
          value: true,
        },
      ];
    },
    boxOptions() {
      const boxOptions = this.availableBoxes.map((box) => ({
        text: box.volume_type,
        value: box.volume_type,
      }));
      return boxOptions;
    },
  },
  watch: {},
};
</script>

<style scoped>
.nb-box-list {
  border-top: 1px solid var(--gray-20);
  width: 100%;
}
.bg-secondary {
  max-width: 100px;
}

.bg-gray-10 .bg-gray-10 .bg-gray-10 {
  border: 1px solid var(--gray-10) !important;
  background-color: var(--gray-10) !important;
}

.dashed-middle {
  width: 100%;
  position: relative;
}

.dashed-middle:before {
  content: "";
  position: absolute;
  bottom: 50%;
  border-bottom: 1px var(--gray-20) dashed;
  width: 100%;
  z-index: 0;
}
/* display: flex;
justify-content: center; */
</style>
