import baseProps from "../baseProps.js";

export default {
  ...baseProps,
  items: {
    type: Array,
    required: true,
  },
  serializer: {
    type: Function,
    required: true,
  },
};
