<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <section class="pl-3">
        <NbStepsCard
          id="NbStepsCard"
          :allSteps="allSteps"
          :progressValue="progressValue"
          :order="order"
          :orderSummary="orderSummary"
          v-model="accordionColapse"
        />
        <NbReceiverInformation
          id="receiver-information"
          :sectionError="sectionErros"
          :accordionAttributes="accordionAttributes"
          :allSteps="allSteps"
          :number="getStepIndex('receiver-information') + 1"
          :errors="creationErrors"
          :currentSeller="currentSeller"
          @siwtchShowAccordion="siwtchShowAccordion($event)"
          @formDoneChangeReceiver="
            formDoneChange($event, 'receiver-information')
          "
          @formDoneChangeSender="formDoneChange($event, 'sender-information')"
          @receiverUpdated="receiverUpdated()"
          @updateValidFields="updateValidFields($event)"
          @updateInvalidFields="updateInvalidFields($event)"
          :formVal.sync="order.receiver"
        />

        <NbShipmentInformation
          id="shipment-information"
          :sectionError="sectionErros.shipment || sectionErros.boxes"
          :accordionAttributes="accordionAttributes"
          @siwtchShowAccordion="siwtchShowAccordion($event)"
          :number="getStepIndex('shipment-information') + 1"
          :quickStarts="quickStarts"
          :errors="creationErrors"
          @shipmentUpdated="shipmentUpdated()"
          @boxMetricsChange="boxMetricsChange()"
          @itemAttributeValueUpdated="itemAttributeValueUpdated()"
          @formDoneChangeShipment="
            formDoneChange($event, 'shipment-information')
          "
          @updateValidFields="updateValidFields($event)"
          @updateInvalidFields="updateInvalidFields($event)"
          :formVal.sync="order.shipment"
        />

        <NbCourierInformation
          id="courier-information"
          :sectionError="sectionErros.courier"
          :accordionAttributes="accordionAttributes"
          @siwtchShowAccordion="siwtchShowAccordion($event)"
          :number="getStepIndex('courier-information') + 1"
          :allSteps="allSteps"
          :progressValue="progressValue"
          :order="order"
          :currency="order.shipment.currency"
          :triggerResetCourier="triggerResetCourier"
          :errors="creationErrors"
          @formDoneChangeCourier="formDoneChange($event, 'courier-information')"
          @updateValidFields="updateValidFields($event)"
          @updateInvalidFields="updateInvalidFields($event)"
          @resetCourierErrors="resetCourierErrors()"
          :formVal.sync="order.courier"
        />

        <OrderFooter
          :order="order"
          :allSteps="allSteps"
          @checkContractDocuments="checkContractDocuments()"
        />
      </section>
    </div>
    <!-- Modals -->
    <UnsavedWorkModal
      v-if="!loaded && !tookAnyDraftAction"
      name="order-create"
      @ok="loadUnsavedWork"
      @cancel="actionTook"
      @fixVolumeWeight="fixVolumeWeight"
      @loadRejectErros="loadRejectErros"
      :isFrom="isFrom"
    />
    <NbModal
      id="documentModal"
      modalConfig="modal-dialog-centered"
      width="800px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("orderCreatePage.documentsAreMissing") }}
        </div>
      </template>
      <template v-slot:body>
        <div class="">
          <div v-for="hs_code in hs_codes_erros" :key="hs_code.hs_code">
            {{ hs_code.hs_code }}:
            <label
              class="btn btn-sm m-2"
              :class="hs_code.fixed ? 'btn-success' : 'btn-outline-danger'"
            >
              <span>
                {{ $t("upload") }}
                <input
                  type="file"
                  class="d-none"
                  @change="processFile"
                  @click="setHsCode(hs_code)"
                  accept=".pdf"
                />
              </span>
            </label>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div></div>
          <div>
            <NbButton
              class="mr-3"
              variant="secondary"
              data-dismiss="modal"
              aria-label="Close"
              @click="createOrder()"
            >
              {{ $t("ignore") }}
            </NbButton>
            <NbButton data-dismiss="modal" aria-label="Close">
              {{ $t("confirm") }}
              <NbHelpText
                id="popover-include-docs"
                :disabled="hs_code_check"
                @click="checkContractDocuments()"
              >
                {{ $t("orderCreatePage.includeDocuments") }}
              </NbHelpText>
            </NbButton>
          </div>
        </div>
      </template>
    </NbModal>
    <!-- end - Modals -->
  </div>
</template>

<script>
import { directive } from "v-click-outside";
import NbStepsCard from "@/components/orders/create/NbStepsCard.vue";
import NbReceiverInformation from "@/components/orders/create/receiver/NbReceiverInformation.vue";
import NbShipmentInformation from "@/components/orders/create/shipment/NbShipmentInformation.vue";
import NbCourierInformation from "@/components/orders/create/courier/NbCourierInformation.vue";
import OrderFooter from "@/components/orders/create/OrderFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

//services
import QuickStartService from "@/services/QuickStartService.js";
import UnsavedWorkModal from "../../components/orders/create/UnsavedWorkModal.vue";
import NProgress from "nprogress";
import OrderService from "../../services/OrderService";
import LogService from "../../services/LogService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import ProductDocumentService from "@/services/ProductDocumentService";
import UserService from "@/services/UserService";
import SellerService from "@/services/SellerService";
import COUNTRIES from "countries-phone-masks";

const quickStartService = new QuickStartService();
const orderService = new OrderService();
const logService = new LogService();
const productDocumentService = new ProductDocumentService();
const userService = new UserService();
const sellerService = new SellerService();

export default {
  name: "OrdersCreate",
  directives: { clickOutside: directive },
  components: {
    NbStepsCard,
    UnsavedWorkModal,
    LoadingPage,
    NbReceiverInformation,
    NbShipmentInformation,
    NbCourierInformation,
    OrderFooter,
    NbModal,
    NbButton,
    NbHelpText,
  },
  props: ["isFrom", "editId", "errorsProp", "logIndex", "orderIndex"],
  data() {
    return {
      reloadInformations: true,
      loaded: true,
      tookAnyDraftAction: false,
      order: {
        receiver: {
          customer_address: "",
          customer_address_complement: "",
          customer_address_number: "",
          customer_address_quarter: "",
          customer_address_reference: "",
          customer_city: "",
          customer_country: "BR",
          customer_created_at: "",
          customer_description: "",
          customer_document_number: "",
          customer_document_type: "CPF",
          customer_email: "",
          customer_full_name: "",
          customer_id: "",
          customer_phone: "",
          customer_phone_number_country: "",
          customer_platform_id: "",
          customer_postal_code: "",
          customer_seller_id: "",
          customer_state: "",
          customer_updated_at: "",
          is_commercial_destination: false,
          seller_rfb_code: null,
          remetente_is_cpf: false,
          seller_address: "",
          seller_address_complement: "",
          seller_address_number: "",
          seller_city: "",
          seller_country: "BR",
          seller_email: "",
          seller_name: "",
          seller_phone: "",
          seller_phone_code: "+55",
          seller_phone_number: "",
          seller_state: "",
          seller_tax_number: "",
          seller_website: "",
          seller_zip_code: "",
          seller_legal_name: "",
        },
        shipment: {
          currency: "USD",
          collected_taxes_currency: "USD",
          freight_cost: "",
          freight_value: "",
          incoterm: "DDU",
          is_landed_cost: false,
          order_number: "",
          quantity: 1,
          prc: false,
          return_insurance: false,
          sales_channel_id: null,
          sales_channel_order_number: "",
          total_value: "0.00",
          volumes_attributes: [
            {
              created_at: "",
              default_choice: true,
              false: "",
              freight_cost: null,
              freight_value: null,
              collected_icms: null,
              collected_ii: null,
              has_nocuous_items: false,
              height: "",
              id: null,
              length: "",
              null: "",
              platform_id: null,
              seller_id: null,
              total_weight: "",
              true: "",
              updated_at: "",
              volume_name: "",
              volume_type: "",
              weight: "",
              width: "",
              order_items_attributes: [
                {
                  box: "Caixa sapatos",
                  category: "",
                  created_at: "",
                  description: "",
                  freight_cost: "",
                  freight_value: "",
                  hs_code: null,
                  id: null,
                  name: "",
                  origin_country: "",
                  platform_id: null,
                  quantity: 1,
                  seller_id: null,
                  sku: "",
                  total: 0,
                  unit_price: "",
                  updated_at: "",
                  value: "0.00",
                  weight: 0,
                },
              ],
            },
          ],
        },
        courier: {
          contract_id: "",
          customer_full_name: "",
          ddp_cost: 0,
          dhl_time: null,
          dhl_type: false,
          freight_cost: 0,
          freight_value: 0,
          handling_time: 0,
          insurance_cost: 0,
          selectedQuote: null,
        },
      },
      currentSeller: {},
      creationErrors: {},
      errorsCopy: "",
      hs_codes_erros: [],
      hs_code_check: null,
      hs_code: null,
      new_product_document: null,
      file: null,
      isSaving: false,
      timer: null,
      accordionColapse: false,
      quickStartsReady: false,
      quickStarts: [],
      triggerResetCourier: false,
      allSteps: [
        {
          title: `1 - ${this.$t("orderCreatePage.receiver")}`,
          link: "receiver-information",
          formDone: false,
        },
        {
          title: `2 - ${this.$t("orderCreatePage.shipment")}`,
          link: "shipment-information",
          formDone: false,
        },
        {
          title: `3 - ${this.$t("orderCreatePage.courier")}`,
          link: "courier-information",
          formDone: false,
        },
      ],
      sectionErros: {
        receiver: false,
        shipment: false,
        boxes: false,
        courier: false,
      },
      accordionAttributes: {
        receiver: true,
        seller: false,
        shipment: false,
        courier: false,
      },
    };
  },
  beforeMount() {
    /* isFrom logic
    const isClone = JSON.parse(localStorage.getItem("isClone"));
    if (isClone) {
      this.isFrom = "isClone";
      localStorage.removeItem("isClone");
    }
    this.editId = JSON.parse(localStorage.getItem("isEdit"));
    if (this.editId) {
      this.isFrom = "isEdit";
    }
    */
  },
  created() {
    this.fetchCurrentSeller();
    this.loadQuickStart();
    localStorage.removeItem("isEdit");
    if (this.errorsProp) {
      this.creationErrors = this.errorsProp;
      this.checkSectionErros();
    }
  },
  methods: {
    siwtchIsSaving(savingVal) {
      if (typeof savingVal === "boolean") {
        this.isSaving = savingVal;
      } else {
        this.isSaving = !this.isSaving;
      }

      this.$root.$emit("isSaving", this.isSaving);
    },
    loadRejectErros() {
      if (this.errorsProp) {
        this.creationErrors = this.errorsProp;
        this.checkSectionErros();
      }
    },
    onLoaded() {
      this.loaded = false;
    },
    loadUnsavedWork({ data }) {
      this.actionTook();
      //this.reloadInformations = false;
      this.order = { ...this.order, ...data };
      //this.fixSellerPhone(this.order.receiver.seller_phone);
      //??? this.currentSeller.is_multiple
      /* setTimeout(() => {
        this.reloadInformations = true;
      }, 200); */
    },
    actionTook() {
      this.tookAnyDraftAction = true;
    },
    saveUnsavedWork() {
      this.siwtchIsSaving(true);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.timer = null;
        this.siwtchIsSaving(false);
        this.$helpers.saveUnsavedWork("order-create", this.order);
      }, 1500);
    },
    calculateTotalValue(volumes) {
      let total = 0;

      volumes.forEach((volume) => {
        volume.order_items_attributes.forEach((product) => {
          product.value = product.unit_price;
          total += parseFloat(product.unit_price) * Number(product.quantity);
        });
      });

      return total;
    },
    calculateVolumesWeight(volumes, err) {
      if (err) {
        return volumes.map((volume) => {
          if (volume.empty_weight > 0) {
            volume.weight = volume.empty_weight;
          } else {
            volume.order_items_attributes.forEach((product) => {
              const total =
                volume.weight - (product.weight || 0) * (product.quantity || 0);
              if (total > 0) {
                volume.weight = parseFloat(total).toFixed(3);
              } else {
                volume.weight = 0.0;
              }
            });
          }
          return volume;
        });
      } else {
        return volumes.map((volume) => {
          volume.empty_weight = volume.weight; //bug fix with total weight

          let total = parseFloat(volume.empty_weight || 0);

          volume.order_items_attributes.forEach((product) => {
            total += (product.weight || 0) * (product.quantity || 0);
          });

          if (parseFloat(total) < 0) {
            total = 0.0;
          }
          volume.weight = total.toFixed(3);

          return volume;
        });
      }
    },
    checkContractDocuments() {
      const dataToSend = this.buildDataToSend();
      const hs_code_list = [];
      const hash_data = {};

      dataToSend.volumes_attributes.forEach((volume) => {
        volume.order_items_attributes.forEach((item) => {
          hs_code_list.push(item.hs_code);
        });
      });

      hash_data["hs_codes"] = hs_code_list;
      hash_data["contract_id"] = dataToSend.contract_id;

      orderService
        .getHscodes(hash_data)
        .then(() => {
          this.createOrder();
        })
        .catch((error) => {
          this.hs_codes_erros = error.response.data.data.map((hs_code) => {
            return { hs_code: hs_code, fixed: false };
          });
          this.$helpers.openModal("documentModal");
        });
    },
    createOrder() {
      const dataToSend = this.buildDataToSend();
      this.setAllAccordionAttributes(false);
      this.scrollToId("NbStepsCard");
      if (this.editId) {
        this.updateOrder(dataToSend);
        return;
      }
      if (this.isFrom == "isReject") {
        this.updateRejectOrder(dataToSend);
        return;
      }

      this.createNewOrder(dataToSend);
    },
    updateOrder(dataToSend) {
      this.loaded = true;
      orderService
        .updateOrder(this.editId, dataToSend)
        .then((response) => {
          let message =
            this.$t("orderCreatePage.orderUpdated") +
            this.$tc("orderCreatePage.dontForgetOverpack", this.canOverpack);
          if (response && response.data && response.data.messages) {
            message = response.data.messages[0];
          }

          this.$router.push("/orders").then(() => {
            this.$helpers.toast(message, "success", 3000);
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages
          ) {
            this.creationErrors = error.response.data.messages[0];
            this.checkSectionErros();
            this.toastErrors(this.creationErrors);
          }

          this.switchTriggerResetCourier(true);
          NProgress.done();
        })
        .finally(this.onLoaded);
    },
    updateRejectOrder(dataToSend) {
      this.loaded = false;
      orderService
        .setOrder(dataToSend)
        .then(() => {
          logService.validateOrder(this.logIndex).then((response) => {
            this.loaded = true;
            let message =
              this.$t("orderCreatePage.rejectOrderUpdated") +
              this.$tc("orderCreatePage.dontForgetOverpack", this.canOverpack);
            if (response && response.data && response.data.messages) {
              message = response.data.messages[0];
            }
            this.$router.push("/orders").then(() => {
              this.$helpers.toast(message, "success", 3000);
            });
          });
        })
        .catch((error) => {
          const dataToSend = this.buildDataToSend();

          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.creationErrors = error.response.data.messages[0];
            this.checkSectionErros();
            this.errorsCopy = error.response.data.messages[0];
            logService.updateOrderLog(
              { order: dataToSend, errors: error.response.data.messages[0] },
              this.logIndex
            );
            this.toastErrors(this.creationErrors);
          }

          this.switchTriggerResetCourier(true);
        })
        .then(() => {
          this.dispatchErrorsInVolumesItems();
          this.$forceUpdate();
          NProgress.done();
        })
        .finally(this.onLoaded);
    },
    createNewOrder(dataToSend) {
      this.loaded = true;
      orderService
        .setOrder(dataToSend)
        .then((response) => {
          let message =
            this.$t("orderCreatePage.orderCreated") +
            this.$tc("orderCreatePage.dontForgetOverpack", this.canOverpack);
          if (response && response.data && response.data.messages) {
            message = response.data.messages[0];
          }
          this.$router.push("/orders").then(() => {
            this.$helpers.toast(message, "success", 3000);
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages
          ) {
            this.creationErrors = error.response.data.messages[0];
            this.checkSectionErros();
            this.toastErrors(this.creationErrors);
          }

          this.switchTriggerResetCourier(true);
          NProgress.done();
        })
        .finally(this.onLoaded);
    },
    toastErrors(errors) {
      const trackedErrors = [
        "order_number",
        "return_insurance",
        "incoterm",
        "sales_channel_id",
        "total_weight",
        "total_value",
        "is_landed_cost",
        "seller_address",
        "seller_address_complement",
        "seller_address_number",
        "seller_city",
        "seller_country",
        "seller_email",
        "seller_name",
        "seller_phone",
        "seller_phone_code",
        "seller_phone_number",
        "seller_state",
        "seller_tax_number",
        "seller_website",
        "seller_zip_code",
        "seller_legal_name",
        "customer_address",
        "customer_address_complement",
        "customer_address_number",
        "customer_address_quarter",
        "customer_address_reference",
        "customer_city",
        "customer_country",
        "customer_created_at",
        "customer_description",
        "customer_document_number",
        "customer_document_type",
        "customer_email",
        "customer_full_name",
        "customer_id",
        "customer_phone",
        "customer_phone_number_country",
        "customer_platform_id",
        "customer_postal_code",
        "customer_seller_id",
        "customer_state",
      ];
      const untrakedErrors = Object.keys(errors).filter((key) => {
        return !trackedErrors.includes(key) && !key.startsWith("volumes[");
      });

      untrakedErrors.forEach((key) => {
        const captalizedKey = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        const errorMessage = `${captalizedKey}: ${errors[key][0]}`;

        this.$helpers.toast(errorMessage, "danger", 10000);
      });
    },
    buildDataToSend(isError) {
      const formData = {
        ...this.order.shipment,
        ...this.order.courier,
        ...this.order.receiver,
      };
      let dataToSend = JSON.parse(JSON.stringify(formData));

      dataToSend.total_value = this.calculateTotalValue(
        dataToSend.volumes_attributes
      );
      dataToSend.volumes_attributes = this.calculateVolumesWeight(
        dataToSend.volumes_attributes,
        isError
      );
      dataToSend.volumes_attributes.forEach((element) => {
        element.weight = parseFloat(element.weight);
        element.width = parseFloat(element.width);
        element.height = parseFloat(element.height);
        element.length = parseFloat(element.length);
      });

      //gambiarra: remove attributos indesejaveis
      delete dataToSend.freight_value;
      delete dataToSend.freight_cost;
      return dataToSend;
    },
    fixVolumeWeight() {
      return this.order.shipment.volumes_attributes.map((volume) => {
        volume.empty_weight = volume.weight; //bug fix with total weight

        let total = parseFloat(volume.empty_weight || 0).toFixed(3);

        volume.order_items_attributes.forEach((product) => {
          total -=
            (parseFloat(product.weight) || 0) *
            (parseFloat(product.quantity) || 0);
          product.weight = product.weight.toString(); //bug fix products not get val weight in float
        });

        if (parseFloat(total) < 0) {
          total = 0.0;
        }

        volume.weight = parseFloat(total).toFixed(3);

        return volume;
      });
    },
    dispatchErrorsInVolumesItems() {
      for (
        let i = 0, l = this.order.shipment.volumes_attributes.length;
        i < l;
        i++
      ) {
        this.order.shipment.volumes_attributes[i]["errors"] = {};
        for (
          let j = 0,
            k =
              this.order.shipment.volumes_attributes[i].order_items_attributes
                .length;
          j < k;
          j++
        ) {
          this.order.shipment.volumes_attributes[i].order_items_attributes[j][
            "errors"
          ] = {};
        }
      }
      for (var key in this.errorsCopy) {
        if (key.substring(0, 8) === "volumes[") {
          var i = 9;
          while (key[i] !== "]") {
            i++;
          }
          var volume_index = parseInt(key.substring(8, i));
          if (key.substring(i, 14 + i) === "].order_items[") {
            var j = 15 + i;
            while (key[j] !== "]") {
              j++;
            }
            var item_index = parseInt(key.substring(14 + i, j));
            this.order.shipment.volumes_attributes[
              volume_index
            ].order_items_attributes[item_index]["errors"][
              key.substring(j + 2, key.length)
            ] = this.errorsCopy[key];
          } else {
            this.order.shipment.volumes_attributes[volume_index]["errors"][
              key.substring(i + 2, key.length)
            ] = this.errorsCopy[key];
          }
        }
      }
    },

    setHsCode(setHsCode) {
      this.hs_code = setHsCode.hs_code;
    },

    processFile(event) {
      this.file = event.target.files[0];

      if (this.file.type == "application/pdf") {
        this.new_product_document = new FormData();
        this.new_product_document.append("file", this.file, this.file.name);
        this.new_product_document.append("hs_code", this.hs_code);

        this.hs_codes_erros.forEach((element) => {
          if (element.hs_code == this.hs_code) {
            element.fixed = true;
          }
        });
      } else {
        this.$helpers.toast(this.$t("isNotPDF"), "danger");
        this.hs_codes_erros.forEach((element) => {
          if (element.hs_code == this.hs_code) {
            element.fixed = false;
          }
        });
        return;
      }
      this.createProductDocument();
    },
    createProductDocument() {
      productDocumentService
        .createProductDocument(this.new_product_document)
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
        });
    },
    /* closeCollapse() {
      this.accordionColapse = false;
    }, */

    loadQuickStart() {
      quickStartService
        .getQuicks("order")
        .then(({ data }) => {
          this.quickStarts = data.data;
          //avoid trigger auto save when quickstarts loads;
          setTimeout(() => {
            this.quickStartsReady = true;
          }, 1200);
        })
        .finally(this.onLoaded);
    },
    checkTookAction() {
      if (this.tookAnyDraftAction && this.quickStartsReady) {
        this.saveUnsavedWork();
      }
    },
    receiverUpdated() {
      this.checkTookAction();
    },
    shipmentUpdated() {
      this.checkTookAction();
    },
    itemAttributeValueUpdated() {
      this.order.shipment.total_value = 0;
      this.order.shipment.quantity = 0;

      this.order.shipment.volumes_attributes.forEach((volumes_attribute) => {
        volumes_attribute.order_items_attributes.forEach(
          (order_items_attribute) => {
            this.order.shipment.total_value = (
              Number(this.order.shipment.total_value) +
              Number(order_items_attribute.value)
            ).toFixed(2);
            this.order.shipment.quantity =
              Number(this.order.shipment.quantity) +
              Number(order_items_attribute.quantity);
          }
        );
      });
      this.switchTriggerResetCourier();
    },
    boxMetricsChange() {
      this.switchTriggerResetCourier();
    },
    switchTriggerResetCourier(checkCourier) {
      if (checkCourier) {
        //check if has error just for courier
        if (
          !this.sectionErros.boxes &&
          this.sectionErros.courier &&
          !this.sectionErros.receiver &&
          !this.sectionErros.seller &&
          !this.sectionErros.shipment
        ) {
          return;
        }
      }
      this.triggerResetCourier = !this.triggerResetCourier;
    },
    formDoneChange(event, id) {
      this.allSteps.find((step) => step.link === id).formDone = event;
    },
    resetCourierErrors() {
      //remove in courier
      const patterns = [
        /^volumes\[\d+\]\.freight_cost$/,
        /^volumes\[\d+\]\.freight_value$/,
        /^volumes\[\d+\]\.duties_tax_value$/,
        /^volumes\[\d+\]\.sales_tax_value$/,
        /^volumes\[\d+\]\.last_mile_number$/,
      ];

      for (const key in this.creationErrors) {
        if (patterns.some((pattern) => key.match(pattern))) {
          delete this.creationErrors[key];
        }
      }
      this.checkSectionErros();
    },
    updateValidFields(validFieldId) {
      //obs tem que startar os campos com erro?

      this.checkTookAction();
      this.creationErrors[validFieldId] = ""; //atualizar no front
      delete this.creationErrors[validFieldId];

      this.checkSectionErros();
    },
    updateInvalidFields(invalidField) {
      this.checkTookAction();
      this.creationErrors = {
        ...this.creationErrors,
        [invalidField.id]: [invalidField.message],
      };
      this.$emit("invalid");
      this.checkSectionErros();
    },
    fetchCurrentSeller() {
      sellerService
        .getCurrentSeller()
        .then((response) => {
          this.currentSeller = response.data.data;
          //test seller
          //this.currentSeller.is_multiple = true;
        })
        .finally(this.onLoaded);
    },
    getStepIndex(str) {
      return this.allSteps.findIndex((step) => step.link === str);
    },
    hasReceiverErrorss(errors) {
      const usedShipmentKeys = ["customer"];
      return usedShipmentKeys.some((keyName) =>
        Object.keys(errors).some((errorKey) => errorKey.includes(keyName))
      );
      //return !!Object.keys(errors).find((keyName) => keyName.startsWith('customer'))
    },
    hasSellerErrorss(errors) {
      const usedShipmentKeys = ["remetente_is_cpf", "seller"];
      return usedShipmentKeys.some((keyName) =>
        Object.keys(errors).some((errorKey) => errorKey.includes(keyName))
      );
      //return !!Object.keys(errors).find((keyName) => keyName.startsWith('customer'))
    },

    hasShipmentErrorss(errors) {
      const usedShipmentKeys = [
        "order_number",
        "return_insurance",
        "incoterm",
        "sales_channel_id",
        "total_weight",
        //'overpack_id', //overpack removido
        "total_value",
        "is_landed_cost",
      ];
      return !!Object.keys(errors).find((keyName) =>
        usedShipmentKeys.includes(keyName)
      );
    },
    hasBoxesErrorss(errors) {
      let finalResult = false;
      const filteredErrs = {};

      for (const key in errors) {
        if (!key.match(/^volumes\[\d+\]\.last_mile_number$/)) {
          filteredErrs[key] = errors[key];
        }
      }

      let autoTaxErr = !!Object.keys(filteredErrs).find((keyName) =>
        keyName.match(/volumes\[\d+\].(duties|sales)_tax_value/)
      );
      let anyVolumeErr = !!Object.keys(filteredErrs).find((keyName) =>
        keyName.startsWith("volumes")
      );
      let hsCodeErr = !!Object.keys(filteredErrs).find((error) =>
        error.startsWith("hs_code_")
      );
      let notAutoTaxErr = !!Object.keys(filteredErrs).find((keyName) =>
        keyName.match(/^volumes\[\d+\].((?!(duties|sales)_tax_value).)*$/)
      );

      if (autoTaxErr) {
        if (notAutoTaxErr || hsCodeErr) {
          finalResult = true;
        }
      } else {
        if (anyVolumeErr || hsCodeErr) {
          finalResult = true;
        } else {
          finalResult = false;
        }
      }
      return finalResult;
    },
    hasCourierErrors(errors) {
      let finalResult = false;
      const usedCourierKeys = [
        /freight_value/,
        /handling_time/,
        /contract_id/,
        /volumes\[\d+\].(duties|sales)_tax_value/,
        /^volumes\[\d+\]\.last_mile_number$/,
      ];
      usedCourierKeys.forEach((usedCourierKey) => {
        if (
          Object.keys(errors).find((keyName) => keyName.match(usedCourierKey))
        ) {
          finalResult = true;
        }
      });

      return finalResult;
    },
    scrollToId(id) {
      const elmnt = document.getElementById(id);
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
    setAllAccordionAttributes(val) {
      for (const key in this.accordionAttributes) {
        this.accordionAttributes[key] = val;
      }
    },
    siwtchShowAccordion(event) {
      if (event !== "all") {
        this.setAllAccordionAttributes(false);

        this.accordionAttributes[event] = true;
        return;
      }

      this.setAllAccordionAttributes(true);
    },
    checkSectionErros() {
      this.sectionErros = {
        receiver: false,
        shipment: false,
        boxes: false,
        courier: false,
      };
      /*
      //check if creationErrors is empty
      if(Object.keys(this.creationErrors).length !== 0) {
      }
      */
      this.sectionErros = {
        receiver: this.hasReceiverErrorss(this.creationErrors),
        shipment: this.hasShipmentErrorss(this.creationErrors),
        boxes: this.hasBoxesErrorss(this.creationErrors),
        courier: this.hasCourierErrors(this.creationErrors),
      };
      if (this.currentSeller.is_multiple) {
        this.sectionErros.seller = this.hasSellerErrorss(this.creationErrors);
      }

      return this.sectionErros;
    },
  },
  watch: {
    hs_codes_erros: {
      //deep: true,
      handler() {
        this.hs_code_check = false;
        this.hs_codes_erros.forEach((hs_code) => {
          if (!hs_code.fixed) {
            this.hs_code_check = true;
          }
        });
      },
    },
    "currentSeller.is_multiple": function (newVal) {
      if (newVal) {
        this.allSteps = [
          {
            title: `1 - ${this.$t("orderCreatePage.receiver")}`,
            link: "receiver-information",
            formDone: false,
          },
          {
            title: `2 - ${this.$t("orderCreatePage.sender")}`,
            link: "sender-information",
            formDone: false,
          },
          {
            title: `3 - ${this.$t("orderCreatePage.shipment")}`,
            link: "shipment-information",
            formDone: false,
          },
          {
            title: `4 - ${this.$t("orderCreatePage.courier")}`,
            link: "courier-information",
            formDone: false,
          },
        ];
      }
    },
    "order.receiver.seller_country": function (newVal) {
      const phone_code = COUNTRIES.find(
        (country) => country.iso == newVal
      ).code;
      if (newVal) {
        this.order.receiver.seller_phone_code = phone_code;
      }
    },
  },
  computed: {
    progressValue() {
      let progressValue = 0;
      this.allSteps.forEach((step) => {
        if (step.formDone) {
          progressValue++;
        }
      });

      return progressValue;
    },
    canOverpack() {
      const can_overpack = userService.getUserAttribute("can_overpack");
      if (can_overpack) {
        return 1;
      }
      return 2;
    },

    orderSummary() {
      let orderSummary = {
        total_value: 0,
        quantity: 0,
        boxLength: 0,
      };
      orderSummary.boxLength = this.order.shipment.volumes_attributes.length;

      this.order.shipment.volumes_attributes.forEach((volumes_attribute) => {
        volumes_attribute.order_items_attributes.forEach(
          (order_items_attribute) => {
            orderSummary.total_value = (
              Number(orderSummary.total_value) +
              Number(order_items_attribute.value)
            ).toFixed(2);
            orderSummary.quantity =
              Number(orderSummary.quantity) +
              Number(order_items_attribute.quantity);
          }
        );
      });

      return orderSummary;
    },
  },
};
</script>
