<template>
  <div>
    <!-- table -->
    <div class="table-wrapper" :class="shortThead ? 'short-thead' : ''">
      <div class="info-table">
        <div class="float-left mr-4" v-if="tableOf">
          <span v-if="total > 0"
            >{{ total }} {{ $tc(`${tableOf}`, total) }}
            {{ $t("components.nbTable.wereFound") }}</span
          >
        </div>
        <br />

        <div class="d-flex justify-content-between">
          <!--
          <div class="mx-1 my-2">
            <slot name="buttons-both"></slot>
          </div>
          <div class="mx-1 my-2">
            <slot name="buttons-head"></slot>
          </div>
          -->
          <div class="mx-1 my-2">
            <slot name="buttons-start"></slot>
          </div>

          <!-- Show selected items -->
          <div v-if="selectable">
            <div v-if="isCheckAll" class="display-bar">
              <div class="d-flex bg-light py-2 px-2 m-0 justify-content-center">
                <span v-if="tableOf" class="text-selectAll text-link"
                  ><span
                    v-if="total != selectedItems.length"
                    class="text-selectAll text-link"
                    >{{ $t("components.nbTable.all") }}</span
                  ><span class="font-weight-bold">{{
                    selectedItems.length
                  }}</span>
                  {{ $tc(`${tableOf}.found`, selectedItems.length) }}
                  <span
                    v-if="total != selectedItems.length"
                    class="text-selectAll text-link"
                    >{{ $t("components.nbTable.onThisPage") }}</span
                  >
                  {{
                    $tc("components.nbTable.isSelected", selectedItems.length)
                  }}.</span
                >
                <a
                  v-if="total == selectedItems.length"
                  href=""
                  @click.prevent="unselectAll"
                  class="selected-all"
                >
                  {{ $t("clear") }}</a
                >
                <a
                  v-else
                  href=""
                  class="selected-all"
                  @click.prevent="selectAll"
                >
                  {{ $t("selectAll") }} {{ total }}
                  {{ $tc(`${tableOf}.found`, total) }}</a
                >
              </div>
            </div>
            <div v-else-if="selectedItems.length > 0" class="display-bar mt-1">
              <div class="d-flex bg-light py-2 px-4 m-0 justify-content-center">
                <span v-if="tableOf" class="text-selectAll text-link"
                  ><span class="font-weight-bold">{{
                    selectedItems.length
                  }}</span>
                  {{ $tc(`${tableOf}.found`, selectedItems.length) }}
                  {{ $t("selected") }}.</span
                >
                <a href="" @click.prevent="unselectAll" class="selected-all">
                  {{ $t("clear") }}</a
                >
              </div>
            </div>
            <div v-else class="display-bar mt-1"></div>
          </div>

          <div class="mx-1 my-2">
            <slot name="buttons-end"></slot>
          </div>
        </div>
      </div>
      <table class="table table-striped shadow-sm rounded">
        <thead class="tr-nbtable" :class="shortThead ? 'short-thead' : ''">
          <tr>
            <th v-if="selectable">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'itemAll' + tableOf"
                  :value="items"
                  @click="checkAll()"
                  v-model="isCheckAll"
                />
                <label
                  :id="'exchange_element' + tableOf"
                  class="text-link text-center custom-control-label"
                  :onClick="`document.getElementById('itemAll${tableOf}').click()`"
                  :for="'item-' + items + tableOf"
                  >{{ $t("selectAll") }}</label
                >
              </div>
            </th>
            <th
              v-for="field in fields"
              :key="field.key"
              class="text-link text-center"
            >
              <slot :name="`label(${removeSpaces(field.label)})`" :item="field">
                {{ field.label ? field.label : "" }}
              </slot>
            </th>
            <th
              class="text-link text-center"
              v-if="editable || editableByModal"
            >
              {{ $t("edit") }}
            </th>
            <th class="text-link text-center" v-if="deletable">
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            @click="clickable ? handleClick(index) : null"
          >
            <td v-if="selectable" class="text-center align-middle">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'item-' + item.id + tableOf"
                  v-model="selectedItems"
                  :value="item.id"
                  @change="updateCheckall()"
                />
                <label
                  class="custom-control-label"
                  :for="'item-' + item.id + tableOf"
                ></label>
              </div>
            </td>

            <td
              v-for="field in fields"
              :key="field.key"
              class="text-center align-middle"
            >
              <!-- :class="editable || editableByModal ? '': 'align-middle' " -->
              <div v-if="index == editingItem">
                <input
                  v-if="checkType(item[field.key])"
                  :id="`edit-${field.key + tableOf}`"
                  v-model="item[`${field.key}`]"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`${field.key}`] }"
                />
                <div v-else>
                  <label :for="`edit-${field.key + tableOf}`" class="mr-1 pb-1">
                    {{ field.label }}</label
                  >
                  <input
                    :id="`edit-${field.key + tableOf}`"
                    class="mr-1"
                    type="checkbox"
                    autocomplete="off"
                    v-model="item[`${field.key}`]"
                  />
                </div>
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors[`${field.key}`]"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>

              <slot
                v-else
                :name="`cell(${field.key})`"
                :item="setItem(item, index)"
              >
                <p class="m-0">
                  {{ item[field.key] ? item[field.key] : "-" }}
                </p>
              </slot>
            </td>

            <td v-if="editable || editableByModal" class="align-middle">
              <button
                v-if="index == editingItem"
                class="btn btn-success px-2 py-1 m-1"
                @click="saveItem(index)"
              >
                <i class="far fa-save"></i>
              </button>

              <button
                v-if="index == editingItem"
                class="btn btn-light px-2 py-1 m-1 border border-dark"
                @click="hideModifyForm(index)"
              >
                <i class="fas fa-ban"></i>
              </button>

              <button
                v-else
                class="btn btn-primary px-2 py-1 m-1"
                @click="displayModifyForm(index, item)"
              >
                <i class="fas fa-pen"></i>
              </button>
            </td>
            <td v-if="deletable" class="align-middle">
              <button
                class="btn btn-danger px-2 py-1 m-1"
                data-toggle="modal"
                :data-target="'#modalDelete' + tableOf"
                @click="deletingItem = index"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mx-1 my-2">
      <slot name="buttons-both"></slot>
    </div>
    <div class="mx-1 my-2">
      <slot name="buttons-foot"></slot>
    </div>

    <!-- modals -->
    <div class="modal fade" :id="'modalDelete' + tableOf">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              v-if="tableOf"
              class="modal-title"
              :id="'modalDeleteTitle' + tableOf"
            >
              {{
                $t("confirmDelete", {
                  val:
                    selectedItems.length > 1
                      ? "these "
                      : "this " +
                        this.$tc(`${tableOf}.found`, selectedItems.length || 1),
                })
              }}?
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <button
              class="button-danger float-right ml-3"
              @click="removeItem(deletingItem)"
              data-dismiss="modal"
            >
              {{ $t("delete") }}
            </button>
            <button class="button-light float-right ml-3" data-dismiss="modal">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NbTable",

  components: {},
  props: {
    tableOf: {
      type: String,
      required: false,
    },
    total: {
      type: Number,
      required: false,
    },
    itemsIds: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
    },
    editableByModal: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    deletable: {
      type: Boolean,
      required: false,
    },
    errors: {
      required: false,
    },
    shortThead: {
      type: Boolean,
      required: false,
    },
  },
  data: () => {
    return {
      selectedItems: [],
      isCheckAll: false,
      editingItem: null,
      editingItemCopy: {},
      deletingItem: {},
    };
  },
  methods: {
    checkType(val) {
      if (typeof val === "boolean") {
        return false;
      }
      return true;
    },
    handleClick(index) {
      this.$emit("clicked", index);
    },
    saveItem(index) {
      this.$emit("saveItem", this.items[index]);
    },
    removeItem(index) {
      this.$emit("removeItem", this.items[index]);
    },
    displayModifyForm(index, item) {
      if (this.editableByModal) {
        const itemToEmit = JSON.parse(JSON.stringify(item));
        this.$emit("editObject", itemToEmit);
        return;
      }
      if (this.editingItem == null) {
        this.editingItemCopy = JSON.parse(JSON.stringify(this.items[index]));
        this.editingItem = index;
      }
    },
    hideModifyForm(index) {
      this.editingItem = null;
      //this.errors = [];
      this.items[index] = JSON.parse(JSON.stringify(this.editingItemCopy));
    },
    removeSpaces(stringui) {
      return stringui.replaceAll(" ", "");
    },
    setItem(item, index) {
      let itemToReturn = {};
      itemToReturn = item;
      itemToReturn.index = index;
      return itemToReturn;
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      if (this.isCheckAll) {
        this.selectedItems = [];
        for (var item in this.items) {
          this.selectedItems.push(this.items[item].id);
        }
        document.querySelector(
          `label#exchange_element${this.tableOf}`
        ).innerHTML = this.$t("clearSelected");
      } else {
        this.selectedItems = [];
        document.querySelector(
          `label#exchange_element${this.tableOf}`
        ).innerHTML = this.$t("selectAll");
      }
    },
    updateCheckall() {
      //rodar isso quando a tabela alterar!!!
      if (this.selectedItems.length == this.items.length) {
        this.isCheckAll = true;
        document.querySelector(
          `label#exchange_element${this.tableOf}`
        ).innerHTML = this.$t("clearSelected");
      } else {
        this.isCheckAll = false;
        document.querySelector(
          `label#exchange_element${this.tableOf}`
        ).innerHTML = this.$t("selectAll");
      }
    },
    selectAll() {
      this.selectedItems = [];
      this.selectedItems = this.itemsIds;
    },
    unselectAll() {
      this.selectedItems = [];
      this.updateCheckall();
    },
  },
  watch: {
    selectedItems(newValue) {
      this.$emit("selectedItems", newValue);
    },
  },
};
</script>

<style lang="scss">
.info-table {
  background: var(--white);
  top: 0px;
  left: 0px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  border-bottom: 1px solid var(--shadow);
}
.table-wrapper {
  max-width: 100% !important;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 220px);
}
.table-wrapper::-webkit-scrollbar {
  width: 0px;
  height: 18px;
  padding: 3px;
}

.table-wrapper::-webkit-scrollbar-track {
  border-radius: 8px;
}

.table.table-striped > .tr-nbtable {
  top: 70px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  background: var(--white);
}
.table.table-striped > .tr-nbtable.short-thead {
  top: 38px;
  position: sticky;
  z-index: 2;
  background: var(--white);
}
.display-bar {
  height: 56px;
  max-height: 56px;
  margin-top: 0.25rem !important;
}
</style>
