<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <div class="pb-4">
        <button
          class="button-primary float-right"
          :class="isReversable ? 'transparent' : ''"
          data-toggle="modal"
          data-target="#reverse-modal"
          :disabled="isReversable"
          :title="isReversable ? $t('orderViewPage.isReversable') : ''"
        >
          {{ $t("volumesReversePage.reverseVolume") }}
        </button>
      </div>
      <back-button />
      <h5 class="font-weight-light mb-4">
        {{ $t("orderViewPage.title") }}:
        <span class="font-weight-bold">{{ order.order_number }}</span>
        <span v-if="order.current_status">
          <span
            v-if="
              order?.current_status?.name === 'PENDING' ||
              order?.current_status?.name === 'FAILED_ATTEMPT' ||
              order?.current_status?.name === 'EXCEPTION IN PICKUP'
            "
            class="badge badge-warning ml-1"
            >{{ order?.current_status?.name }}</span
          >
          <span
            v-else-if="
              order?.current_status?.name === 'PARTIAL DELIVERY FAILED' ||
              order?.current_status?.name === 'EXPIRED' ||
              order?.current_status?.name === 'EXCEPTION'
            "
            class="badge badge-danger ml-1"
            >{{ order?.current_status?.name }}</span
          >
          <span v-else class="badge badge-success ml-1">{{
            order?.current_status?.name
          }}</span>
          <span
            v-if="order.is_dispatched && contractDispatchable"
            class="badge badge-success ml-1 text-uppercase"
            >Dispatched</span
          >
        </span>
        <button
          class="button-primary float-right mb-2"
          @click="trackSearch(order.reference)"
          title="Tracking"
        >
          <i class="fas fa-chevron-circle-right mr-1"></i
          >{{ $t("ordersListPage.toTrackingPage") }}
        </button>
        <button
          v-if="!order.is_dispatched && contractDispatchable"
          target="_blank"
          class="button-primary float-right mb-2 mx-1"
          title="Dispatch This Order"
          @click="dispatchOrder"
        >
          <i class="fas fa-chevron-circle-right mr-1"></i>{{ $t("dispatch") }}
        </button>
      </h5>

      <div class="card-deck">
        <!-- order -->
        <div class="card bg-light border-white rounded">
          <div class="pb-2 pt-4 px-3">
            <h5 class="pl-3">{{ $t("order") }}</h5>
            <hr class="mb-0" />
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("orderViewPage.orderNumber") }}:</strong>
            </div>
            <div class="col">{{ order.order_number }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("salesChannel") }}:</strong>
            </div>
            <div class="col">{{ order.sales_channel_name }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("salesChannelOrderNumber") }}:</strong>
            </div>
            <div class="col">{{ order.sales_channel_order_number }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("trackingNumber") }}:</strong>
            </div>
            <div class="col">{{ order.reference }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong
                >{{ $t("ordersListPage.firstMileTrackingNumber") }}:</strong
              >
            </div>
            <div class="col">
              {{ getFirstMileTrackingNumbers(order.volumes) }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("totalValue") }}:</strong>
            </div>
            <div class="col">
              {{ order.currency + " " + standardizeValue(order.total_value) }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("ordersListPage.sellersName") }}:</strong>
            </div>
            <button
              class="btn btn-link"
              data-toggle="modal"
              data-target="#modal-shipper-name"
            >
              {{ order.shipper_name }}
            </button>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong
                >{{ $t("ordersListPage.isCommercialDestination") }}:</strong
              >
            </div>
            <div class="col">{{ order.is_commercial_destination }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("orderViewPage.observations") }}:</strong>
            </div>
            <div class="col">{{ order.observations }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col p-2 mx-3 text-center">
              <h6>
                <strong>{{ $t("ordersListPage.firstMileLabel") }}</strong>
              </h6>
              <a
                href="#"
                v-on:click="generateFirstMile(order.id)"
                title="First Mile Label"
              >
                <i class="fas fa-file-alt fa-lg text-dark"></i>
              </a>
            </div>

            <div class="col p-2 mx-3 text-center">
              <h6>
                <strong>{{ $t("ordersListPage.invoice") }}</strong>
              </h6>
              <a
                href="#"
                v-on:click="generateCommercialInvoice(order.id)"
                title="Commercial Invoice"
              >
                <i class="fas fa-file-alt fa-lg text-dark"></i>
              </a>
            </div>

            <div v-if="order.contain_battery" class="col p-2 mx-3 text-center">
              <h6>
                <strong>{{ $t("orderViewPage.shipperConfirmation") }}</strong>
              </h6>
              <a
                href="#"
                v-on:click="generateShipperConfirmation(order.id)"
                title="Shipper Confirmation"
              >
                <i class="far fa-file fa-lg text-dark"></i>
              </a>
            </div>

            <div class="col p-2 mx-3 text-center">
              <h6>
                <strong>{{ $t("printingsPage.packingSlip") }}</strong>
              </h6>
              <a
                href="#"
                v-on:click="generatePackingSlipLabel(order.id)"
                title="Packing Slip"
              >
                <i class="fas fa-file fa-lg text-dark"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- ./order -->

        <!-- shipping -->
        <div class="card bg-light border-white rounded">
          <div class="pb-2 pt-4 px-3">
            <h5 class="pl-3">{{ $t("orderViewPage.shipping") }}</h5>
            <hr class="mb-0" />
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("ordersListPage.contractName") }}:</strong>
            </div>
            <div class="col">{{ contract.name }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("orderViewPage.incoterm") }}:</strong>
            </div>
            <div class="col">{{ order.incoterm }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("orderViewPage.type") }}:</strong>
            </div>
            <div class="col">
              {{
                !!contract.last_mile_delivery_method &&
                !!contract.last_mile_delivery_method.type
                  ? contract.last_mile_delivery_method.type
                  : $t("orderViewPage.standard")
              }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("declaredValue") }}:</strong>
            </div>
            <div class="col">
              {{ order.currency == "USD" ? "$" : "R$"
              }}{{
                order.declared_value == null
                  ? "0.00"
                  : standardizeValue(order.declared_value)
              }}
              (Products {{ standardizeValue(order.products_value) }} + Freight
              {{ standardizeValue(order.freight_value) }})
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("taxValue") }}:</strong>
            </div>
            <div class="col">
              {{ order.currency == "USD" ? "$" : "R$"
              }}{{
                order.tax_value == null
                  ? "0.00"
                  : standardizeValue(order.tax_value)
              }}
              (Duties Tax {{ standardizeValue(order.duties_tax_value) }} + Sales
              Tax {{ standardizeValue(order.sales_tax_value) }})
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("orderViewPage.haveInsurance") }}:</strong>
            </div>
            <div class="col">
              {{ order.return_insurance ? this.$t("yes") : this.$t("no") }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("insuranceCost") }}:</strong>
            </div>
            <div class="col">
              {{ order.currency == "USD" ? "$" : "R$"
              }}{{
                order.insurance_cost == null
                  ? "0.00"
                  : standardizeValue(order.insurance_cost)
              }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("ordersListPage.insuranceCoverage") }}:</strong>
            </div>
            <div class="col">{{ order.insurance_coverage }}</div>
          </div>

          <div
            v-if="order.overpack_id"
            class="row border rounded bg-white p-2 mx-3 mb-2"
          >
            <div class="col">
              <strong>{{ $t("orderViewPage.overpackReference") }}:</strong>
            </div>
            <div class="col-8">
              <a :href="'/overpacks/' + order.overpack_id">{{
                overpack.reference
              }}</a>
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("orderViewPage.shipFrom") }}:</strong>
            </div>
            <div class="col-8">
              {{
                order.seller.address +
                ", " +
                order.seller.address_number +
                " - " +
                order.seller.city +
                " / " +
                order.seller.country
              }}
            </div>
          </div>
        </div>
        <!-- ./shipping -->

        <!-- customer -->
        <div class="card bg-light border-white rounded">
          <div class="pb-2 pt-4 px-3">
            <h5 class="pl-3">
              {{ $t("createGroupConfigurationPage.customer") }}
            </h5>
            <hr class="mb-0" />
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("name") }}:</strong>
            </div>
            <div class="col">{{ order.customer_full_name }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ order.customer_document_type + ":" }}</strong>
            </div>
            <div class="col">{{ order.customer_document_number }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("address") }}:</strong>
            </div>
            <div class="col-8">
              {{
                order.customer_address +
                (order.customer_address_number
                  ? " " + order.customer_address_number
                  : "") +
                (order.customer_address_complement
                  ? ", " + order.customer_address_complement
                  : "") +
                (order.customer_address_reference
                  ? " (" + order.customer_address_reference + ") "
                  : "") +
                " - " +
                order.customer_city
              }}
            </div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("postalCode") }}:</strong>
            </div>
            <div class="col">{{ order.customer_postal_code }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("state") }}:</strong>
            </div>
            <div class="col">{{ order.customer_state }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("country") }}:</strong>
            </div>
            <div class="col">{{ order.customer_country }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("email") }}:</strong>
            </div>
            <div class="col">{{ order.customer_email }}</div>
          </div>

          <div class="row border rounded bg-white p-2 mx-3 mb-2">
            <div class="col">
              <strong>{{ $t("phone") }}:</strong>
            </div>
            <div class="col">{{ order.customer_phone }}</div>
          </div>

          <div
            class="row border rounded bg-white p-2 mx-3 mb-2"
            v-if="order.is_dispatched"
          >
            <div class="col">
              <strong class="text-warning">Warning:</strong>
            </div>
            <div class="col text-warning">
              Can not be edited because is dispatched
            </div>
          </div>
        </div>
        <!-- ./customer -->
      </div>
      <div v-b-toggle="'collapse-order-costs'" class="mt-3 card rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">
            {{ $t("orderViewPage.fieldsOrderCosts.general_costs") }}:<i
              class="fas fa-plus ml-1"
            ></i>
          </h5>
        </div>
      </div>
      <b-collapse id="collapse-order-costs">
        <b-card>
          <NbTable
            class="my-2"
            :shortThead="true"
            striped
            :fields="generalCostFields"
            :items="generalCostsTable"
          >
            <template #cell(general_costs)="data">
              <button
                class="btn btn-link"
                data-toggle="modal"
                data-target="#modal-general-costs"
              >
                {{ data.item.general_costs }} <i class="fas fa-plus ml-1"></i>
              </button>
            </template>
          </NbTable>
        </b-card>
      </b-collapse>

      <div class="card mt-4">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">
            {{ $t("orderViewPage.totalVolumes") }}:
            <strong>{{ order.volumes.length }}</strong>
          </h5>
          <hr class="mb-0" />
        </div>
        <div id="collapseShowList">
          <div class="card-body pt-1">
            <div
              class="accordion mt-4"
              id="accordionShowVolumes"
              v-for="(volume, index) in order.volumes"
              :key="index"
            >
              <div class="card border-white">
                <a
                  class="accordion-toggle mb-0 collapsed"
                  type="button"
                  data-toggle="collapse"
                  :data-target="'#ShowVolume' + index"
                  aria-expanded="false"
                  :aria-controls="'ShowVolume' + index"
                  :id="'headingShowVolume' + index"
                >
                  <h5 class="mb-0 mr-5 d-flex justify-content-between">
                    <div>
                      Volume #{{ index + 1 }} (
                      {{
                        volume.order_items.reduce(
                          (tot, elt) => tot + elt.quantity,
                          0
                        )
                      }}
                      {{
                        volume.order_items.reduce(
                          (tot, elt) => tot + elt.quantity,
                          0
                        ) > 1
                          ? "items)"
                          : "item)"
                      }}
                    </div>
                    <div>
                      <b>{{ $t("lastMileTrackingNumber") }}:</b>
                      {{ volume.last_mile_tracking_number }}
                    </div>
                    <div>
                      <h6 class="float-right mb-0">
                        {{ $t("orderViewPage.currentStatus") }}:
                        <span
                          v-if="
                            volume?.current_status?.name === 'PENDING' ||
                            volume?.current_status?.name === 'FAILED_ATTEMPT' ||
                            volume?.current_status?.name ===
                              'EXCEPTION IN PICKUP'
                          "
                          class="badge badge-warning ml-1"
                          >{{ volume?.current_status?.name }}</span
                        >
                        <span
                          v-else-if="
                            volume?.current_status?.name ===
                              'PARTIAL DELIVERY FAILED' ||
                            volume?.current_status?.name === 'EXPIRED' ||
                            volume?.current_status?.name === 'EXCEPTION'
                          "
                          class="badge badge-danger ml-1"
                          >{{ volume?.current_status?.name }}</span
                        >
                        <span v-else class="badge badge-success ml-1">{{
                          volume?.current_status?.name
                        }}</span>
                        {{ volume?.current_status?.title }}
                      </h6>
                      <i class="fa fa-chevron-down pull-right"></i>
                    </div>
                  </h5>
                </a>
                <div
                  :id="'ShowVolume' + index"
                  class="collapse"
                  :aria-labelledby="'headingShowVolume' + index"
                  data-parent="#accordionShowVolumes"
                >
                  <div class="card-body pt-3">
                    <div v-if="volume.volume_reverse" class="mt-4 mb-4">
                      <h5 class="mt-4 mb-4">Volume Reverse</h5>
                      <table class="table table-borderless rounded">
                        <thead>
                          <tr>
                            <th class="text-center">Reverse Number</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Address</th>
                            <th class="text-center">Address Number</th>
                            <th class="text-center">Address Complement</th>
                            <th class="text-center">State</th>
                            <th class="text-center">Postal Code</th>
                            <th class="text-center">Country</th>
                            <th class="text-center">City</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center align-middle">
                              <a
                                :href="
                                  'volume_reverses/' + volume.volume_reverse.id
                                "
                              >
                                {{ volume.volume_reverse.reverse_number }}</a
                              >
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.name }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.address }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.address_number }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.address_complement }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.state }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.postal_code }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.country }}
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.volume_reverse.city }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr class="mt-2" />
                    </div>
                    <div class="d-flex align-items-start">
                      <span
                        >{{ $t("ordersListPage.firstMileTrackingNumber") }}:
                        {{ volume.first_mile_tracking_number }}</span
                      >
                    </div>
                    <div
                      class="d-flex justify-content-around align-items-center"
                    >
                      <h5>Package dimensions:</h5>
                      <div class="text-center">
                        <img
                          src="https://i.pinimg.com/originals/a0/86/47/a08647cec486718eaf66a38d6f6f8784.png"
                          width="40px"
                          height="100%"
                        />
                        <h6 class="mb-0 mt-2">{{ $t("length") }}</h6>
                        <h6>
                          {{ standardizeValue(volume.length) }}
                          {{ currentMeasurementMetric }}
                        </h6>
                        <h6
                          v-if="volume.last_volume_dimension"
                          class="font-weight-bold"
                          :class="
                            volume.length != volume.last_volume_dimension.length
                              ? 'volume-new-dimension'
                              : ''
                          "
                        >
                          {{
                            volume.length != volume.last_volume_dimension.length
                              ? standardizeValue(
                                  volume.last_volume_dimension.length
                                )
                              : "&#8205;"
                          }}
                          {{
                            volume.length != volume.last_volume_dimension.length
                              ? currentMeasurementMetric
                              : ""
                          }}
                          <NbHelpText
                            v-if="
                              volume.length !=
                              volume.last_volume_dimension.length
                            "
                            id="popover-length"
                            size="sm"
                            placement="topright"
                            >{{ $t("length") }} measured by carrier.</NbHelpText
                          >
                        </h6>
                      </div>
                      <div class="text-center">
                        <img
                          src="https://i.pinimg.com/originals/a0/86/47/a08647cec486718eaf66a38d6f6f8784.png"
                          width="40px"
                          height="100%"
                        />
                        <h6 class="mb-0 mt-2">{{ $t("height") }}</h6>
                        <h6>
                          {{ standardizeValue(volume.height) }}
                          {{ currentMeasurementMetric }}
                        </h6>
                        <h6
                          v-if="volume.last_volume_dimension"
                          class="font-weight-bold"
                          :class="
                            volume.height != volume.last_volume_dimension.height
                              ? 'volume-new-dimension'
                              : ''
                          "
                        >
                          {{
                            volume.height != volume.last_volume_dimension.height
                              ? standardizeValue(
                                  volume.last_volume_dimension.height
                                )
                              : "&#8205;"
                          }}
                          {{
                            volume.height != volume.last_volume_dimension.height
                              ? currentMeasurementMetric
                              : ""
                          }}
                          <NbHelpText
                            v-if="
                              volume.height !=
                              volume.last_volume_dimension.height
                            "
                            id="popover-height"
                            size="sm"
                            placement="topright"
                            >{{ $t("height") }} measured by carrier.</NbHelpText
                          >
                        </h6>
                      </div>
                      <div class="text-center">
                        <img
                          src="https://i.pinimg.com/originals/a0/86/47/a08647cec486718eaf66a38d6f6f8784.png"
                          width="40px"
                          height="100%"
                        />
                        <h6 class="mb-0 mt-2">{{ $t("width") }}</h6>
                        <h6>
                          {{ standardizeValue(volume.width) }}
                          {{ currentMeasurementMetric }}
                        </h6>
                        <h6
                          v-if="volume.last_volume_dimension"
                          class="font-weight-bold"
                          :class="
                            volume.width != volume.last_volume_dimension.width
                              ? 'volume-new-dimension'
                              : ''
                          "
                        >
                          {{
                            volume.width != volume.last_volume_dimension.width
                              ? standardizeValue(
                                  volume.last_volume_dimension.width
                                )
                              : "&#8205;"
                          }}
                          {{
                            volume.width != volume.last_volume_dimension.width
                              ? currentMeasurementMetric
                              : ""
                          }}
                          <NbHelpText
                            v-if="
                              volume.width != volume.last_volume_dimension.width
                            "
                            id="popover-width"
                            size="sm"
                            placement="topright"
                            >{{ $t("width") }} measured by carrier.</NbHelpText
                          >
                        </h6>
                      </div>

                      <div class="text-center">
                        <img
                          src="https://i.pinimg.com/originals/a0/86/47/a08647cec486718eaf66a38d6f6f8784.png"
                          width="40px"
                          height="100%"
                        />
                        <h6 class="mb-0 mt-2">{{ $t("weight") }}</h6>
                        <h6>
                          {{ standardizeValue(volume.weight) }}
                          {{ currentWeightMetric }}
                        </h6>
                        <h6
                          v-if="volume.last_volume_dimension"
                          class="font-weight-bold"
                          :class="
                            volume.weight != volume.last_volume_dimension.weight
                              ? 'volume-new-dimension'
                              : ''
                          "
                        >
                          {{
                            volume.weight != volume.last_volume_dimension.weight
                              ? standardizeValue(
                                  volume.last_volume_dimension.weight
                                )
                              : "&#8205;"
                          }}
                          {{
                            volume.weight != volume.last_volume_dimension.weight
                              ? currentWeightMetric
                              : ""
                          }}
                          <NbHelpText
                            v-if="
                              volume.weight !=
                              volume.last_volume_dimension.weight
                            "
                            id="popover-weight"
                            size="sm"
                            placement="topright"
                            >{{ $t("weight") }} measured by carrier.</NbHelpText
                          >
                        </h6>
                      </div>
                      <div class="row">
                        <div
                          v-if="contract.id == '2' || contract.id == '3'"
                          class="col p-2 mx-3 text-center"
                        >
                          <h6>
                            <strong>{{ $t("ordersListPage.invoice") }}</strong>
                          </h6>
                          <a
                            href="#"
                            v-on:click="
                              generateCommercialInvoice(order.id, volume.id)
                            "
                            title="Commercial Invoice"
                          >
                            <i class="fas fa-file-alt fa-lg text-dark"></i>
                          </a>
                        </div>
                        <div class="col p-2 mx-3 text-center">
                          <h6>
                            <strong>{{ $t("label") }}</strong>
                          </h6>
                          <a
                            href="#"
                            v-on:click="generateBoxLabel(volume.id)"
                            title="Order Label"
                          >
                            <i class="far fa-file-alt fa-lg text-dark"></i>
                          </a>
                        </div>
                        <div class="col p-2 mx-3 text-center">
                          <h6>
                            <strong>{{
                              $t("ordersListPage.firstMileLabel")
                            }}</strong>
                          </h6>
                          <a
                            href="#"
                            v-on:click="generateVolumeFirstMile(volume.id)"
                            title="First Mile Label"
                          >
                            <i class="far fa-file-alt fa-lg text-dark"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <hr class="mt-2" />
                    <div>
                      <div
                        v-if="volume.last_mile_carrier_number"
                        class="mt-4 mb-4"
                      >
                        <h5 class="mt-4 mb-4">
                          {{ $t("ordersListPage.lastMileCarrierNumber") }}:
                          {{ volume.last_mile_carrier_number }}
                        </h5>
                        <hr class="mt-2" />
                      </div>
                    </div>
                    <div>
                      <h5 class="mt-4 mb-4">{{ $t("items") }}</h5>
                      <table class="table table-borderless rounded">
                        <thead>
                          <tr>
                            <th class="text-center">Name</th>
                            <th class="text-center">SKU</th>
                            <th class="text-center">HS Code</th>
                            <th class="text-center">Unit Products</th>
                            <th class="text-center">Unit Freight</th>
                            <th class="text-center">Unit Tax</th>
                            <!-- <th class="text-center">Value</th> -->
                            <!-- <th class="text-center">Value With Tax</th> -->
                            <th class="text-center">Quantity</th>
                            <th class="text-center">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index_item) in volume.order_items"
                            :key="index_item"
                          >
                            <td class="text-center align-middle">
                              {{ item.name }}
                            </td>
                            <td class="text-center align-middle">
                              {{ item.sku }}
                            </td>
                            <td class="text-center align-middle">
                              {{ item.hs_code }}
                            </td>
                            <td class="text-center align-middle">
                              {{ order.currency == "USD" ? "$" : "R$"
                              }}{{ item.unit_products_value || "0.00" }}
                            </td>
                            <td class="text-center align-middle">
                              {{ order.currency == "USD" ? "$" : "R$"
                              }}{{ item.unit_freight_value || "0.00" }}
                            </td>
                            <td class="text-center align-middle">
                              {{ order.currency == "USD" ? "$" : "R$"
                              }}{{ item.unit_tax_value || "0.00" }}
                            </td>
                            <!-- <td class="text-center align-middle">{{ (order.currency == 'USD') ? '$' : 'R$' }}{{ standardizeValue(item.value) }}</td> -->
                            <!-- <td class="text-center align-middle">{{ (order.currency == 'USD') ? '$' : 'R$' }}{{ standardizeValue(item.value_with_tax === null ? 0 : item.value_with_tax) }}</td> -->
                            <td class="text-center align-middle">
                              {{ item.quantity }}
                            </td>
                            <td width="30%" class="text-center align-middle">
                              {{ item.description }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <h5 class="mt-4 mb-4">Logs</h5>
                      <table class="table table-borderless rounded">
                        <thead>
                          <tr>
                            <th class="text-center">Title</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              item, index_log
                            ) in volume.tracking_alert_logs"
                            :key="`log${index_log}`"
                          >
                            <td class="text-center align-middle">
                              <router-link
                                :to="`/tracking-alert-logs/${item.id}`"
                                class="text-secondary"
                              >
                                {{ item.title }}
                              </router-link>
                            </td>
                            <td class="text-center align-middle">
                              <router-link
                                :to="`/tracking-alert-logs/${item.id}`"
                                class="text-secondary"
                              >
                                {{ item.status.replace("_", " ") }}
                              </router-link>
                            </td>
                            <td class="text-center align-middle">
                              <router-link
                                :to="`/tracking-alert-logs/${item.id}`"
                                class="text-secondary"
                              >
                                {{ item.type.replace("_", " ") }}
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr class="mt-2" />
                    <div>
                      <h5 class="mt-4 mb-4">
                        {{ $t("orderViewPage.trackingHistory") }}
                      </h5>
                      <table class="table table-borderless rounded">
                        <thead>
                          <tr>
                            <th class="text-center">
                              {{ $t("orderViewPage.carrierOrShipper") }}
                            </th>
                            <th width="14.5%" class="text-center">
                              {{ $t("orderViewPage.trackingStatus") }}
                            </th>
                            <th width="14.5%" class="text-center">
                              {{ $t("trackingNumber") }}
                            </th>
                            <th width="14.5%" class="text-center">
                              {{ $t("description") }}
                            </th>
                            <th width="14.5%" class="text-center">
                              {{ $t("date") }}
                            </th>
                            <th width="14.5%" class="text-center">
                              {{ $t("orderViewPage.message") }}
                            </th>
                            <th width="14.5%" class="text-center">
                              {{ $t("orderViewPage.attachment") }}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(checkpoint, index2) in volumeCheckpoints[
                              index
                            ].checkpoints"
                            :key="index2"
                          >
                            <td class="text-center align-middle">
                              <div class="card border-0 m-1">
                                <img
                                  src="../../assets/img/nobordist-management.png"
                                  width="100%"
                                  class="rounded"
                                />
                              </div>
                            </td>
                            <td class="text-center align-middle">
                              <div v-if="checkpoint.checkpoint">
                                <span
                                  v-if="
                                    checkpoint.checkpoint.name === 'PENDING' ||
                                    checkpoint.checkpoint.name ===
                                      'FAILED_ATTEMPT' ||
                                    checkpoint.checkpoint.name ===
                                      'EXCEPTION IN PICKUP'
                                  "
                                  class="badge badge-warning ml-1"
                                  >{{ checkpoint.checkpoint.name }}</span
                                >
                                <span
                                  v-else-if="
                                    checkpoint.checkpoint.name ===
                                      'PARTIAL DELIVERY FAILED' ||
                                    checkpoint.checkpoint.name === 'EXPIRED' ||
                                    checkpoint.checkpoint.name === 'EXCEPTION'
                                  "
                                  class="badge badge-danger ml-1"
                                  >{{ checkpoint.checkpoint.name }}</span
                                >
                                <span v-else class="badge badge-success ml-1">{{
                                  checkpoint.checkpoint.name
                                }}</span>
                                <h6 class="m-0">
                                  {{ checkpoint.checkpoint.title }}
                                </h6>
                              </div>
                            </td>
                            <td class="text-center align-middle">
                              {{ volume.last_mile_tracking_number }}
                            </td>
                            <td class="text-center align-middle">
                              {{ checkpoint.checkpoint.description }}
                            </td>
                            <td class="text-center align-middle">
                              {{
                                checkpoint.date_iso
                                  ? checkpoint.date_iso.substring(0, 10) +
                                    " at " +
                                    checkpoint.date_iso.substring(11, 16)
                                  : ""
                              }}
                            </td>

                            <td class="text-center align-middle">
                              {{ checkpoint.message }}
                            </td>

                            <td class="text-secondary text-center align-middle">
                              <!--<button class="btn btn-success">Download</button>-->
                            </td>
                          </tr>
                          <tr
                            v-if="!editableCheckpointsHidden[index]"
                            style="background-color: #eef4f9"
                          >
                            <td class="text-center bg-green align-middle">
                              {{ $t("orderViewPage.statusToAdd") }}
                            </td>
                            <td class="text-center align-middle">
                              <span
                                v-if="
                                  statusToAdd[index].name === 'PENDING' ||
                                  statusToAdd[index].name ===
                                    'FAILED_ATTEMPT' ||
                                  statusToAdd[index].name ===
                                    'EXCEPTION IN PICKUP'
                                "
                                class="badge badge-warning ml-1"
                                >{{ statusToAdd[index].name }}</span
                              >
                              <span
                                v-else-if="
                                  statusToAdd[index].name ===
                                    'PARTIAL DELIVERY FAILED' ||
                                  statusToAdd[index].name === 'EXPIRED' ||
                                  statusToAdd[index].name === 'EXCEPTION'
                                "
                                class="badge badge-danger ml-1"
                                >{{ statusToAdd[index].name }}</span
                              >
                              <span v-else class="badge badge-success ml-1">{{
                                statusToAdd[index].name
                              }}</span>
                              <h6 class="m-0">
                                {{ statusToAdd[index].title }}
                              </h6>
                            </td>
                            <td class="text-center align-middle">
                              {{ statusToAdd[index].description }}
                            </td>
                            <td></td>
                            <td></td>
                            <td class="text-center align-middle">
                              <button
                                @click="updateStatus(index, volume)"
                                class="button-success ml-3"
                              >
                                {{ $t("orderViewPage.addStatus111") }}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <button
                        v-if="editableCheckpointsHidden[index]"
                        v-on:click="addStatus(index)"
                        class="button-secondary"
                      >
                        {{ $t("orderViewPage.addStatus222") }}
                      </button>

                      <div v-if="!editableCheckpointsHidden[index]">
                        <form class="form-inline mt-4">
                          <select
                            id="status"
                            class="form-control"
                            v-model="statusToAdd[index]"
                          >
                            <option value="">
                              {{ $t("orderStatus.selectOption") }}
                            </option>
                            <option
                              v-for="macro_status in macroStatusList"
                              :key="macro_status.id"
                              :value="macro_status"
                            >
                              {{ macro_status.name }}
                            </option>
                          </select>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 pb-4 mt-4">
        <a
          v-if="order.is_dispatched"
          v-on:click="errorEditDispatched"
          class="button-primary float-right mb-5 text-white"
        >
          Edit Order</a
        >
        <a
          v-else-if="order.overpack_id"
          v-on:click="errorEditOverpacked"
          class="button-primary float-right mb-5 text-white"
        >
          Edit Order</a
        >
        <a
          v-else
          href="#"
          @click="editOrder"
          class="button-primary float-right mb-5"
          >Edit Order</a
        >

        <button
          class="button-secondary float-right mr-3 mb-5"
          data-toggle="modal"
          data-target="#setNewOrderNumber"
          @click="newOrderNumber = order.order_number"
        >
          {{ $t("orderViewPage.cloneOrder") }}
        </button>

        <button
          class="button-danger float-right mr-3 mb-5"
          data-toggle="modal"
          data-target="#modalDeleteOrder"
        >
          {{ $t("orderViewPage.deleteOrder") }}
        </button>
      </div>
      <!-- modals -->
      <div class="modal fade" id="setNewOrderNumber">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalsetNewOrderNumberTitle">
                {{ $t("orderViewPage.orderNumberMustBeUnique") }}!
              </h5>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group mt-2 mb-4">
                <label for="new-ordernumber">
                  {{ $t("orderViewPage.newOrderNumber") }}</label
                >
                <input
                  type="text"
                  id="new-ordernumber"
                  class="form-control"
                  v-model="newOrderNumber"
                />
              </div>
              <button
                href="#"
                class="btn button-primary float-right ml-3"
                @click="cloneOrder"
                data-dismiss="modal"
                :disabled="newOrderNumber == order.order_number"
              >
                {{ $t("confirm") }}
              </button>
              <button
                class="button-light float-right ml-3"
                data-dismiss="modal"
              >
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modalDeleteOrder">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalDeleteOrderTitle">
                {{ $t("orderViewPage.deleteConfirm") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <a
                href="#"
                class="button-danger float-right ml-3"
                v-on:click="deleteOrder"
                data-dismiss="modal"
                >{{ $t("delete") }}</a
              >
              <a
                href="#"
                class="button-light float-right ml-3"
                data-dismiss="modal"
                >{{ $t("cancel") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-general-costs">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal-general-costs">
                {{ $t("orderViewPage.fieldsOrderCosts.general_costs") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <NbTable
                class="my-2"
                :shortThead="true"
                striped
                :fields="orderCostsFields"
                :items="order.order_costs"
              >
                <template v-slot:cell(value)="data">
                  <div>
                    {{ standardizeValue(data.item.value) }}
                  </div> </template
                >formateDate
                <template v-slot:cell(created_at)="data">
                  <div>
                    {{
                      formateDate(data.item.created_at, data.item.created_at)
                    }}
                  </div>
                </template>
              </NbTable>
            </div>
            <div class="modal-footer">
              <button class="button-light" data-dismiss="modal">
                {{ $t("close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-shipper-name">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal-shipper-name">
                {{ $t("ordersListPage.sellerInformations") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("ordersListPage.sellersName") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_name }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("ordersListPage.sellerRfbCode") }}:</strong>
                </div>
                <div class="col">{{ order.seller_rfb_code }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("ordersListPage.sellerLegalName") }}:</strong>
                </div>
                <div class="col">{{ order.seller_legal_name }}</div>
              </div>

              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("address") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_address }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("addressNumber") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_address_number }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("complement") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_address_complement }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("postalCode") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_zip_code }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("state") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_state }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("country") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_country }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("city") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_city }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("email") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_email }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("phone") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_phone }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("ordersListPage.sellerTaxNumber") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_tax_number }}</div>
              </div>
              <div class="row p-2">
                <div class="col">
                  <strong>{{ $t("site") }}:</strong>
                </div>
                <div class="col">{{ order.shipper_site }}</div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="button-light" data-dismiss="modal">
                {{ $t("close") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="reverse-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reverseLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteLabel">
                {{ $t("volumesReversePage.reverseVolume") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="checkReverses">
                <div
                  class="accordion"
                  id="accordionShowVolumesToReverse"
                  v-for="(volume, index) in order.volumes"
                  :key="index"
                >
                  <h6 v-if="index === 0">
                    {{ $t("orderViewPage.orderVolumes") }}
                  </h6>
                  <div
                    v-if="!volume.volume_reverse"
                    class="border border-primary rounded py-1 px-2 my-2"
                  >
                    {{ $t("orderViewPage.volume") }}#{{ index + 1 }}
                    <a
                      type="button"
                      v-on:click="createVolumeReverse(volume.id)"
                      title="Create Volume Reverse"
                      class="my-1 button-primary font-weight-bold float-right"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      {{ $t("orderViewPage.createVolumeReverse") }}
                    </a>
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item font-weight-bold">
                        {{
                          volume.order_items.reduce(
                            (tot, elt) => tot + elt.quantity,
                            0
                          )
                        }}
                        {{
                          volume.order_items.reduce(
                            (tot, elt) => tot + elt.quantity,
                            0
                          ) > 1
                            ? "items:"
                            : "item: "
                        }}
                      </li>
                      <li
                        v-for="(orderItem, index) in volume.order_items"
                        :key="index"
                        class="list-inline-item"
                      >
                        {{ orderItem.name }};
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else>
                {{ $t("orderViewPage.noVolumes") }}
              </div>
              <div
                class="accordion"
                id="accordionShowVolumesToReversed"
                v-for="(volume, index) in order.volumes"
                :key="index"
              >
                <hr v-if="index === 0" />
                <h6 v-if="index === 0 && volume.volume_reverse">
                  {{ $t("orderViewPage.volumesInReverse") }}
                </h6>
                <router-link
                  v-if="volume.volume_reverse"
                  :to="'volume_reverses/' + volume.volume_reverse.id"
                  class="my-1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {{ $t("orderViewPage.volume") }} #{{ index + 1 }} (
                  {{
                    volume.order_items.reduce(
                      (tot, elt) => tot + elt.quantity,
                      0
                    )
                  }}
                  {{
                    volume.order_items.reduce(
                      (tot, elt) => tot + elt.quantity,
                      0
                    ) > 1
                      ? "items)"
                      : "item)"
                  }}
                </router-link>
                <div v-else-if="index === 0">
                  {{ $t("orderViewPage.noReversedVolumes") }}
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button class="button-light" data-dismiss="modal">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end modals -->
    </div>
  </div>
</template>

<script>
import BackButton from "../../components/generic/BackButton";
import OrderService from "../../services/OrderService";
import CheckpointService from "../../services/CheckpointService";
import OrderLabelService from "../../services/OrderLabelService";
import NProgress from "nprogress";
import moment from "moment";
import PackingSlipService from "../../services/PackingSlipService";
import UsersService from "../../services/UsersService";
import { HS_CODES } from "../../constants";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import VolumeReverseService from "../../services/VolumeReverseService";
import NbTable from "@/components/tables/NbTable.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

const orderService = new OrderService();
const checkpointService = new CheckpointService();
const orderLabelService = new OrderLabelService();
const packingSlipService = new PackingSlipService();
const usersService = new UsersService();
const volumeReverseService = new VolumeReverseService();

export default {
  name: "OrdersView",

  components: { BackButton, LoadingPage, NbTable, NbHelpText },
  data: () => {
    return {
      loaded: true,
      measurementSystem: "metric",
      order: {
        order_number: "",
        sales_channel_id: "",
        sales_channel_order_number: "",
        estimated_delivery_date: "",
        customer_full_name: "",
        customer_document_type: "",
        customer_document_number: "",
        customer_address: "",
        customer_address_number: "",
        customer_address_complement: "",
        customer_address_reference: "",
        customer_postal_code: "",
        customer_address_quarter: "",
        customer_city: "",
        customer_state: "",
        customer_country: "BR",
        customer_phone: "",
        customer_phone_country_code: "+55",
        customer_email: "",
        customer_mailbox: "",
        incoterm: "",
        total_value: "",
        observations: "",
        freight_value: "",
        freight_cost: "",
        handling_time: "",
        /* tax_cost: "", */
        insurance_cost: "",
        currency: "USD",
        quantity: "",
        has_insurance: false,
        volumes: [
          {
            id: "",
            height: "",
            width: "",
            length: "",
            weight: null,
            cubage_factor: "",
            volume_type: "",
            sales_tax_value: null,
            duties_tax_value: null,
            order_items: [
              {
                name: "",
                description: "",
                weight: null,
                value: "",
                hs_code: "",
                sku: "",
                quantity: "",
              },
            ],
          },
        ],
        contract_id: 1,
        last_mile_number: "",
        current_status: {
          name: "",
        },
        seller: {},
        sales_channel_name: "",
      },
      generalCostsTable: [],
      newOrderNumber: "",
      contract: {
        name: "",
        is_auto_tax_defined: null,
        last_mile_delivery_method: {
          type: null,
        },
      },
      fields: [{ key: "volume_reverse" }],
      overpack: {
        reference: "",
      },
      statusToAdd: [{}],
      editableCheckpointsHidden: [],
      macroStatusList: [],
      volumeCheckpoints: [
        {
          id: null,
          checkpoints: [
            {
              id: null,
              message: null,
              attachment: null,
              date_iso: null,
              created_at: null,
              checkpoint: {
                id: null,
                type: null,
                slug: null,
                name: null,
                description: null,
                created_at: null,
                tracking_code: null,
                title: null,
              },
            },
          ],
        },
      ],

      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      has_insurance: false,
    };
  },
  created() {
    /**
     * Get order
     */
    usersService.getUserMeasurementSystem().then(({ data }) => {
      this.measurementSystem = data.data;
    });

    this.loadOrder();

    /**
     * Get order contract
     */
    orderService.getContract(this.$route.params.id).then((response) => {
      this.contract = response.data.data;
    });

    /**
     * Get order overpack
     */
    orderService.getOverpack(this.$route.params.id).then((response) => {
      this.overpack = response.data.data;
    });

    /**
     * Get order Sales Channel
     */
    orderService.getSalesChannel(this.$route.params.id).then((response) => {
      this.sales_channel = response.data.data;
    });

    /**
     * Get checkpoints list
     */
    checkpointService.getMacros().then((response) => {
      this.macroStatusList = response.data.data;
      this.macroStatusList = this.macroStatusList.filter((elt) => {
        return !(
          elt.name == "PENDING" ||
          elt.name == "NEW" ||
          elt.name == "PARTIAL DELIVERY" ||
          elt.name == "PARTIAL DELIVERY FAILED"
        );
      });
    });
  },
  computed: {
    generalCostFields() {
      return [
        {
          key: "freight_cost",
          label: this.$t("orderViewPage.fieldsOrderCosts.freight_cost"),
        },
        {
          key: "duties",
          label: this.$t("orderViewPage.fieldsOrderCosts.duties"),
        },
        {
          key: "insurance",
          label: this.$t("orderViewPage.fieldsOrderCosts.insurance"),
        },
        {
          key: "general_costs",
          label: this.$t("orderViewPage.fieldsOrderCosts.general_costs"),
        },
        {
          key: "fines",
          label: this.$t("orderViewPage.fieldsOrderCosts.fines"),
        },
      ];
    },
    orderCostsFields() {
      return [
        { key: "name", label: "Type" },
        { key: "value", label: "Value" },
        { key: "created_at", label: "Creation Date" },
      ];
    },
    currentWeightMetric() {
      if (this.measurementSystem === "metric") {
        return "kg";
      }
      return "lb";
    },
    currentMeasurementMetric() {
      if (this.measurementSystem === "metric") {
        return "cm";
      }
      return "in";
    },
    checkReverses() {
      let finalResult = false;

      this.order.volumes.forEach((volume) => {
        if (!volume.hasOwnProperty("volume_reverse")) {
          finalResult = true;
        }
      });
      return finalResult;
    },
    isDispatchable() {
      return !this.is_dispatched;
    },
    isReversable() {
      if (this.contract.reverse_delivery_method_id) {
        return false;
      }
      return true;
    },
    computedDuties() {
      //'Duties e a soma da diferenca de item.value_with_tax e item.value'
      let taxed_val = 0;
      let item_val = 0;

      this.order.volumes.forEach((volume) => {
        volume.order_items.forEach((item) => {
          taxed_val += parseFloat(item.value_with_tax);
          item_val += parseFloat(item.value);
        });
      });
      return `${(taxed_val - item_val).toFixed(2)}`;
    },
  },
  methods: {
    createVolumeReverse(volumeId) {
      const dataToSend = { volume_id: volumeId };
      volumeReverseService
        .setVolumeReverse(dataToSend)
        .then((response) => {
          this.$router
            .push(`/orders/volume_reverses/${response.data.data.id}`)
            .then(() => {
              this.$helpers.toast(
                "Volume Reverse has been created.",
                "success"
              );
            });
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger", 3000);
          });
        });
    },
    contractDispatchable() {
      const hasLion = this.contract.slug.toLowerCase().includes("lion");
      const isSmartDdu = this.contract.slug.toLowerCase().includes("smart_ddu"); //correios
      if (hasLion || isSmartDdu) {
        return true;
      }
      return false;
    },
    loadOrder() {
      orderService
        .getOrder(this.$route.params.id)
        .then((response) => {
          const order = response.data.data;
          this.order = order;
          this.loadGeneralCostsTable();

          this.has_insurance = order.has_insurance || false;
          this.order.estimated_delivery_date = moment(
            order.estimated_delivery_date
          ).format("YYYY-MM-DD");
          //this.order.return_insurance = order.return_insurance ? "YES" : "NO";
          /**
        /* Get volume checkpoints
        */
          this.volumeCheckpoints = [];
          this.editableCheckpointsHidden = [];
          this.statusToAdd = [];
          for (let i = 0, l = this.order.volumes.length; i < l; i++) {
            this.volumeCheckpoints.push({
              id: this.order.volumes[i].id,
              checkpoints: [],
            });
            this.editableCheckpointsHidden.push(true);
            this.statusToAdd.push({});
          }

          checkpointService
            .getVolumeCheckpoints(this.$route.params.id)
            .then((response) => {
              response.data.data.forEach((elt) => {
                for (let i = 0, l = this.volumeCheckpoints.length; i < l; i++) {
                  if (this.volumeCheckpoints[i].id === elt.id) {
                    this.volumeCheckpoints[i].checkpoints = elt.checkpoints;
                  }
                }
              });
              this.loaded = false;
            });
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push("/");
          }

          this.$helpers.toast(error.message, "danger");

          NProgress.done();
        });
    },
    loadGeneralCostsTable() {
      let tableData = [];

      tableData = [
        {
          freight_cost: `$${this.standardizeValue(this.order.freight_cost)}`,
          duties: `$${this.computedDuties}`,
          insurance: "$0.00",
          general_costs: `${
            this.order.currency == "USD" ? "$" : "R$"
          } ${this.sumValues(this.order.order_costs)}`,
          fines: "$0.00",
          other_costs: "$0.00",
        },
      ];

      this.generalCostsTable = tableData;
    },
    generateCommercialInvoice(orderId, volumeId = {}) {
      orderLabelService
        .generateCommercialInvoiceLabel(orderId, volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    },
    generateFirstMile(orderId) {
      orderLabelService
        .generateFirstMile(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generatePackingSlipLabel(orderId) {
      packingSlipService
        .generate(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    },

    generateShipperConfirmation(orderId) {
      orderLabelService
        .generateShipperConfirmation(orderId)
        .then((response) => {
          window.open(response.data.data);
        });
    },

    generateBoxLabel(volumeId) {
      orderLabelService
        .generateVolumeLabel(volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generateVolumeFirstMile(volumeId) {
      orderLabelService
        .generateVolumeFirstMile(volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },

    getCategory(hscode) {
      let finalCategory = "Others";
      for (var i in HS_CODES) {
        if (HS_CODES[i] == hscode) {
          finalCategory = i;
        }
      }
      return finalCategory;
    },

    editOrder() {
      let order = {
        courier: {
          contract_id: null,
          selectedQuote: null,
          customer_full_name: "",
          freight_value: 0,
          freight_cost: 0,
          insurance_cost: 0,
          ddp_cost: 0,
          handling_time: 0,
          dhl_type: false,
          dhl_time: null,
        },
        receiver: {
          customer_full_name: this.order.customer_full_name,
          customer_email: this.order.customer_email,
          customer_phone: this.order.customer_phone,
          customer_phone_country_code: this.order.customer_phone_country_code,
          customer_country: this.order.customer_country,
          customer_postal_code: this.order.customer_postal_code,
          customer_city: this.order.customer_city,
          customer_state: this.order.customer_state,
          customer_address: this.order.customer_address,
          customer_address_number: this.order.customer_address_number,
          customer_address_complement: this.order.customer_address_complement,
          customer_address_reference: this.order.customer_address_reference,
          customer_company_name: this.order.customer_company_name,
          customer_document_number: this.order.customer_document_number,
          customer_document_type: this.order.customer_document_type,
          is_commercial_destination: this.order.is_commercial_destination,
          seller_name: this.order.seller_name,
          seller_address: this.order.seller_address,
          seller_address_number: this.order.seller_address_number,
          seller_address_complement: this.order.seller_address_complement,
          seller_city: this.order.seller_city,
          seller_state: this.order.seller_state,
          seller_country: this.order.seller_country,
          seller_email: this.order.seller_email,
          seller_zip_code: this.order.seller_zip_code,
          seller_tax_number: this.order.seller_tax_number,
          seller_website: this.order.seller_website,
          seller_phone: this.order.seller_phone,
          seller_phone_code: this.order.seller_phone_code,
          seller_phone_number: this.order.seller_phone_number,
          seller_rfb_code: this.order.seller_rfb_code,
          seller_legal_name: this.order.seller_legal_name,
        },
        shipment: {
          sales_channel_id: this.order.sales_channel_id,
          sales_channel_order_number: this.order.sales_channel_order_number,
          order_number: this.order.order_number,
          incoterm: this.order.incoterm,
          return_insurance: this.order.return_insurance,
          volumes_attributes: this.order.volumes,
          quantity: this.order.quantity,
          currency: this.order.currency,
          is_landed_cost: this.order.is_landed_cost,
        },
      };

      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].order_items_attributes =
          element.order_items;
        order.shipment.volumes_attributes[
          indexOne
        ].volume_type = `${Math.random().toString(16).slice(2)}`; //add box hash

        this.order.volumes[indexOne].order_items.forEach(
          (element, indexTwo) => {
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].box = order.shipment.volumes_attributes[indexOne].volume_type;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].unit_price = element.value;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].category = this.getCategory(element.hs_code);
          }
        );
      });

      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].collected_icms =
          element.collected_icms;
        order.shipment.volumes_attributes[indexOne].collected_ii =
          element.collected_ii;
      });

      this.$helpers.saveUnsavedWork("order-create", order);
      this.$router.push({
        name: "create_order",
        params: {
          isFrom: "isEdit",
          editId: this.order.id,
        },
      });
    },
    cloneOrder() {
      let order = {
        courier: {
          contract_id: this.order.contract_id,
          freight_value: this.order.freight_value,
          freight_cost: this.order.freight_cost,
          handling_time: this.order.handling_time,
        },
        receiver: {
          customer_full_name: this.order.customer_full_name,
          customer_email: this.order.customer_email,
          customer_phone: this.order.customer_phone,
          customer_phone_country_code: this.order.customer_phone_country_code,
          customer_country: this.order.customer_country,
          customer_postal_code: this.order.customer_postal_code,
          customer_city: this.order.customer_city,
          customer_state: this.order.customer_state,
          customer_address: this.order.customer_address,
          customer_address_number: this.order.customer_address_number,
          customer_address_complement: this.order.customer_address_complement,
          customer_address_reference: this.order.customer_address_reference,
          customer_company_name: this.order.customer_company_name,
          customer_document_number: this.order.customer_document_number,
          customer_document_type: this.order.customer_document_type,
          is_commercial_destination: this.order.is_commercial_destination,
          seller_name: this.order.seller_name,
          seller_address: this.order.seller_address,
          seller_address_number: this.order.seller_address_number,
          seller_address_complement: this.order.seller_address_complement,
          seller_city: this.order.seller_city,
          seller_state: this.order.seller_state,
          seller_country: this.order.seller_country,
          seller_email: this.order.seller_email,
          seller_zip_code: this.order.seller_zip_code,
          seller_tax_number: this.order.seller_tax_number,
          seller_website: this.order.seller_website,
          seller_phone: this.order.seller_phone,
          seller_phone_code: this.order.seller_phone_code,
          seller_phone_number: this.order.seller_phone_number,
          seller_rfb_code: this.order.seller_rfb_code,
          seller_legal_name: this.order.seller_legal_name,
        },
        shipment: {
          sales_channel_id: this.order.sales_channel_id,
          sales_channel_order_number: this.order.sales_channel_order_number,
          order_number: this.newOrderNumber,
          incoterm: this.order.incoterm,
          return_insurance: this.order.return_insurance,
          volumes_attributes: this.order.volumes,
          quantity: this.order.quantity,
          currency: this.order.currency,
          is_landed_cost: this.order.is_landed_cost,
        },
      };
      /* CASO ALTERE A VARIAVEL PARA O JEITO QUE ESTAVA ANTES.
      if (order.shipment.return_insurance == "NO") {
        order.shipment.return_insurance = false
      } else {
        order.shipment.return_insurance = true
      }
      */
      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].order_items_attributes =
          element.order_items;
        order.shipment.volumes_attributes[
          indexOne
        ].volume_type = `${Math.random().toString(16).slice(2)}`; //add box hash

        this.order.volumes[indexOne].order_items.forEach(
          (element, indexTwo) => {
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].box = order.shipment.volumes_attributes[indexOne].volume_type;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].unit_price = element.value;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].category = this.getCategory(element.hs_code);
          }
        );
      });

      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].collected_icms =
          element.collected_icms;
        order.shipment.volumes_attributes[indexOne].collected_ii =
          element.collected_ii;
      });

      this.$helpers.saveUnsavedWork("order-create", order);
      this.$router.push({
        name: "create_order",
        params: {
          isFrom: "isClone",
        },
      });
    },

    deleteOrder() {
      orderService
        .deleteOrder(this.order.id)
        .then(() => {
          this.$router.push("/orders").then(() => {
            this.$helpers.toast("Order deleted", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },

    dispatchOrder() {
      orderService.dispatchOrder(this.order.id).then(() => {
        this.loadOrder();
        this.$helpers.toast("Order dispatched", "success", 5000);
      });
    },

    errorEditOverpacked() {
      this.$helpers.toast(
        "The order cannot be edited as it is already overpacked.",
        "danger"
      );
    },

    errorEditDispatched() {
      this.$helpers.toast("Can not be edited because is dispatched", "danger");
    },

    addStatus(index) {
      this.editableCheckpointsHidden[index] = false;
      this.$forceUpdate();
    },

    updateStatus(index, volume) {
      if (this.statusToAdd[index].tracking_code) {
        const params = {
          checkpoint_code: parseInt(this.statusToAdd[index].tracking_code),
        };
        checkpointService
          .addCheckpointToVolume(volume.id, params)
          .then((response) => {
            this.$helpers.toast("Status updated", "success");
            this.volumeCheckpoints[index].checkpoints.push(response.data.data);
            this.order.volumes[index].current_status = this.statusToAdd[index];
            this.statusToAdd[index] = {};
            this.editableCheckpointsHidden[index] = true;
          })
          .catch((error) => {
            if (
              error.hasOwnProperty("response") &&
              error.response.hasOwnProperty("data") &&
              error.response.data.hasOwnProperty("messages")
            ) {
              this.validationErrors = error.response.data.messages[0];
            }

            let errors = this.$helpers.extractErrorMessages(error);

            errors.forEach((message) => {
              this.$helpers.toast(message, "danger");
            });
          });
      }
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    openTrakingPage(trackingNumber) {
      const routeData = this.$router.resolve({
        name: "track_my_order",
        query: { trackingNumber },
      });

      window.open(routeData.href, "_blank");
    },
    sumValues(arrayObjs) {
      if (arrayObjs) {
        let sumTotal = 0;
        arrayObjs.forEach((arrayObj) => {
          sumTotal += Number(arrayObj.value);
        });
        return sumTotal.toFixed(2);
      }
      return "0.00";
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear()
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        //check the last trackingBaseUrl is a "/" and remove it to avoid router bugs like (...)/trackings/tracking making it like (...)/trackings?trackingNumber=tracking_number
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(this.trackingBaseUrl + "?trackingNumber=" + traking_number);
        return;
      }
      location.replace(this.trackingBaseUrl + "404");
    },
    getFirstMileTrackingNumbers(volumes) {
      const trackingNumbers = volumes.map(
        (volume) => volume.first_mile_tracking_number
      );
      return trackingNumbers.join(", ");
    },
  },
};
</script>

<style scoped>
.text-left tbody > tr > td {
  text-align: left !important;
}

.volume-new-dimension {
  border-top: 1px solid var(--gray);
  padding-top: 4px;
}
</style>
