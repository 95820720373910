<template>
  <base-template current-page="OrdersIndex">
    <router-view></router-view>
  </base-template>
</template>

<script>
import BaseTemplate from "../BaseTemplate";

export default {
  name: "OrdersIndex",
  components: { BaseTemplate },
};
</script>
