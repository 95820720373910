<template>
  <NbModal id="downloadVolumes">
    <template #header>
      <div class="d-flex flex-column">
        <h4 class="heading-4" id="settingsTitle">
          {{ $t("components.selectTableColumns.title") }}
        </h4>

        <div class="d-flex justify-content-end">
          <NbButton variant="quaternary" @click="clearSelected()">{{
            $t("clearSelected")
          }}</NbButton>
          <NbButton variant="quaternary" @click="selectAll()" class="ml-2">{{
            $t("selectAll")
          }}</NbButton>
        </div>
      </div>
    </template>

    <template #body>
      <div>
        <NbCheckbox
          v-for="volume in volumeFields"
          :key="volume.key"
          v-model="volume.selected"
          :name="volume.label"
          :id="volume.key"
        />
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" @click="closeModal()">{{
          $t("cancel")
        }}</NbButton>
        <NbButton
          @click="downloadVolumes()"
          class="ml-2"
          :disabled="loading || !selectedColumns.length"
          >{{ $t("ordersListPage.downloadVolumes") }}</NbButton
        >
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import DownloadService from "../../../services/DownloadsService";

const downloadsService = new DownloadService();

export default {
  name: "ModalDownloadVolumes",
  components: {
    NbModal,
    NbButton,
    NbCheckbox,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    orderIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      volumeFields: [
        { key: "id", label: "ID", selected: false },
        { key: "weight", label: "Weight", selected: false },
        { key: "height", label: "Height", selected: false },
        { key: "width", label: "Width", selected: false },
        { key: "length", label: "Length", selected: false },
        { key: "reference", label: "Reference", selected: false },
        {
          key: "estimated_freight_cost",
          label: "Estimated Freight Cost",
          selected: false,
        },
        { key: "collected_icms", label: "Collected ICMS", selected: false },
        { key: "collected_ii", label: "Collected II", selected: false },
        {
          key: "last_mile_tracking_number",
          label: "Last Mile Tracking Number",
          selected: false,
        },
        { key: "created_at", label: "Creation Date", selected: false },
        // order items data
        { key: "hs_code", label: "HS Code", selected: false },
        { key: "description", label: "Description", selected: false },
        { key: "current_status", label: "Current Status", selected: false },
        // order data
        { key: "order.id", label: "Order ID", selected: false },
        { key: "order.seller_id", label: "Seller ID", selected: false },
        { key: "order.contract_id", label: "Contract ID", selected: false },
        {
          key: "order.customer_full_name",
          label: "Customer Name",
          selected: false,
        },
        {
          key: "order.customer_postal_code",
          label: "C. Postal Code",
          selected: false,
        },
        { key: "order.customer_city", label: "Customer City", selected: false },
        {
          key: "order.customer_state",
          label: "Customer State",
          selected: false,
        },
        {
          key: "order.customer_address",
          label: "Customer Address",
          selected: false,
        },
        {
          key: "order.customer_address_number",
          label: "Customer Address Number",
          selected: false,
        },
        {
          key: "order.customer_address_complement",
          label: "Customer Address Complement",
          selected: false,
        },
        {
          key: "order.customer_address_reference",
          label: "Customer Address Reference",
          selected: false,
        },
        {
          key: "order.customer_address_quarter",
          label: "Customer Address Quarter",
          selected: false,
        },
        {
          key: "order.customer_document_type",
          label: "Customer Document Type",
          selected: false,
        },
        {
          key: "order.customer_document_number",
          label: "Customer Document Number",
          selected: false,
        },
        {
          key: "order.customer_country",
          label: "Customer Country",
          selected: false,
        },
        {
          key: "order.customer_email",
          label: "Customer Email",
          selected: false,
        },
        {
          key: "order.customer_phone",
          label: "Customer Phone",
          selected: false,
        },
        { key: "order.currency", label: "Currency", selected: false },
        { key: "order.freight_cost", label: "Freight Cost", selected: false },
        { key: "order.freight_value", label: "Freigt Value", selected: false },
        {
          key: "order.estimated_delivery_date",
          label: "Estimated Delivery Date",
          selected: false,
        },
        { key: "order.incoterm", label: "Incoterm", selected: false },
        { key: "order.order_number", label: "Order Number", selected: false },
        {
          key: "order.sales_channel_name",
          label: "Sales Channel",
          selected: false,
        },
        {
          key: "order.sales_channel_order_number",
          label: "Sales Ch. Order Number",
          selected: false,
        },
        { key: "order.total_value", label: "Total Value", selected: false },
        { key: "order.reference", label: "Order Reference", selected: false },
      ],
    };
  },
  watch: {
    show(oldValue, newValue) {
      if (oldValue === newValue) return;

      if (newValue) {
        this.clearSelected();
        this.$helpers.openModal("downloadVolumes");
        return;
      }

      this.closeModal();
    },
  },
  computed: {
    selectedColumns() {
      return this.volumeFields
        .filter((f) => f.selected === true)
        .map((f) => f.key);
    },
  },
  methods: {
    clearSelected() {
      this.volumeFields = this.volumeFields.map((item) => ({
        ...item,
        selected: false,
      }));
    },
    selectAll() {
      this.volumeFields = this.volumeFields.map((item) => ({
        ...item,
        selected: true,
      }));
    },
    closeModal() {
      this.$helpers.closeModal("downloadVolumes");
      this.$emit("update:show", false);
    },
    async downloadVolumes() {
      try {
        this.loading = true;
        const dataQuery = {
          data_type: "volumes",
          orders_ids: this.orderIds,
          columns: this.selectedColumns,
        };
        await downloadsService.loadProcessing(dataQuery);
        this.$helpers.toast(
          "Download file created, you can go to download page to check",
          "success"
        );
        this.closeModal();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
