<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded" class="page">
      <div class="heading d-md-flex justify-content-md-between mb-4">
        <div class="heading-left">
          <h4 class="heading-4">{{ $t("ordersRejectedPage.title") }}</h4>
        </div>
        <div class="heading-right">
          <h4 class="heading-4 mb-3">{{ $t("ordersListPage.newOrder") }}</h4>
          <div class="d-flex flex-column">
            <NbButton
              variant="secondary"
              size="sm"
              icon="fas fa-plus"
              class="mb-2 text-left"
              @click="$router.push({ name: 'create_order' })"
            >
              {{ $t("ordersListPage.createOrder") }}
            </NbButton>
            <NbButton
              variant="secondary"
              size="sm"
              icon="fas fa-arrow-down"
              class="mb-2 text-left"
              @click="$router.push({ name: 'orders_import' })"
            >
              {{ $t("orderImport") }}
            </NbButton>
            <NbButton
              variant="secondary"
              size="sm"
              icon="fas fa-dollar"
              class="text-left"
              @click="$router.push({ name: 'quotes_new' })"
            >
              {{ $t("ordersListPage.quotation") }}
            </NbButton>
          </div>
        </div>
      </div>

      <NbTabs class="mb-3 mt-5" :tabs="tabsOptions" @switchTab="switchTab" />

      <NbTablev2
        tableOf="rejectedTable"
        namespace="orders/import/errors"
        ref="rejectedTable"
        :allFields="allFields"
        :fields="fields"
        :selectedItems.sync="selectedOrders"
        :selectable="true"
        :buttonOptions="buttonOptions"
        :optionsWidth="150"
        @optionTook="optionTook($event)"
        @reloadFields="fields = $event"
        @total="totalItems = $event"
      >
        <template #cell(order_number)="data">
          <div class="link-1" @click="editRejectOrder(data.item)">
            {{ data.item.content.order_number }}
          </div>
        </template>
        <template #cell(created_at)="data">
          {{ formatDate(data.item.created_at) }}
        </template>
        <template #cell(status)="data">
          <NbBadge
            :id="data.item.id"
            class="fit-content"
            type="danger"
            :text="$t('invalid')"
          />
        </template>
        <template #cell(content)="data">
          <div
            class="link-1"
            data-toggle="modal"
            data-target="#codeView"
            @click="
              currentCodeView = data.item.content;
              codeViewTitle = $t('ordersRejectedPage.content');
            "
          >
            {{ truncateString(data.item.content, 65) }}
          </div>
        </template>
        <template #cell(error_info)="data">
          <div
            class="link-1"
            data-toggle="modal"
            data-target="#codeView"
            @click="
              currentCodeView = data.item.error_info;
              codeViewTitle = $t('ordersRejectedPage.error');
            "
          >
            {{ truncateString(data.item.error_info, 65) }}
          </div>
        </template>
      </NbTablev2>

      <NbFooter
        class="mt-1"
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />

      <NbModal id="codeView" modalConfig="modal-dialog-centered" width="850px">
        <template v-slot:header>
          <div class="heading-4">
            {{ codeViewTitle }}
          </div>
        </template>
        <template v-slot:body>
          <pre>
            <code>
{{ currentCodeView }} 
            </code>
          </pre>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-content-between">
            <NbButton
              variant="secondary"
              data-dismiss="modal"
              aria-label="Close"
            >
              {{ $t("close") }}
            </NbButton>
            <div></div>
          </div>
        </template>
      </NbModal>
    </div>
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

import LogService from "../../services/LogService";
import { HS_CODES } from "../../constants";
import moment from "moment";

const logService = new LogService();

export default {
  name: "OrdersRejected",
  components: {
    LoadingPage,
    NbButton,
    NbTabs,
    NbTablev2,
    NbBadge,
    NbModal,
    NbFooter,
  },
  data: () => {
    return {
      loaded: true,
      selectedOrders: [],
      currentTable: "all",
      totalItems: -1,
      fields: [],
      currentCodeView: {},
      codeViewTitle: "Code View",
    };
  },
  beforeMount() {
    this.fields = this.allFields;
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
  },
  methods: {
    optionTook(event) {
      this[event]();
    },
    switchTab(tab) {
      if (tab !== this.currentTable) {
        this.currentTable = tab;

        //this.filterTable();
      }
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    reloadData() {
      this.$refs?.rejectedTable?.getData();
    },
    remove() {
      logService.removeOrder(this.selectedOrders);
      setTimeout(() => {
        this.reloadData();
        this.selectedOrders = [];
      }, 1300);
    },
    editRejectOrder(rejectedOrder) {
      const rejectedContent = rejectedOrder.content;

      let order = {
        courier: {
          contract_id: "",
          selectedQuote: null,
        },
        receiver: {
          customer_address: rejectedContent.customer_address ?? "",
          customer_address_complement:
            rejectedContent.customer_address_complement ?? "",
          customer_address_number:
            rejectedContent.customer_address_number ?? "",
          customer_address_quarter:
            rejectedContent.customer_address_quarter ?? "",
          customer_address_reference:
            rejectedContent.customer_address_reference ?? "",
          customer_city: rejectedContent.customer_city ?? "",
          customer_country: rejectedContent.customer_country
            ? rejectedContent.customer_country.toUpperCase()
            : "BR",
          customer_created_at: rejectedContent.customer_created_at ?? "",
          customer_description: rejectedContent.customer_description ?? "",
          customer_document_number:
            rejectedContent.customer_document_number ?? "",
          customer_document_type: rejectedContent.customer_document_type
            ? rejectedContent.customer_document_type.toUpperCase()
            : "CPF",
          customer_email: rejectedContent.customer_email ?? "",
          customer_full_name: rejectedContent.customer_full_name ?? "",
          customer_id: rejectedContent.customer_id ?? "",
          customer_phone: rejectedContent.customer_phone ?? "",
          customer_platform_id: rejectedContent.customer_platform_id ?? "",
          customer_postal_code: rejectedContent.customer_postal_code ?? "",
          customer_seller_id: rejectedContent.customer_seller_id ?? "",
          customer_state: rejectedContent.customer_state ?? "",
          customer_updated_at: rejectedContent.customer_updated_at ?? "",
          is_commercial_destination:
            rejectedContent.is_commercial_destinatio == "true",
          remetente_is_cpf: rejectedContent.is_commercial_destinatio == "true",
          seller_address: rejectedContent.seller_address ?? "",
          seller_address_complement:
            rejectedContent.seller_address_complement ?? "",
          seller_address_number: rejectedContent.seller_address_number ?? "",
          seller_city: rejectedContent.seller_city ?? "",
          seller_country: rejectedContent.seller_country ?? "BR",
          seller_email: rejectedContent.seller_email ?? "",
          seller_name: rejectedContent.seller_name ?? "",
          seller_phone: rejectedContent.seller_phone ?? "",
          seller_phone_code: rejectedContent.seller_phone_code ?? "+55",
          seller_phone_number: rejectedContent.seller_phone_number ?? "",
          seller_state: rejectedContent.seller_state ?? "",
          seller_tax_number: rejectedContent.seller_tax_number ?? "",
          seller_website: rejectedContent.seller_website ?? "",
          seller_zip_code: rejectedContent.seller_zip_code ?? "",
        },
        shipment: {
          sales_channel_id: rejectedContent.sales_channel_id,
          sales_channel_order_number:
            rejectedContent.sales_channel_order_number,
          order_number: rejectedContent.order_number,
          incoterm: rejectedContent.incoterm,
          return_insurance: rejectedContent.return_insurance == "true",
          volumes_attributes: rejectedContent.volumes_attributes,
          quantity: rejectedContent.quantity,
          currency: rejectedContent.currency,
          is_landed_cost: rejectedContent.is_landed_cost == "true",
        },
      };
      rejectedContent.volumes_attributes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].volume_name = "Custom Box";
        order.shipment.volumes_attributes[indexOne].order_items_attributes =
          element.order_items_attributes;

        order.shipment.volumes_attributes[
          indexOne
        ].volume_type = `${Math.random().toString(16).slice(2)}`; //add box hash
        rejectedContent.volumes_attributes[
          indexOne
        ].order_items_attributes.forEach((element, indexTwo) => {
          order.shipment.volumes_attributes[indexOne].order_items_attributes[
            indexTwo
          ].box = order.shipment.volumes_attributes[indexOne].volume_type;
          order.shipment.volumes_attributes[indexOne].order_items_attributes[
            indexTwo
          ].unit_price = element.value;
          order.shipment.volumes_attributes[indexOne].order_items_attributes[
            indexTwo
          ].category = this.getCategory(element.hs_code);
        });
      });
      rejectedContent.volumes_attributes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].collected_icms =
          element.collected_icms;
        order.shipment.volumes_attributes[indexOne].collected_ii =
          element.collected_ii;
      });

      this.$helpers.saveUnsavedWork("order-create", order);
      delete rejectedOrder.error_info.customer_document_type;
      this.$router.push({
        name: "create_order",
        params: {
          isFrom: "isReject",
          orderProp: rejectedOrder.content,
          errorsProp: rejectedOrder.error_info,
          logIndex: rejectedOrder.id,
          //orderIndex: rejectedOrder.order_index
        },
      });
    },
    getCategory(hscode) {
      let finalCategory = "Others";
      for (var i in HS_CODES) {
        if (HS_CODES[i] == hscode) {
          finalCategory = i;
        }
      }
      return finalCategory;
    },
    truncateString(str, limit) {
      let finalStr = str;
      if (typeof finalStr !== "string") {
        finalStr = JSON.stringify(str);
      }
      if (finalStr.length > limit) {
        return finalStr.substring(0, limit) + "(...)";
      }
      return finalStr;
    },
  },
  computed: {
    buttonOptions() {
      return [
        {
          text: this.$t("delete"),
          value: "remove",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("ordersRejectedPage.title"),
          found: this.totalItems,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        { key: "order_number", label: this.$t("ordersListPage.orderNo") },
        {
          key: "created_at",
          label: this.$t("ordersRejectedPage.dateImport"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        { key: "content", label: this.$t("ordersRejectedPage.content") },
        {
          key: "error_info",
          label: this.$t("ordersRejectedPage.error"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  .heading-right {
    min-width: 15.87rem;
  }
}
</style>
