import HttpService from "./HttpService";

export default class AddressCompleteService {
  constructor() {
    this.httpService = HttpService;
  }

  getAddress(postal_code) {
    return this.httpService.get(`/v1/search_address/${postal_code}`);
  }
}
