<template>
  <div>
    <div v-for="(value, key) in quotes" :key="key">
      <NbCardItemsManyAttributes
        v-for="successQuote in quotes[key]"
        :genericMessage="genericMessage"
        :key="successQuote.id + 'quote-cards'"
        :id="successQuote.id + 'quote-cards-id'"
        :type="key"
        :fields="fields"
        :item="successQuote"
        class="mb-3"
        @selected="$emit('selected', $event)"
        v-model="content"
      />
    </div>
  </div>
</template>

<script>
import NbCardItemsManyAttributes from "@/components/cards/NbCardItemsManyAttributes.vue";

export default {
  name: "QuotesTable",
  components: { NbCardItemsManyAttributes },
  props: {
    quotes: {
      type: [Object, Array],
      required: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    formVal: {
      required: false,
    },
    /* value: {
      required: false,
    }, */
    fields: {
      type: Array,
      required: true,
    },
    genericMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      DHLLogo: require("@/assets/img/couriers/dhl-logo.svg"),
      correiosLogo: require("@/assets/img/couriers/correios-logo.svg"),
    };
  },
  methods: {
    onRowSelected(items) {
      this.selectedContract = { ...items[0] };
    },
  },
  created() {},
  computed: {
    sorteredQuotes() {
      const quotesBase = this.quotes;
      return quotesBase.sort((current, next) =>
        current.freigh_cost > next.freigh_cost ? 1 : -1
      );
    },
    content: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
  },
  watch: {
    /* //altera o input (way data bind ->)
    content(newValue) {
      this.$emit("update:formVal", newValue);
    },
    //altera o input vindo do pai (way data bind <-)
    formVal(newValue) {
      this.content = newValue;
    }, */
  },
};
</script>

<style lang="scss">
#quotes-table {
  &.left-text {
    & > tbody > tr > td {
      text-align: initial;
    }
    .courier-logo {
      width: 42px;
      height: 42px;
      margin-right: 10px;
    }
  }
  &.selectable {
    & > tbody > tr > td:nth-child(1) {
      width: 32px;
    }
  }
}
</style>
