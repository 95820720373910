<template>
  <div :id="id ? `accordion-wrapper-${id}` : `accordion-wrapper-${number}`">
    <div class="nb-card-icon-accordion pointer" @click="siwtchShowAccordion">
      <div class="mr-3 mb-2">
        <IconNumberBall width="29" :number="number" />
      </div>
      <div class="w-100">
        <div class="heading-3 mb-1">
          {{ title }}
          <span
            class="nb-badge"
            :class="isFormDone.class ? isFormDone.class : ''"
          >
            {{ isFormDone.text ? isFormDone.text : "" }}
          </span>
        </div>
        <div class="body-4">{{ subTitle }} <slot name="beforeForm" /></div>
      </div>
      <div class="mt-auto">
        <div class="d-flex">
          <NbHelpText
            v-if="helpText"
            :id="`${id}-popover`"
            class="m-auto text-gray-40"
            :size="helpTextSize"
            placement="topright"
          >
            {{ helpText }}
          </NbHelpText>
          <NbButton class="ml-2" variant="tertiary">
            <i
              class="fa fa-chevron-down p-1 text-gray-40"
              :class="internalShowAccordion ? 'rotate-0' : 'rotate-right-90'"
            ></i>
          </NbButton>
        </div>
      </div>
    </div>
    <div class="nb-card-icon-accordion-body">
      <b-collapse
        :id="id ? `card-icon-accordion-${id}` : `card-icon-accordion-${number}`"
        class="mt-0 steps-card-detail-body"
        v-model="internalShowAccordion"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div
            v-for="formInput in formInputs"
            :key="formInput.id"
            :class="formInput.classes"
          >
            <NbCustomerTypeAhead
              v-if="formInput.type === 'NbCustomerTypeAhead'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              :placeholder="content[formInput.key]"
              @selected="onSelected($event)"
              @valid="$emit('updateValidFields', $event)"
              @invalid="(event) => $emit('invalid', event)"
              @input="updateCustomerFullName($event, formInput.key)"
              @loaded="$emit('loaded', true)"
              v-model="content[formInput.key]"
            />

            <NbSelectInput
              v-else-if="formInput.type === 'select'"
              :required="formInput.required"
              :disabled="formInput.disabled"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              :options="formInput.options"
              @click="$emit(formInput.onClick, content[formInput.key])"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              v-model="content[formInput.key]"
            />

            <NbTextInput
              v-else-if="formInput.type === 'email'"
              :required="formInput.required"
              :rules="formInput.rules || 'email'"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :type="formInput.type"
              :append="formInput.append"
              :prepend="formInput.prepend"
              :error="errors[formInput.key] || formInput.error"
              :placeholder="formInput.placeholder"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              v-model="content[formInput.key]"
            />

            <NbPostalCodeInput
              v-else-if="formInput.type === 'postalCode'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              :placeholder="formInput.placeholder"
              @found="onAddressFound($event)"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              v-model="content[formInput.key]"
            />

            <NbDocumentInput
              v-else-if="formInput.type === 'document'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :country="formInput.country"
              :error="errors[formInput.key] || formInput.error"
              :placeholder="formInput.placeholder"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              v-model="content[formInput.key]"
            />

            <NbRadio
              v-else-if="formInput.type === 'radio'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :classes="formInput.inputClasses"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              @click="$emit(formInput.onClick, content[formInput.key])"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              :options="formInput.options"
              v-model="content[formInput.key]"
            />

            <NbSwitch
              v-else-if="formInput.type === 'switch'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              @click="$emit(formInput.onClick, content[formInput.key])"
              @valid="$emit('updateValidFields', $event)"
              v-model="content[formInput.key]"
            />

            <NbCheckbox
              v-else-if="formInput.type === 'checkbox'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :id="formInput.id"
              :name="formInput.name"
              :label="formInput.label"
              :helpText="formInput.helpText"
              :nameHelpText="formInput.nameHelpText"
              @click="$emit(formInput.onClick, content[formInput.key])"
              @valid="$emit('updateValidFields', $event)"
              :error="errors[formInput.key] || formInput.error"
              v-model="content[formInput.key]"
            />

            <NbInternationalPhoneInput
              v-else-if="formInput.type === 'international-phone'"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :disableOn="formInput.disableOn"
              :id="formInput.id"
              :classes="formInput.inputClasses"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              :placeholder="formInput.placeholder"
              :code="content[formInput.codeKey]"
              @select="updateCountryCode(formInput.codeKey, $event)"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              v-model="content[formInput.key]"
            />

            <img
              v-else-if="
                formInput.type === 'imageView' ||
                formInput.type === 'image-view' ||
                formInput.type === 'image'
              "
              :id="formInput.id"
              :classes="formInput.inputClasses"
              :src="formInput.src"
              :alt="formInput.alt"
              :width="formInput.width"
              :height="formInput.height"
            />

            <NbTextInput
              v-else
              :id="formInput.id"
              :required="formInput.required"
              :rules="formInput.rules || ''"
              :name="formInput.name"
              :masks="formInput.masks"
              :helpText="formInput.helpText"
              :type="formInput.type"
              :append="formInput.append"
              :prepend="formInput.prepend"
              :decimals="formInput.decimals"
              :min="formInput.min"
              :step="formInput.step"
              :disabled="formInput.disabled"
              :maxlength="formInput.maxlength"
              :placeholder="formInput.placeholder"
              :error="errors[formInput.key] || formInput.error"
              @valid="$emit('updateValidFields', $event)"
              @invalid="$emit('invalid', $event)"
              v-model="content[formInput.key]"
            />
          </div>
        </div>
        <slot />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import IconNumberBall from "../icons/IconNumberBall.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbCustomerTypeAhead from "@/components/orders/create/receiver/NbCustomerTypeAhead.vue";
import NbPostalCodeInput from "@/components/input/postal-code/NbPostalCodeInput.vue";
import NbDocumentInput from "@/components/input/document/NbDocumentInput.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbInternationalPhoneInput from "@/components/input/phone/NbInternationalPhoneInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";

export default {
  components: {
    IconNumberBall,
    NbButton,
    NbHelpText,
    NbTextInput,
    NbSelectInput,
    NbCustomerTypeAhead,
    NbPostalCodeInput,
    NbDocumentInput,
    NbSwitch,
    NbCheckbox,
    NbInternationalPhoneInput,
    NbRadio,
  },
  props: {
    id: {
      type: [Number, String],
    },
    /*  value: {
      type: Object,
      required: true,
    }, */
    formVal: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      require: true,
    },
    formInputs: {
      type: Array,
      require: false,
    },
    title: {
      type: String,
      require: false,
    },
    subTitle: {
      type: String,
      require: false,
    },
    twoPartFormDone: {
      type: Boolean,
      required: false,
      default: null,
    },
    isFrom: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "lg",
    },
    accordionKey: {
      type: String,
      required: false,
    },
    showAccordion: {
      type: Boolean,
      required: false,
      default: null,
    },
    hasError: {
      type: Boolean,
      required: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formToEmit: {},
      internalShowAccordion: true,
      phoneCountryCode: "",
    };
  },
  created() {
    if (this.formInputs) {
      this.loadFormToEmit();
    }
    if (typeof this.showAccordion === "boolean") {
      this.internalShowAccordion = this.showAccordion;
      return;
    }
  },
  methods: {
    //inicializa o form
    loadFormToEmit() {
      let formKeys = {};
      this.formInputs.forEach((formInput) => {
        formKeys[formInput.key] = "";
      });
      this.formToEmit = JSON.parse(JSON.stringify(formKeys));
    },
    updateCountryCode(codeKey, { code }) {
      this.content[codeKey] = code;
    },
    onSelected(selectedCustomer) {
      Object.keys(selectedCustomer).forEach((key) => {
        const transposedKey = "customer_" + key;
        this.content[transposedKey] = selectedCustomer[key];
      });

      this.$emit("selected", selectedCustomer);
    },
    updateCustomerFullName(fullName, key) {
      this.content[key] = fullName;
      this.$emit("input", this.content);
    },
    onAddressFound(address) {
      if (!this.isFrom || this.isFromLoaded) {
        this.content.customer_address = "";
        this.content.customer_city = "";
        this.content.customer_state = "";
        if (address.logradouro || address.bairro) {
          this.content.customer_address = `${address.logradouro} - ${address.bairro}`;
        }
        this.content.customer_city = address.localidade;
        this.content.customer_state = address.uf;
      }
      this.isFromLoaded = true;
    },
    siwtchShowAccordion() {
      if (typeof this.showAccordion === "boolean") {
        this.$emit("siwtchShowAccordion", this.accordionKey);
        setTimeout(() => {
          this.scrollToId(`accordion-wrapper-${this.id}`);
        }, 300);
        return;
      }
      this.internalShowAccordion = !this.internalShowAccordion;
      setTimeout(() => {
        this.scrollToId(`accordion-wrapper-${this.id}`);
      }, 300);
    },
    scrollToId(id) {
      if (id) {
        const elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  computed: {
    content: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
    isFormDone() {
      let isDone = false;
      let formAttributes = {
        class: "bg-warning-10",
        text: this.$t("incomplete"),
      };

      if (typeof this.hasError === "boolean" && this.hasError) {
        formAttributes = {
          class: "bg-danger text-white",
          text: this.$t("erro"),
        };
        this.$emit("firstFormDone", isDone);
        return formAttributes;
      }
      let count = 0;
      if (this.formInputs) {
        const filteredFormToEmit = this.formInputs.filter((formInput) => {
          if (formInput.required) {
            return formInput;
          }
        });
        filteredFormToEmit.forEach((element) => {
          if (
            this.content[element.key] ||
            typeof this.content[element.key] === "boolean"
          ) {
            count++;
            return;
          }
          isDone = false;
          formAttributes = {
            class: "bg-warning-10",
            text: this.$t("incomplete"),
          };
        });

        if (count >= filteredFormToEmit.length) {
          isDone = true;
          formAttributes = {
            class: "bg-success text-white",
            text: this.$t("complete"),
          };
        }
      } else {
        isDone = true;
        formAttributes = {
          class: "bg-success text-white",
          text: this.$t("complete"),
        };
      }

      if (typeof this.twoPartFormDone === "boolean") {
        //Send if this form is complete or not (second part need to be checked by father element)
        this.$emit("firstFormDone", isDone);

        if (!this.twoPartFormDone) {
          isDone = false;
          formAttributes = {
            class: "bg-warning-10",
            text: this.$t("incomplete"),
          };
        }
      }

      this.$emit("formDoneChange", isDone);
      return formAttributes;
    },
  },
  watch: {
    formVal() {
      this.isFormDone();
    },
    formToEmit: {
      //deep: true,
      handler() {
        this.$emit("formChange", this.formToEmit);
      },
    },
    showAccordion() {
      if (typeof this.showAccordion === "boolean") {
        this.internalShowAccordion = this.showAccordion;
      }
    },
    twoPartFormDone() {
      this.isFormDone();
    },
  },
};
</script>

<style scoped>
.nb-card-icon-accordion {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 2rem 3.12rem 0rem 3.12rem;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.nb-card-icon-accordion-body {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 1rem 6rem 1rem 6rem;
}

.logo-previsualization {
  text-align: center;
}
.logo-previsualization img {
  object-fit: contain;
  cursor: pointer;
}
</style>
