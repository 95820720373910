<template>
  <b-modal
    id="modal-unsaved-work"
    centered
    :title="title"
    v-model="isOpen"
    @ok="onOk"
    @cancel="onCancel"
    @close="onClose"
    @hidden="onClose"
  >
    <div class="modal-body">
      <div class="body-2 my-4">
        {{ information }}
        <b
          v-if="
            this.isFrom == 'isClone' ||
            this.isFrom == 'isEdit' ||
            this.isFrom == 'isReject'
          "
        >
          <NbProgressBar
            id="progressBar-unsalved-work"
            :height="20"
            max="100"
            :progressVal="load_current"
          />
        </b>
        <b v-else>
          {{ configuredDate }}
        </b>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <NbButton class="float-left" variant="secondary" @click="cancel">
        {{ cancelText }}
      </NbButton>
      <NbButton @click="ok">
        {{ okText }}
      </NbButton>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment";
import NbButton from "@/components/buttons/NbButton.vue";
import NbProgressBar from "@/components/progressBar/NbProgressBar.vue";

export default {
  name: "UnsavedWorkModal",
  components: { NbButton, NbProgressBar },
  props: {
    name: {
      type: String,
      required: true,
    },
    isFrom: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    unsavedWork: {},
    isOpen: false,
    load_current: 0,
  }),
  created() {
    this.unsavedWork = this.$helpers.loadUnsavedWork(this.name);
    const storageEdit = JSON.parse(localStorage.getItem("isEdit"));
    if (storageEdit) {
      localStorage.removeItem("isEdit");
    }
  },
  computed: {
    hasUnsavedWork() {
      return !!Object.keys(this.unsavedWork).length;
    },
    configuredDate() {
      if (this.hasUnsavedWork) {
        return moment(this.unsavedWork.updatedAt).calendar();
      }
      return null;
    },
    okText() {
      if (this.isFrom) {
        return this.$t("yes");
      }
      return this.$t("components.unsavedWorkModal.draft.resume");
    },
    cancelText() {
      return this.$t("components.unsavedWorkModal.discard");
    },
    title() {
      if (this.isFrom) {
        return this.$t("components.unsavedWorkModal.otherDraft.title");
      }
      return this.$t("components.unsavedWorkModal.draft.title");
    },
    information() {
      if (this.isFrom) {
        this.loadingProgress(100, 25, 250);
        return (
          this.$t("components.unsavedWorkModal.otherDraft.information") +
          this.unsavedWork.data.shipment.order_number
        );
      }
      return this.$t("components.unsavedWorkModal.draft.information");
    },
  },
  methods: {
    onClose() {
      this.unsavedWork = {};
    },
    onCancel() {
      this.onDiscard();
    },
    onOk() {
      this.$emit("ok", this.unsavedWork);
      this.clearUnsavedWork();
      if (
        this.isFrom == "isClone" ||
        this.isFrom == "isEdit" ||
        this.isFrom == "isReject"
      ) {
        this.$emit("fixVolumeWeight");
        this.$emit("fixSellerPhone");
      }
    },
    onDiscard() {
      this.$emit("cancel");
      this.clearUnsavedWork();
    },
    clearUnsavedWork() {
      this.$helpers.deleteUnsavedWork(this.name);
      this.unsavedWork = {};
    },
    loadingProgress(max, jump, speed) {
      const loadBar = setInterval(() => {
        if (this.load_current < max) {
          this.load_current += jump || 1;
        } else {
          clearInterval(loadBar);
          this.onOk();
          if (this.isFrom == "isReject") {
            this.$emit("loadRejectErros");
          }
        }
      }, speed);
    },
  },
  watch: {
    hasUnsavedWork(unsaved) {
      if (unsaved !== this.isOpen) {
        this.isOpen = unsaved;
      }

      if (!unsaved) {
        this.$emit("cancel");
      }
    },
  },
};
</script>

<style>
#modal-unsaved-work .modal-content {
  background: var(--background);
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
}

#modal-unsaved-work .close {
  color: var(--black);
  opacity: 1;
}

#modal-unsaved-work .modal-header {
  border: 0px;
  padding: 0rem;
}

#modal-unsaved-work .modal-body {
  padding: 0rem;
}

#modal-unsaved-work .modal-footer {
  justify-content: space-between;
  border: 0px;
  padding: 0rem;
}

#modal-unsaved-work .visibility-hidden {
  visibility: hidden;
}
</style>
