<template>
  <TypeAheadInput
    :tabindex="tabindex"
    :items="products"
    :serializer="serializer"
    :id="id"
    :class="wrapperClasses"
    :name="name"
    :error="errorsFeedback"
    :required="required"
    :helpText="helpText"
    :placeholder="placeholder"
    @blur="onBlur"
    @selected="(event) => $emit('selected', event)"
    @valid="(event) => $emit('valid', event)"
    @invalid="(event) => $emit('invalid', event)"
    @input="getProducts($event)"
    v-model="searchQuery"
  />
</template>

<script>
import NProgress from "nprogress";
import TypeAheadInput from "../../../input/type-ahead/TypeAheadInput.vue";
import baseCustomerTypeAheadProps from "./baseCustomerTypeAheadProps.js";

import ProductService from "@/services/ProductService.js";

const productService = new ProductService();

export default {
  name: "NbProductTypeAhead",
  components: { TypeAheadInput },
  props: {
    ...baseCustomerTypeAheadProps,
    tabindex: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      products: [],
      searchQuery: this.value,
      internalErrors: [],
      timer: null,
    };
  },
  methods: {
    serializer(customer) {
      return customer[this.type];
    },
    onBlur() {
      this.$emit("blur");
      this.checkRules(this.searchQuery);
    },
    checkRules(newValue) {
      this.internalErrors = this.$helpers.inputRules(
        newValue,
        this.rules,
        this.required
      );
    },
    allOk() {
      const element = document.getElementById(this.id);
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        if (this.searchQuery.length > 0) {
          element.classList.add("is-valid");
        }
      }, 200);
    },
    getProducts(value) {
      this.$emit("input", value);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        productService
          .fetchProduct(value, this.type)
          .then((response) => {
            this.products = response.data.data.elements;
          })
          .catch(() => {
            NProgress.done();
          })
          .finally(() => {
            this.$emit("loaded", true);
          });
      }, 1000);
    },
  },
  computed: {
    wrapperClasses() {
      /* if (this.disabled) {
        return "disabled ";
      } */
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        return "is-invalid ";
      }

      if (this.searchQuery && this.searchQuery.length > 0) {
        return "is-valid";
      }
      return "";
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error;
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors;
      }
      return [];
    },
  },
  watch: {
    value(newValue) {
      if (newValue !== this.searchQuery) {
        this.searchQuery = newValue;
      }
    },
    searchQuery(newValue) {
      if (newValue !== this.value) {
        this.value = newValue;
      }
      this.$emit("input", newValue);
      //this.allOk();
    },
    /*  internalErrors() {
      this.allOk();
    }, */
  },
};
</script>
