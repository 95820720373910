<template>
  <div>
    <!-- success -->
    <div
      v-if="type === 'success' || type === ''"
      class="card-items borded pointer"
      :id="`helptext-cardItems-${item.id}-card-item-external`"
      :class="classes"
      @click="select(item)"
    >
      <div class="d-flex justify-content-between" :class="classes">
        <div class="d-flex justify-content-between">
          <div
            class="img-empty"
            :class="image ? 'img-box' : ''"
            style="min-height: 4.25rem; min-width: 4.45rem"
          >
            <img
              v-if="image"
              :src="image"
              style="
                height: 4.25rem;
                width: 4.45rem;
                object-fit: contain;
                padding: 0.2rem;
              "
            />
          </div>
          <div class="block-text-1" v-if="item.name" style="width: 110px">
            <div>{{ item.name }}</div>
            <div class="mt-2 body-1">
              <RatingStars
                v-if="item.ratedStarts"
                :currentRating="item.ratedStarts"
                size="0.6rem"
              />ID: {{ item.id }}
            </div>
          </div>
        </div>
        <div
          v-for="field in fields"
          :key="field.key + '-card-item'"
          class="d-flex justify-content-between"
        >
          <div class="mr-2">
            <div class="card-text-title">
              {{ field.label }}
              <NbHelpText
                :id="`helptext-cardItems-${item.id}-card-item`"
                v-if="field.helpText"
                size="sm"
                >{{ field.helpText }}</NbHelpText
              >
            </div>
            <div class="card-text">
              {{ field.isCurrency ? "" : field.prepend
              }}{{
                field.isCurrency
                  ? standardizeValue(item[`${field.key}`], field.prepend)
                  : item[`${field.key}`]
              }}
            </div>
          </div>
        </div>

        <div class="center mt-06rem">
          <NbRadio
            v-if="showRadioBtn"
            :id="`radio-cardItems-${id}`"
            name=""
            :options="[{ text: '', value: item.id }]"
            :disabled="true"
            v-model="content"
          />
        </div>
      </div>
    </div>
    <!-- partial success || warning -->
    <div
      v-else-if="type === 'partial_success' || type === 'warning'"
      :id="`helptext-cardItems-${item.id}-card-item-external`"
      class="card-items borded pointer border-warning pb-1"
      :class="classes"
      @click="select(item)"
    >
      <div class="d-flex justify-content-between" :class="classes">
        <div class="d-flex justify-content-between">
          <div
            class="img-empty"
            :class="image ? 'img-box' : ''"
            style="min-height: 4.25rem; min-width: 4.45rem"
          >
            <img
              v-if="image"
              :src="image"
              style="
                height: 4.25rem;
                width: 4.45rem;
                object-fit: contain;
                padding: 0.2rem;
              "
            />
          </div>
          <div class="block-text-1" v-if="item.name" style="width: 110px">
            <div>{{ item.name }}</div>
            <div class="mt-2 body-1">
              <RatingStars
                v-if="item.ratedStarts"
                :currentRating="item.ratedStarts"
                size="0.6rem"
              />ID: {{ item.id }}
            </div>
          </div>
        </div>
        <div
          v-for="field in fields"
          :key="field.key + '-card-item'"
          class="d-flex justify-content-between"
        >
          <div class="mr-2">
            <div class="card-text-title">
              {{ field.label }}
              <NbHelpText
                :id="`helptext-cardItems-${item.id}-card-item`"
                v-if="field.helpText"
                size="sm"
                >{{ field.helpText }}</NbHelpText
              >
            </div>
            <div class="card-text">
              {{ field.isCurrency ? "" : field.prepend
              }}{{
                field.isCurrency
                  ? standardizeValue(item[`${field.key}`], field.prepend)
                  : item[`${field.key}`]
              }}
            </div>
          </div>
        </div>

        <div class="center mt-06rem">
          <NbRadio
            v-if="showRadioBtn"
            :id="`radio-cardItems-${id}`"
            name=""
            :options="[{ text: '', value: item.id }]"
            :disabled="true"
            v-model="content"
          />
        </div>
      </div>

      <div
        :id="`helptext-cardItems-${item.id}-card-item-external`"
        class="mt-1 link-2 fit-content"
      >
        <NbIcon
          icon="alert-triangle"
          :attributes="{
            class: 'text-warning',
            'stroke-width': 3,
            width: '.8rem',
            height: '.8rem',
          }"
        />
        More Details
        <NbHelpText
          :id="`helptext-cardItems-${item.id}-card-item-more-details`"
          :externalId="`helptext-cardItems-${item.id}-card-item-external`"
          v-if="item.message"
          size="sm"
          >{{ item.message }} {{ genericMessage }}</NbHelpText
        >
      </div>
    </div>
    <!-- error -->
    <div
      v-else-if="type === 'error' || type === 'danger'"
      :id="`helptext-cardItems-${item.id}-card-item-external`"
      class="card-items error borded not-allowed pb-1"
      :class="classes"
    >
      <div class="d-flex justify-content-between" :class="classes">
        <div class="d-flex justify-content-between">
          <div
            class="img-empty"
            :class="image ? 'img-box' : ''"
            style="min-height: 4.25rem; min-width: 4.45rem"
          >
            <img
              v-if="image"
              :src="image"
              style="
                height: 4.25rem;
                width: 4.45rem;
                object-fit: contain;
                padding: 0.2rem;
              "
            />
          </div>
          <div class="block-text-1" v-if="item.name" style="width: 110px">
            <div>{{ item.name }}</div>
            <div class="mt-2 body-1">
              <RatingStars
                v-if="item.ratedStarts"
                :currentRating="item.ratedStarts"
                size="0.6rem"
              />ID: {{ item.id }}
            </div>
          </div>
        </div>
        <div
          v-for="field in fields"
          :key="field.key + '-card-item'"
          class="d-flex justify-content-between"
        >
          <div class="mr-2">
            <div class="card-text-title">
              {{ field.label }}
              <NbHelpText
                :id="`helptext-cardItems-${item.id}-card-item`"
                v-if="field.helpText"
                size="sm"
                >{{ field.helpText }}</NbHelpText
              >
            </div>
            <div class="card-text">
              {{ field.isCurrency ? "" : field.prepend
              }}{{
                field.isCurrency
                  ? standardizeValue(item[`${field.key}`], field.prepend)
                  : item[`${field.key}`]
              }}
            </div>
          </div>
        </div>

        <div class="center mt-06rem">
          <NbRadio
            v-if="showRadioBtn"
            :id="`radio-cardItems-${id}`"
            name=""
            :options="[{ text: '', value: item.id }]"
            :disabled="true"
            v-model="content"
          />
        </div>
      </div>

      <!-- OLD: mostrar a mensagem apenas no more details <div :id="`helptext-cardItems-${item.id}-card-item-external`" class="mt-1 link-2 fit-content"> -->
      <div class="mt-1 link-2 fit-content">
        <NbIcon
          icon="alert-circle"
          :attributes="{
            class: 'text-danger',
            'stroke-width': 3,
            width: '.8rem',
            height: '.8rem',
          }"
        />
        More Details
        <NbHelpText
          :id="`helptext-cardItems-${item.id}-card-item-more-details`"
          :externalId="`helptext-cardItems-${item.id}-card-item-external`"
          v-if="item.message || item.error"
          size="sm"
          >{{ item.message || item.error }} {{ genericMessage }}</NbHelpText
        >
      </div>
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import RatingStars from "@/components/ratingstars/RatingStars.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbCardItems",
  components: {
    NbHelpText,
    NbRadio,
    RatingStars,
    NbIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    showRadioBtn: {
      type: Boolean,
      default: true,
    },
    genericMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      /* cardConfig: [
        { text: '', value: 'Value1' },
      ], */
      content: this.value,
    };
  },
  methods: {
    select(item) {
      if (this.content == this.item.id) {
        this.reset();
        this.$emit("selected", {});
        return;
      }
      this.content = this.item.id;
      this.$emit("selected", item);
    },
    reset() {
      this.content = null;
    },
    standardizeValue(val, currency) {
      if (typeof val === "number" || typeof val === "string") {
        return currency + parseFloat(val).toFixed(2);
      }
      return "-";
    },
  },
  computed: {
    classes() {
      //cardSelected != '' ? 'selected' : ''
      if (this.content == this.item.id) {
        return "selected";
      }
      return "";
    },
  },
  watch: {
    //altera o input (way data bind ->)
    content(newValue) {
      this.$emit("input", newValue);
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.content = newValue;
    },
  },
};
</script>

<style scoped>
.card-items {
  transition: all 0.3s ease;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 8px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  padding: 1rem 0rem 1rem 1.5rem;
}
.card-items.borded.selected {
  border: 1px solid var(--black) !important;
}
.card-items:hover {
  background: var(--gray-05) 0% 0% no-repeat padding-box;
  border: 1px solid var(--gray-20);
}
.card-items.error {
  transition: all 0.3s ease;
  background: var(--gray-05) !important;
  box-shadow: 4px 4px 8px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  padding: 1rem 0rem 1rem 1.5rem;
}
.borded {
  border: 1px solid var(--gray-20);
}
.img-empty {
  height: 4.25rem;
  width: 4.45rem;
  background: var(--gray-10);
  margin-right: 1rem;
}
.img-empty.img-box {
  background: var(--gray-05);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.mt-06rem {
  margin-top: 0.6rem !important;
}
.block-text-1 {
  max-width: 9.38rem;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-20)
    var(--unnamed-font-family-nunito-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--black);
  text-align: left;
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
}
.card-text-title {
  color: var(--black);
  text-align: center;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  margin-bottom: 0.5rem;
}
.fit-content {
  width: fit-content;
}
.card-text {
  color: var(--black);
  text-align: center;
  font: normal normal bold 20px/24px Nunito Sans;
  letter-spacing: 0px;
}
</style>
