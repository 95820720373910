<template>
  <div class="item-card bg-background mt-2 pt-3 px-4 pb-4">
    <div class="item-card-header d-flex justify-content-between">
      <div class="heading-3">{{ $t("item") }} {{ itemIndex + 1 }}</div>
      <div>
        <NbButton
          class="mr-1"
          variant="tertiary"
          size="sm"
          icon="plus"
          @click="copyItem(boxIndex, itemIndex)"
        >
          {{ $t("clone") }}
        </NbButton>
        <NbButton
          variant="tertiary"
          size="sm"
          icon="plus"
          @click="deleteItem(boxIndex, itemIndex)"
        >
          {{ $t("delete") }}
        </NbButton>
      </div>
    </div>
    <div class="item-card-body d-flex justify-content-between flex-wrap">
      <NbProductTypeAhead
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].sku`"
        :class="quoteVersion ? 'w-12 mt-3' : 'w-16 mt-3'"
        :name="$t('sku')"
        type="sku"
        :helpText="$t('orderCreatePage.shipmentInformation.itemskuHelpText')"
        :error="errors[`volumes[${boxIndex}].order_items[${itemIndex}].sku`]"
        :placeholder="order_items_attribute.sku || '000'"
        @selected="onSelected($event)"
        @valid="$emit('updateValidFields', $event)"
        @invalid="(event) => $emit('invalid', event)"
        @input="updateProduct($event, 'sku')"
        @loaded="$emit('loaded', true)"
        v-model="order_items_attribute.sku"
      />
      <NbProductTypeAhead
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].name`"
        :class="quoteVersion ? 'w-26 mt-3' : 'w-30 mt-3'"
        :name="$t('name')"
        type="name"
        :placeholder="
          order_items_attribute.name ||
          $t('orderCreatePage.shipmentInformation.USBcharger')
        "
        :error="errors[`volumes[${boxIndex}].order_items[${itemIndex}].name`]"
        :helpText="$t('orderCreatePage.shipmentInformation.itemnameHelpText')"
        @selected="onSelected($event)"
        @valid="$emit('updateValidFields', $event)"
        @invalid="(event) => $emit('invalid', event)"
        @input="updateProduct($event, 'name')"
        @loaded="$emit('loaded', true)"
        v-model="order_items_attribute.name"
      />

      <NbTextInput
        v-if="!quoteVersion"
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].description`"
        class="w-50 mt-3"
        :name="$t('description')"
        :placeholder="
          $t(
            'orderCreatePage.shipmentInformation.productDescriptionPlaceholder'
          )
        "
        :error="
          errors[`volumes[${boxIndex}].order_items[${itemIndex}].description`]
        "
        :helpText="
          $t('orderCreatePage.shipmentInformation.itemdescriptionHelpText')
        "
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.description"
      />
      <NbSelectInput
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].box`"
        :class="quoteVersion ? 'w-12 mt-3' : 'w-16 mt-3'"
        :name="$t('box')"
        :options="boxes"
        :placeholder="`select a ${$t('box')}`"
        :returnCompleteObject="true"
        @objectSelected="updateBox(boxIndex, itemIndex, $event)"
        :error="errors[`volumes[${boxIndex}].order_items[${itemIndex}].box`]"
        :helpText="$t('orderCreatePage.shipmentInformation.itemboxHelpText')"
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.box"
      />
      <NbCountrySelectInput
        v-if="!quoteVersion"
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].origin_country`"
        class="w-30 mt-3"
        :name="$t('originCountry')"
        placeholder="An col placeholder"
        :error="
          errors[
            `volumes[${boxIndex}].order_items[${itemIndex}].origin_country`
          ]
        "
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.origin_country"
      />
      <NbSelectInput
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].category`"
        :class="quoteVersion ? 'w-26 mt-3' : 'w-30 mt-3'"
        :name="$t('category')"
        :options="categoryOptions"
        :placeholder="`select a ${$t('category')}`"
        @input="
          updateProductHSCode(
            order_items_attribute.category,
            `volumes[${boxIndex}].order_items[${itemIndex}].hs_code`
          )
        "
        :error="
          errors[`volumes[${boxIndex}].order_items[${itemIndex}].category`]
        "
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.category"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].hs_code`"
        :class="quoteVersion ? 'w-18 mt-3' : 'w-20 mt-3'"
        type="number"
        rules="minLength:6|notLength:7|maxLength:8"
        :name="$t('hsCode')"
        placeholder="123456"
        :onkeypress="'return event.charCode >= 48 && event.charCode <= 57'"
        :checkOnChange="true"
        :disabled="!checkProductCategory(order_items_attribute.category)"
        :error="
          errors[`volumes[${boxIndex}].order_items[${itemIndex}].hs_code`]
        "
        :helpText="$t('orderCreatePage.shipmentInformation.itemhscodeHelpText')"
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.hs_code"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].weight`"
        class="w-30 mt-3"
        type="number"
        :append="weightUnit"
        :name="`${$t('unitWeight')} (${weightUnit})`"
        :decimals="3"
        :min="0.001"
        step="0.001"
        :placeholder="`0.000 ${weightUnit}`"
        :autoSelect="true"
        :error="errors[`volumes[${boxIndex}].order_items[${itemIndex}].weight`]"
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.weight"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].value`"
        class="w-30 mt-3"
        type="money"
        :prepend="currency"
        :name="`${$t('unitPrice')} (${currency})`"
        :placeholder="`0.00`"
        :decimals="2"
        :min="0.0"
        :autoSelect="true"
        :currency="currency"
        :error="errors[`volumes[${boxIndex}].order_items[${itemIndex}].value`]"
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.unit_price"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].quantity`"
        class="w-20 mt-3"
        :name="$t('quantity')"
        placeholder="0"
        :min="1"
        step="1"
        :onkeypress="'return event.charCode >= 48 && event.charCode <= 57'"
        type="number"
        :autoSelect="true"
        :error="
          errors[`volumes[${boxIndex}].order_items[${itemIndex}].quantity`] ||
          errors[`volumes[${boxIndex}].order_items[${itemIndex}].length`]
        "
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('invalid', $event)"
        v-model="order_items_attribute.quantity"
      />
      <div class="w-16 mt-3 d-flex flex-column text-center input-label">
        <div class="mt-1 mb-2">
          {{ $t("total") }}
        </div>
        <div class="number-3">
          {{ currency }}{{ order_items_attribute.value || "0.00" }}
        </div>
      </div>
      <!--
    <div class="w-100 mt-3" >
      <div class="input-label w-100">
        {{ $t('components.boxesList.productData') }}
      </div>
      <NbCheckbox 
        :id="`volumes[${boxIndex}].order_items[${itemIndex}].length`"
        class="w-100"
        :name="$t('components.boxesList.saveProduct')" 
        :error="[]"
        @valid="$emit('updateValidFields', $event)" 
        v-model="saveThisProduct" 
      />
    </div> 
    --></div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbProductTypeAhead from "@/components/orders/create/receiver/NbProductTypeAhead.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbCountrySelectInput from "../../../input/country/NbCountrySelectInput.vue";
//import NbCheckbox from '@/components/buttons/NbCheckbox.vue';

export default {
  name: "NbProductCard",
  components: {
    NbButton,
    NbTextInput,
    NbSelectInput,
    NbCountrySelectInput,
    NbProductTypeAhead /* NbCheckbox, */,
  },
  props: {
    quoteVersion: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      required: true,
    },
    weightUnit: {
      type: String,
      required: true,
    },
    errors: {
      type: [Array, Object],
      required: false,
      default: () => {},
    },
    boxes: {
      type: Array,
      required: true,
    },
    boxIndex: {
      type: Number,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    /*  value: {
      type: Object,
      required: true,
    }, */
    formValProduc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    saveThisProduct: false,
    getProductSKUControl: null,
    timer: null,
  }),
  created() {},
  methods: {
    deleteItem(boxIndex, itemIndex) {
      const itemToDelete = {
        boxIndex: boxIndex,
        itemIndex: itemIndex,
      };
      this.$emit("itemAttributeValueUpdated");
      this.$emit("deleteItem", itemToDelete);
    },
    copyItem(boxIndex, itemIndex) {
      const itemToCopy = {
        boxIndex: boxIndex,
        itemIndex: itemIndex,
        item: this.order_items_attribute,
      };
      this.$emit("copyItem", itemToCopy);
    },
    updateBox(boxOriginIndex, itemOriginIndex, event) {
      const boxToUpdate = {
        boxOriginIndex: boxOriginIndex,
        itemOriginIndex: itemOriginIndex,
        boxDestinationIndex: event.text - 1,
      };
      //esperar o input alterar a caixa
      setTimeout(() => {
        this.$emit("relocateItem", boxToUpdate);
      }, 200);
    },
    updateProductHSCode(product, hs_code_id) {
      const newHSCode = this.HS_CODES[product];

      this.$emit("updateValidFields", hs_code_id);

      if (
        product !== "Others" &&
        newHSCode !== this.order_items_attribute.hs_code
      ) {
        this.order_items_attribute.hs_code = newHSCode;
      }
    },
    checkProductCategory(category) {
      return category && category === "Others"; //if simplificado
    },
    onSelected(selectedProduct) {
      Object.assign(this.order_items_attribute, selectedProduct);
      this.order_items_attribute.value = (
        Number(this.order_items_attribute.unit_price) *
        Number(this.order_items_attribute.quantity)
      ).toFixed(2);
      this.$emit("selected", selectedProduct);
    },
    updateProduct(value, key) {
      this.order_items_attribute[key] = value;
      this.$emit("update:formValProduc", this.order_items_attribute);
    },
  },
  computed: {
    order_items_attribute: {
      get() {
        return this.formValProduc;
      },
      set(val) {
        this.$emit("update:formValProduc", val);
      },
    },
    categoryOptions() {
      return [
        {
          text: this.$t("hsCodes.acessoryNoBattery"),
          value: "Acessory (no-battery)",
        },
        { text: this.$t("hsCodes.acessory"), value: "Acessory (with battery)" },
        { text: this.$t("hsCodes.audioVideo"), value: "Audio Video" },
        { text: this.$t("hsCodes.bagsLuggages"), value: "Bags & Luggages" },
        { text: this.$t("hsCodes.boardGames"), value: "Board Games" },
        {
          text: this.$t("hsCodes.booksCollectibles"),
          value: "Books & Collectibles",
        },
        { text: this.$t("hsCodes.cameras"), value: "Cameras" },
        {
          text: this.$t("hsCodes.computersLaptops"),
          value: "Computers & Laptops",
        },
        { text: this.$t("hsCodes.documents"), value: "Documents" },
        {
          text: this.$t("hsCodes.dryFoodsSupplements"),
          value: "Dry Foods & Supplements",
        },
        { text: this.$t("hsCodes.fashion"), value: "Fashion" },
        { text: this.$t("hsCodes.gaming"), value: "Gaming" },
        { text: this.$t("hsCodes.healthBeauty"), value: "Health & Beauty" },
        { text: this.$t("hsCodes.homeAppliances"), value: "Home Appliances" },
        { text: this.$t("hsCodes.homeDecor"), value: "Home Decor" },
        { text: this.$t("hsCodes.jewelry"), value: "Jewelry" },
        { text: this.$t("hsCodes.mobilePhone"), value: "Mobile Phone" },
        { text: this.$t("hsCodes.petAccessory"), value: "Pet Accessory" },
        { text: this.$t("hsCodes.shoes"), value: "Shoes" },
        { text: this.$t("hsCodes.sportLeisures"), value: "Sport & Leisures" },
        { text: this.$t("hsCodes.tablets"), value: "Tablets" },
        { text: this.$t("hsCodes.toys"), value: "Toys" },
        { text: this.$t("hsCodes.watches"), value: "Watches" },
        { text: this.$t("hsCodes.others"), value: "Others" },
      ];
    },
    HS_CODES() {
      return {
        ["Acessory (no-battery)"]: 903289,
        ["Acessory (with battery)"]: 851822,
        ["Audio Video"]: 711719,
        ["Bags & Luggages"]: 420299,
        ["Board Games"]: 950300,
        ["Books & Collectibles"]: 490199,
        ["Cameras"]: 852580,
        ["Computers & Laptops"]: 847130,
        ["Documents"]: 490199,
        ["Dry Foods & Supplements"]: 210690,
        ["Fashion"]: 430310,
        ["Gaming"]: 950430,
        ["Health & Beauty"]: 330499,
        ["Home Appliances"]: 851640,
        ["Home Decor"]: 442090,
        ["Jewelry"]: 711719,
        ["Mobile Phone"]: 851712,
        ["Pet Accessory"]: 420100,
        ["Shoes"]: 640590,
        ["Sport & Leisures"]: 420291,
        ["Tablets"]: 847130,
        ["Toys"]: 950300,
        ["Watches"]: 910219,
      };
    },
  },
  watch: {
    "order_items_attribute.unit_price": function () {
      this.order_items_attribute.value = (
        Number(this.order_items_attribute.unit_price) *
        Number(this.order_items_attribute.quantity)
      ).toFixed(2);
      this.$emit("itemAttributeValueUpdated");
    },
    "order_items_attribute.quantity": function () {
      this.order_items_attribute.value = (
        Number(this.order_items_attribute.unit_price) *
        Number(this.order_items_attribute.quantity)
      ).toFixed(2);
      this.$emit("itemAttributeValueUpdated");
    },
    "order_items_attribute.weight": function () {
      this.$emit("itemAttributeValueUpdated");
    },
  },
};
</script>

<style scoped>
.item-card-header {
  margin-bottom: 2rem;
}
</style>
