<template>
  <div class="" :class="errorsFeedback ? 'is-invalid' : ''">
    <label
      v-if="name"
      class="input-label"
      :class="disabled ? 'not-allowed' : ''"
      :for="id"
    >
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <input
      :tabindex="tabindex"
      :type="type"
      :min="min"
      :step="step"
      class="nb form-control input"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      @blur="onBlur"
      v-model="inputText"
      v-facade="mask"
      :class="classes"
    />
    <ErrorFeedback :error="errorsFeedback" />
  </div>
</template>

<script>
import { facade } from "vue-input-facade";
import { getValidDocumentName } from "../../../validators.js";
import { CPF_MASK, CNPJ_MASK, MEXICAN_CPF_MASK } from "../../../constants.js";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import ErrorFeedback from "../../generic/ErrorFeedback.vue";

export default {
  name: "DocumentInput",
  directives: { facade },
  components: {
    NbHelpText,
    ErrorFeedback,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    prepend: {
      type: String,
      required: false,
    },
    append: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    decimals: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    step: {
      type: String,
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    country: {
      type: String,
      required: false,
      default: "BR",
    },
  },
  data: () => ({
    textContent: "",
    inputText: "",
    internalErrors: [],
  }),
  created() {
    this.textContent = this.value;
    this.inputText = JSON.parse(JSON.stringify(this.value));
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      this.checkRules(this.textContent);
      this.validateTextContent();
    },
    validateTextContent() {
      let contentIsValid = true;
      if (this.country === "BR") {
        contentIsValid = getValidDocumentName(this.textContent);
      }

      if (!contentIsValid) {
        this.$emit("invalid", {
          id: this.id,
          message: this.errorMessage,
        });
        return;
      }

      this.$emit("valid", this.id);
    },
    onlyNumbers(string) {
      const regex = /[^0-9 ]/g;
      const finalString = string.replace(regex, "");
      return finalString;
    },
    checkRules(newValue) {
      this.internalErrors = this.$helpers.inputRules(
        newValue,
        this.rules,
        this.required
      );
    },
    allOk() {
      const element = document.getElementById(this.id);
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        if (this.textContent && this.textContent.length > 0) {
          element.classList.add("is-valid");
        }
      }, 200);
    },
  },
  computed: {
    classes() {
      if (this.errorsFeedback) {
        return this.disabled ? "is-invalid not-allowed" : "is-invalid";
      }
      if (this.disabled) {
        return "not-allowed";
      }

      if (this.textContent && this.textContent.length > 0) {
        return "is-valid";
      }
      return "";
    },
    mask() {
      let finalResult = [CPF_MASK, CNPJ_MASK];
      if (this.country == "MX") {
        finalResult = [MEXICAN_CPF_MASK];
      }

      return finalResult;
    },
    documentType() {
      if (this.textContent.length < 8) {
        return "DOCUMENT";
      }
      if (this.textContent.length > 11) {
        return "CNPJ";
      }
      return "CPF";
    },
    errorMessage() {
      if (this.required && !this.inputText.length) {
        return this.$t("errorMessages.required");
      }

      const errorsList = {
        DOCUMENT: this.$t("errorMessages.invalidDocument"),
        CNPJ: this.$t("errorMessages.invalidCNPJ"),
        CPF: this.$t("errorMessages.invalidCPF"),
      };
      return errorsList[this.documentType];
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error[0];
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors[0];
      }
      return "";
    },
  },
  watch: {
    inputText(newValue) {
      this.textContent = this.onlyNumbers(newValue);

      this.$emit("input", this.textContent);
      this.validateTextContent();
      //this.allOk();
    },
    value(newValue) {
      if (newValue !== this.textContent) {
        this.textContent = this.onlyNumbers(newValue);
        this.inputText = JSON.parse(JSON.stringify(this.value));
      }
    },

    /* internalErrors() {
      this.allOk();
    }, */
  },
};
</script>
