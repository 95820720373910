<template>
  <div>
    <div class="d-flex justify-content-start flex-wrap">
      <NbSelectInput
        :id="`volumes[${boxIndex}].volume_name`"
        class="w-32-5 mr-3"
        :name="$t('box')"
        :placeholder="`select a ${$t('box')}`"
        :options="boxOptions"
        @change="updateBox($event)"
        v-model="box.volume_name"
      />
      <NbTextInput
        :required="prc"
        :name="$t('generalDescription')"
        :id="`volumes[${boxIndex}].general_description`"
        class="w-66"
        type="text"
        :decimals="2"
        :autoSelect="true"
        :error="
          errors[`volumes[${boxIndex}].general_description`] ||
          internalErrors[`volumes[${boxIndex}].general_description`]
        "
        @invalid="$emit('updateInvalidFields', $event)"
        @valid="$emit('updateValidFields', $event)"
        v-model="box.general_description"
      />

      <Alert
        class="mb-2 w-100 alert-create-box-model"
        :link="boxLink"
        :aling="'center'"
        type="success"
        :message="$t('orderCreatePage.shipmentInformation.knowYouCan')"
      />
    </div>
    <div class="d-flex justify-content-between flex-wrap">
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].height`"
        class="w-16 mt-3"
        type="number"
        :append="sizeUnit"
        :name="`${$t('height')} (${sizeUnit})`"
        :decimals="2"
        step="0.01"
        :placeholder="`0.00`"
        :autoSelect="true"
        :disabled="
          checkVolumeName(box, 'height', `volumes[${boxIndex}].height`)
        "
        :error="
          errors[`volumes[${boxIndex}].height`] ||
          internalErrors[`volumes[${boxIndex}].height`]
        "
        @invalid="$emit('updateInvalidFields', $event)"
        @valid="$emit('updateValidFields', $event)"
        v-model="box.height"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].width`"
        class="w-16 mt-3"
        type="number"
        :append="sizeUnit"
        :name="`${$t('width')} (${sizeUnit})`"
        :decimals="2"
        step="0.01"
        :placeholder="`0.00`"
        :autoSelect="true"
        :disabled="checkVolumeName(box, 'width', `volumes[${boxIndex}].width`)"
        :error="
          errors[`volumes[${boxIndex}].width`] ||
          internalErrors[`volumes[${boxIndex}].width`]
        "
        @invalid="$emit('updateInvalidFields', $event)"
        @valid="$emit('updateValidFields', $event)"
        v-model="box.width"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].length`"
        class="w-16 mt-3"
        type="number"
        :append="sizeUnit"
        :name="`${$t('length')} (${sizeUnit})`"
        :decimals="2"
        step="0.01"
        :placeholder="`0.00`"
        :autoSelect="true"
        :disabled="
          checkVolumeName(box, 'length', `volumes[${boxIndex}].length`)
        "
        :error="
          errors[`volumes[${boxIndex}].length`] ||
          internalErrors[`volumes[${boxIndex}].length`]
        "
        @invalid="$emit('updateInvalidFields', $event)"
        @valid="$emit('updateValidFields', $event)"
        v-model="box.length"
      />
      <NbTextInput
        required
        :id="`volumes[${boxIndex}].weight`"
        class="w-16 mt-3"
        type="number"
        :append="weightUnit"
        :name="`${$t('weight')} (${weightUnit})`"
        :decimals="3"
        step="0.001"
        :placeholder="`0.000`"
        :autoSelect="true"
        :disabled="
          checkVolumeName(box, 'weight', `volumes[${boxIndex}].weight`)
        "
        :error="
          errors[`volumes[${boxIndex}].weight`] ||
          internalErrors[`volumes[${boxIndex}].weight`]
        "
        :helpText="
          $t('orderCreatePage.shipmentInformation.boxemptyweightHelpText')
        "
        @invalid="$emit('updateInvalidFields', $event)"
        @valid="$emit('updateValidFields', $event)"
        v-model="box.weight"
      />

      <div class="w-16 mt- d-flex flex-column text-center input-label">
        <div class="mt-1 mb-2">
          <div>
            {{ $t("totalWeight") }}
          </div>
          <div>({{ $t("box") }} + {{ $t("items") }})</div>
        </div>
        <div class="number-3">{{ getTotalWeight(box) }} {{ weightUnit }}</div>
        <div data-toggle="modal" data-target="#editMetricModal" class="link-1">
          {{ $t("components.modalEditSellerMetric.title") }}
        </div>
      </div>

      <NbTextInput
        v-if="prc"
        :required="prc"
        :id="`volumes[${boxIndex}].collected_icms`"
        class="w-48-5 mb-4"
        type="money"
        :prepend="simplifyCurrency(taxes_currency)"
        :name="`${$t('collectedIcms')} (${simplifyCurrency(taxes_currency)})`"
        :helpText="
          $t('orderCreatePage.courierInformation.freightvalueHelpText')
        "
        :decimals="2"
        :min="0.0"
        :error="errors[`volumes[${boxIndex}].collected_icms`]"
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('updateInvalidFields', $event)"
        v-model="box.collected_icms"
      />

      <NbTextInput
        v-if="prc"
        :required="prc"
        :id="`volumes[${boxIndex}].collected_ii`"
        class="w-48-5 mb-4"
        type="money"
        :prepend="simplifyCurrency(taxes_currency)"
        :name="`${$t('collectedIi')} (${simplifyCurrency(taxes_currency)})`"
        :helpText="
          $t('orderCreatePage.courierInformation.freightvalueHelpText')
        "
        :decimals="2"
        :min="0.0"
        :error="errors[`volumes[${boxIndex}].collected_ii`]"
        @valid="$emit('updateValidFields', $event)"
        @invalid="$emit('updateInvalidFields', $event)"
        v-model="box.collected_ii"
      />
    </div>
    <!-- modals -->
    <NbModal
      id="editMetricModal"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("components.modalEditSellerMetric.title") }}
        </div>
      </template>
      <template v-slot:body>
        <NbRadio
          id="customer_box_name_editMetric"
          name="Metric Mode"
          :options="metricOptions"
          v-model="editMetric"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="sendMetric()">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </div>
</template>

<script>
import Alert from "@/components/alerts/Alert.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";

export default {
  name: "NbBox",
  components: { Alert, NbTextInput, NbModal, NbButton, NbRadio, NbSelectInput },
  props: {
    boxIndex: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    taxes_currency: {
      type: String,
      required: true,
    },
    sizeUnit: {
      type: String,
      required: true,
    },
    weightUnit: {
      type: String,
      required: true,
    },
    prc: {
      type: Boolean,
      default: false,
    },
    boxOptions: {
      type: Array,
      default: () => [],
    },
    availableBoxes: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    /* value: {
      type: Object,
      required: true,
    }, */
    formValBox: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    editMetric: "",
    internalErrors: [],
  }),
  created() {},
  methods: {
    getTotalWeight(box) {
      let boxTotalWeight = JSON.parse(JSON.stringify(box.weight));

      if (box.order_items_attributes) {
        box.order_items_attributes.forEach((item) => {
          const itemWeight = parseFloat(
            Number(item.weight) * Number(item.quantity)
          ).toFixed(3);

          boxTotalWeight = parseFloat(
            Number(boxTotalWeight) + Number(itemWeight)
          ).toFixed(3);
        });
      }

      return boxTotalWeight;
    },
    sendMetric() {
      this.$emit("sendMetric", this.editMetric);
      this.$helpers.closeModal("editMetricModal");
    },
    simplifyCurrency(currency) {
      if (currency === "USD") {
        return "$";
      } else {
        return "R$";
      }
    },
    checkVolumeName(box, boxAttribute, boxErrReference) {
      if (
        typeof box.volume_name !== "string" ||
        box.volume_name === "Custom Box"
      ) {
        this.internalErrors[boxErrReference] = [];
        return false;
      }
      const selectedBox = this.availableBoxes.find(
        ({ volume_type }) => volume_type === box.volume_name
      );

      if (
        !selectedBox[boxAttribute] ||
        Number(selectedBox[boxAttribute]) == 0
      ) {
        this.internalErrors[boxErrReference] = [
          this.$t("errorMessages.required"),
        ];
        return false;
      }
      delete this.internalErrors[boxErrReference];

      return true;
    },
    updateBox(boxName) {
      let selectedBox = this.availableBoxes.find(
        ({ volume_type }) => volume_type === boxName
      );

      this.box.freight_cost = selectedBox.freight_cost;
      this.box.freight_value = selectedBox.freight_value;
      this.box.volume_name = selectedBox.volume_type;
      this.box.general_description = selectedBox.general_description;
      this.box.height = selectedBox.height;
      this.box.width = selectedBox.width;
      this.box.length = selectedBox.length;
      this.box.weight = selectedBox.weight;
      this.collected_icms = selectedBox.collected_icms;
      this.collected_ii = selectedBox.collected_ii;
    },
  },
  computed: {
    box: {
      get() {
        return this.formValBox;
      },
      set(val) {
        this.$emit("update:formValBox", val);
      },
    },
    boxLink() {
      return {
        target: "_blank",
        src: "/boxes",
        text: `${this.$t(
          "orderCreatePage.shipmentInformation.registerBoxes"
        )}?`,
      };
    },
    metricOptions() {
      return [
        { text: this.$t("components.boxesList.metric"), value: "metric" },
        { text: this.$t("components.boxesList.imperial"), value: "imperial" },
      ];
    },
  },
  watch: {
    editMetric(newValue) {
      this.$emit("editMetricChange", newValue);
    },
    //reset courier watchers
    sizeUnit(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("boxMetricsChange");
      }
    },
    weightUnit(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("boxMetricsChange");
      }
    },
    "box.height": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("boxMetricsChange");
      }
    },
    "box.width": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("boxMetricsChange");
      }
    },
    "box.length": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("boxMetricsChange");
      }
    },
    "box.weight": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("boxMetricsChange");
      }
    },
  },
};
</script>

<style scoped>
.alert-create-box-model {
  margin-top: 1.7rem;
}
</style>
