<template>
  <div class="search-input-ahead-wrapper" :class="error[0] ? 'is-invalid' : ''">
    <label v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <VueBootstrapTypeAhead
      :id="id"
      :ref="`${id}-ref`"
      :tabindex="tabindex"
      :class="error[0] ? `is-invalid` : ``"
      :inputClass="`input`"
      v-model="textContent"
      :data="items"
      :serializer="serializer"
      :minMatchingChars="1"
      :placeholder="placeholder"
      @hit="(event) => $emit('selected', event)"
    />
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import VueBootstrapTypeAhead from "vue-bootstrap-typeahead";
import baseTypeAheadProps from "./baseTypeAheadProps.js";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import ErrorFeedback from "../../generic/ErrorFeedback.vue";

export default {
  name: "TextInput",
  components: {
    VueBootstrapTypeAhead,
    NbHelpText,
    ErrorFeedback,
  },
  props: {
    ...baseTypeAheadProps,
    tabindex: {
      type: Number,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
  },
  data: () => ({ textContent: "" }),
  async mounted() {
    await this.$nextTick();
    this.$refs[`${this.id}-ref`].$el
      .querySelector("input")
      .addEventListener("blur", () => this.onBlur());
  },
  computed: {
    classes() {
      let baseClass = "";

      if (this.error.length) {
        baseClass += "is-invalid";
      }
      return baseClass;
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      this.validateTextContent();
    },
    validateTextContent() {
      if (this.required && !this.textContent.length) {
        this.$emit("invalid", {
          id: this.id,
          message: this.$t("errorMessages.required"),
        });
        return;
      }
      this.$emit("valid", this.id);
    },
  },
  created() {
    this.textContent = this.value;
  },
  watch: {
    textContent(newValue) {
      if (newValue !== this.$refs[`${this.id}-ref`].inputValue) {
        this.$refs[`${this.id}-ref`].inputValue = newValue;
      }
      this.$emit("input", newValue);
      this.validateTextContent();
    },
    value(newValue) {
      if (newValue !== this.textContent) {
        this.textContent = newValue;
      }
    },
    tabindex() {
      const element =
        document.getElementsByClassName("type-ahead-area")[0].firstChild
          .children[0];
      element.setAttribute("tabindex", this.tabindex);
    },
  },
};
</script>

<style lang="scss">
.type-ahead-area {
  width: 100%;
}
.is-invalid :not(.input-wrapper) .form-control.input {
  border-bottom: 2px solid var(--error);
  caret-color: var(--error);
  background-image: none;
}
.is-valid :not(.input-wrapper) .form-control.input {
  border-bottom: 2px solid var(--success);
  caret-color: var(--success);
  background-image: none;
}
.is-invalid .form-control.input:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}
</style>
