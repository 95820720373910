<template>
  <div class="" :class="errorsFeedback ? 'is-invalid' : ''">
    <label
      v-if="name"
      class="input-label"
      :class="disabled ? 'not-allowed' : ''"
      :for="id"
    >
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <input
      :tabindex="tabindex"
      :type="type"
      :min="min"
      :step="step"
      class="nb form-control input"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      @blur="onBlur"
      v-model="textContent"
      v-facade="finalMask"
      :class="classes"
    />
    <ErrorFeedback :error="errorsFeedback" />
  </div>
</template>

<script>
import { facade } from "vue-input-facade";
import {
  BRAZIL_ZIP_CODE_MASK,
  GENERIC_ZIP_CODE_MASK,
} from "../../../constants.js";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import ErrorFeedback from "../../generic/ErrorFeedback.vue";

import AddressCompleteService from "../../../services/AddressCompleteService.js";
const addressCompleteService = new AddressCompleteService();

export default {
  name: "PostalCodeInput",
  directives: { facade },
  components: {
    NbHelpText,
    ErrorFeedback,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    prepend: {
      type: String,
      required: false,
    },
    append: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    decimals: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    step: {
      type: String,
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
    },
    rules: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    mask: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    timer: null,
    textContent: "",
    finalTextContent: "",
    internalErrors: [],
    /* mask: BRAZIL_ZIP_CODE_MASK, */
  }),
  created() {
    //this.textContent = this.value;
    this.finalTextContent = this.value;
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      this.startValidationProcess();
      this.checkRules(this.finalTextContent);
      //May call startValidationProcess() on blur????
    },
    startValidationProcess() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.timer = null;
        this.validateTextContent();
      }, 400);
    },
    validateTextContent() {
      if (this.required && !this.finalTextContent.length) {
        this.invalidCallback();
        return;
      }
      if (this.finalTextContent.length >= 8) {
        this.findAddress(this.finalTextContent)
          .then((response) => {
            if (response.data.data) {
              const address = response.data.data.address;
              const resp = {
                logradouro: address.street,
                localidade: address.city,
                uf: address.state,
                bairro: address.neighbourhood,
              };
              this.$emit("valid", this.id);
              this.$emit("found", resp);
            }
          })
          .catch(this.invalidCallback);
      }
    },
    invalidCallback() {
      this.$emit("invalid", {
        id: this.id,
        message: this.errorMessage,
      });
    },
    findAddress(postal_code) {
      return addressCompleteService.getAddress(postal_code);
    },
    checkRules(newValue) {
      this.internalErrors = this.$helpers.inputRules(
        newValue,
        this.rules,
        this.required
      );
    },
    allOk() {
      const element = document.getElementById(this.id);
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        if (this.textContent && this.textContent.length > 0) {
          element.classList.add("is-valid");
        }
      }, 200);
    },
  },
  computed: {
    finalMask() {
      if (this.textContent.length >= 8 && this.mask === "generic") {
        return GENERIC_ZIP_CODE_MASK;
      }
      return BRAZIL_ZIP_CODE_MASK;
    },
    classes() {
      if (this.errorsFeedback) {
        return this.disabled ? "is-invalid not-allowed" : "is-invalid";
      }
      if (this.disabled) {
        return "not-allowed";
      }

      if (this.textContent && this.textContent.length > 0) {
        return "is-valid";
      }
      return "";
    },
    errorMessage() {
      if (this.required && !this.textContent.length) {
        return this.$t("errorMessages.required");
      }
      return this.$t("errorMessages.invalidZIPCode");
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error[0];
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors[0];
      }
      return "";
    },
  },
  watch: {
    textContent(newValue) {
      this.finalTextContent = newValue.replace(/[^a-zA-Z0-9]/g, "");
      this.$emit("input", this.finalTextContent);
      //this.allOk();
      this.startValidationProcess();
    },
    value(newValue) {
      if (newValue !== this.finalTextContent) {
        this.finalTextContent = newValue;
        this.textContent = newValue;
      }
    },
    /* internalErrors() {
      this.allOk();
    }, */
  },
};
</script>
