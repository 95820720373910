import HttpService from "./HttpService";

export default class QuoteService {
  constructor() {
    this.httpService = HttpService;
  }

  getQuote(data) {
    return this.httpService.post("/v1/quotations", data);
  }

  getOrderQuotation(data) {
    return this.httpService.post("/v1/order_quotation", {
      order: data,
    });
  }

  getQuotation(data) {
    return this.httpService.post("/v1/quote_freight", data);
  }

  getQuotationSimple(data) {
    return this.httpService.post("/v1/quote_simple", data);
  }

  getQuotationDecomposition(data) {
    return this.httpService.post("/v1/quotes_decomposition", data);
  }
}
